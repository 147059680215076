import React, { useEffect, useState } from "react";
import "./LauderMain.css";
import logo from './img/bilka.png';

import { motion } from "framer-motion";


const LauderMain = () => {

  const [isOpen, setIsOpen] = useState(false);

  // Варіанти анімації для пелюсток
  const petalVariants = {
    closed: { rotate: 0, scale: 0.5, opacity: 0 },
    open: (i) => ({
      rotate: i * 60, // Відкриваємо кожну пелюстку
      scale: 1,
      opacity: 1,
      transition: { delay: i * 0.2, duration: 1 }
    })
  };

  return (
    <div className="loader-top">

      
      
      <div class="loader-container">
        <div class="flipping-cards">
        <div class="card-loader">  <img className="logo-leader" src={logo} /></div>
          <div class="card-loader">К</div>
          <div class="card-loader">О</div>
          <div class="card-loader">Ц</div>
          <div class="card-loader">Ю</div>
          <div class="card-loader">Б</div>
          <div class="card-loader">І</div>
          <div class="card-loader">Л</div>
          <div class="card-loader">К</div>
          <div class="card-loader">А</div>
    
        </div>
      </div>


     
    </div>
  );
}

export default LauderMain