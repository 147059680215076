import React from "react";
import { createContext } from "react";
import { useState, useEffect } from 'react';

// Категорії
import nedvijemost from './img/categori/nedvijemost.png';
import master from './img/categori/master.png';
import komunalka from './img/categori/komunalka.jpg';
import beauty from './img/categori/beauty.jpg';
import childrens from './img/categori/children.png';
import dostavka from './img/categori/dostavka1.png';
import sport from './img/categori/sport.png';
import delo from './img/categori/delo.png';
import finansi from './img/categori/finansi.png';
import avto from './img/categori/avto.png';
import thingth from './img/categori/Thingth.png';
import podarok from './img/categori/podarok.png';
import magazin from './img/categori/magazin.png';
import podarunok from './img/categori/podarunok.png';
import kafe from './img/categori/kafe.png';
import medecina from './img/categori/medecina.png';
import post from './img/categori/post1.png';
import oxorona from './img/categori/oxorona.png';
import cnap from './img/categori/komunalka.png';
import magazinzagal from './img/categori/magazin1.png';
import zoo from './img/categori/zoo.png';
import transport from './img/categori/transport.png';
import podarunki from './img/categori/podarunki.png';
import jobs from './img/categori/jobs.png';
import vidpochinok from './img/categori/vidpochinok.png';



import reklama from './img/categori/reklama.png';

// Підкаатегорії, типи нерухомості
import oficeNedv from './img/type/ofice.png';

// Підкаатегорії, типи майстри
import santexnik from './img/type/santexnik.png';
import electric from './img/type/electric.png';
import mebel from './img/type/mebel.png';
import holodilnik from './img/type/holodilnik.png';
import svh from './img/type/svh.png';
import posudom from './img/type/posudom.png';
import sma from './img/type/sma.png';
import varochna from './img/type/varochna.png';
import duhovka from './img/type/duhovka.png';
import kondicioner from './img/type/kondicioner.png';
import vikna from './img/type/vikna.png';
import gaz from './img/type/gaz.png';
import klining from './img/type/klining.png';
import remtelefon from './img/type/remtelefon.png';
import remzamkov from './img/type/remzamkov.png';
import kaprem from './img/type/kaprem.png';

// Підкаатегорії, типи ком послуги
import osbb from './img/type/osbb.png';
import lichilnik from './img/type/lichilnik.png';
import kominikacii from './img/type/kominikacii.png';
import grafik from './img/type/grafik.png';
import grafikvoda from './img/type/grafikvoda.png';
import elevator from './img/type/elevator.png';

// Підкаатегорії, типи бюті
import manik from './img/type/manik.png';
import brovi from './img/type/brovi.png';
import parehmaher from './img/type/parehmaher.png';
import kosmetologia from './img/type/kosmetologia.png';
import makiyaj from './img/type/makiyaj.png';
import masaj from './img/type/masaj.png';
import epilacia from './img/type/epilacia.png';
import permanent from './img/type/permanent.png';
import tatoo from './img/type/tatoo.png';
import barber from './img/type/barber.png';
import bad from './img/type/bad.png';



//Підкаатегорії, типи для дітей
import ditachisadochok from './img/type/ditachisadochok.png';
import shool from './img/type/shool.png';
import centryrozvitku from './img/type/centryrozvitku.png';
import sportdite from './img/type/sportdite.png';
import sviatodite from './img/type/sviatodite.png';
import odiaddite from './img/type/odiaddite.png';


// Підкаатегорії, типи доставка
import voda from './img/type/voda.png';
import ija from './img/type/ija.png';
import kofeina from './img/type/kofeina.png';

// Підкаатегорії, типи фінансові рослуги
import bank from './img/type/bank.png';
import obmin from './img/type/obmin.png';

// Підкаатегорії, типи ділові послуги
import notarius from './img/type/notarius.png';
import advocat from './img/type/advocat.png';
import bugalteria from './img/type/bugalteria.png';
import kserox from './img/type/kserox.png';
import turist from './img/type/turist.png';
import graficdizainer from './img/type/graficdizainer.png';


// Підкаатегорії, типи авто
import avtomagazin from './img/type/avtomagazin.png';
import shinomontaj from './img/type/shinomontaj.png';
import cto from './img/type/cto.png';
import parking from './img/type/parking.png';
import moika from './img/type/moika.png';

// Підкаатегорії, типи одяг
import atele from './img/type/atele.png';
import obuv from './img/type/obuv.png';
import himchiska from './img/type/himchiska.png';

// Підкаатегорії, типи б/у речі
import bymebli from './img/type/bymebli.png';
import buodejda from './img/type/buodejda.png';
import butexnika from './img/type/butexnika.png';

// Підкаатегорії, типи кафе ресторани
import kofeniakaf from './img/type/kofeniakaf.png';
import kaffe from './img/type/kaffe.png';

// Підкаатегорії, типи магазини
import magazinprod from './img/type/magazinprod.png';
import zamorojeni from './img/type/zamorojeni.png';
import zoomagazin from './img/type/zoomagazin.png';
import alcomagazin from './img/type/alcomagazin.png';
import stroitelmagazin from './img/type/stroitelmagazin.png';
import mouhiemagaz from './img/type/mouhiemagaz.png';
import kvitkovimagaz from './img/type/kvitkovimagaz.png';

// Підкаатегорії, типи лікарні
import bolnica from './img/type/bolnica.png';
import labolatoria from './img/type/labolatoria.png';
import apteka from './img/type/apteka.png';
import stomatologia from './img/type/stomatologia.png';

// Підкаатегорії, пошта
// import novapost from './img/type/novapost.png';
// import ukrpost from './img/type/ukrpost.png';
// import rozetka from './img/type/rozetka.png';

// Підкаатегорії, типи безпека
import police from './img/type/police.png';
import oxrana from './img/type/oxrana.png';

import besplatno from './img/type/besplatno.png';
import topteklama from './img/type/topteklama.png';
import personreklama from './img/type/personreklama.png';

//зоо грумінг
import gruming from './img/type/gruming.png';
import vetklinika from './img/type/vetklinika.png';
//транспорт
import potiag from './img/type/potiag.png';
import lavinabus from './img/type/lavinabus.png';
import marshrutka from './img/transport/marshrutki/marshrutka.png';
import karta from './img/transport/lavinabus/karta.png';
import rozkladpotiga from './img/transport/potiag/rozkladpotiga.png';

//подарунки
//смачні букети
import smachnibuketi from './img/type/smachnibuketi.png';

//Робота
import searchjobs from './img/type/searchjobs.png';
import searchemployee from './img/type/searchemployee.png';

//відпочинок
import bani from './img/type/bani.png';



//мастера
//мебель
import shunmebel from './img/masterdom/mebel/shunmebel.jpeg';

//холодильник
import holodvachslav from './img/masterdom/holodilnik/Vacheslav/holodvachslav.jpg';

//вікна
import moskitni from './img/masterdom/vikna/moskitni.jpeg';
import mastervikon from './img/masterdom/vikna/mastervikon.png';
import budmaterialvikna from './img/masterdom/vikna/budmaterialvikna.jpg';


//нерухомість
//житлова
//1
import ponomar26_20_28_1 from './img/neruxomist/ponomar26_20_28/1.png';
import ponomar26_20_28_2 from './img/neruxomist/ponomar26_20_28/2.png';
import ponomar26_20_28_3 from './img/neruxomist/ponomar26_20_28/3.png';
import ponomar26_20_28_4 from './img/neruxomist/ponomar26_20_28/4.png';
import ponomar26_20_28_5 from './img/neruxomist/ponomar26_20_28/5.png';

//2
import _3ponomar26_1_107_1 from './img/neruxomist/3_ponomareva_26_1_107/1.png';
import _3ponomar26_1_107_2 from './img/neruxomist/3_ponomareva_26_1_107/2.png';
import _3ponomar26_1_107_3 from './img/neruxomist/3_ponomareva_26_1_107/3.png';
import _3ponomar26_1_107_4 from './img/neruxomist/3_ponomareva_26_1_107/4.png';
import _3ponomar26_1_107_5 from './img/neruxomist/3_ponomareva_26_1_107/5.png';
import _3ponomar26_1_107_6 from './img/neruxomist/3_ponomareva_26_1_107/6.png';

//3
import dokivska_119_1 from './img/neruxomist/dokivska_119/1.png';
import dokivska_119_2 from './img/neruxomist/dokivska_119/2.png';
import dokivska_119_3 from './img/neruxomist/dokivska_119/3.png';
import dokivska_119_4 from './img/neruxomist/dokivska_119/4.png';
//4
import _3ponomar26_1_106_18_1 from './img/neruxomist/3_ponomareva_26_4_18pov/Пономарьова26_106_18пов_1.jpg';
import _3ponomar26_1_106_18_2 from './img/neruxomist/3_ponomareva_26_4_18pov/Пономарьова26_106_18пов_2.jpg';
import _3ponomar26_1_106_18_3 from './img/neruxomist/3_ponomareva_26_4_18pov/Пономарьова26_106_18пов_3.jpg';
import _3ponomar26_1_106_18_4 from './img/neruxomist/3_ponomareva_26_4_18pov/Пономарьова26_106_18пов_4.jpg';
import _3ponomar26_1_106_18_5 from './img/neruxomist/3_ponomareva_26_4_18pov/Пономарьова26_106_18пов_5.jpg';
import _3ponomar26_1_106_18_6 from './img/neruxomist/3_ponomareva_26_4_18pov/Пономарьова26_106_18пов_6.jpg';
//5
import _2dokivska_10_41_5_1 from './img/neruxomist/_2dokivska_10_41.2_5/Доківська10_41_5пов_1.jpg';
import _2dokivska_10_41_5_2 from './img/neruxomist/_2dokivska_10_41.2_5/Доківська10_41_5пов_2.jpg';
import _2dokivska_10_41_5_3 from './img/neruxomist/_2dokivska_10_41.2_5/Доківська10_41_5пов_3.jpg';
import _2dokivska_10_41_5_4 from './img/neruxomist/_2dokivska_10_41.2_5/Доківська10_41_5пов_4.jpg';
import _2dokivska_10_41_5_5 from './img/neruxomist/_2dokivska_10_41.2_5/Доківська10_41_5пов_5.jpg';

//6
import sal_1_k_ponomar26_49_18et_1 from './img/neruxomist/sal_1_k_ponomar26_49_18et/1к_Пономарьова_26_49_18пов_1.jpg';
import sal_1_k_ponomar26_49_18et_2 from './img/neruxomist/sal_1_k_ponomar26_49_18et/1к_Пономарьова_26_49_18пов_2.jpg';
import sal_1_k_ponomar26_49_18et_3 from './img/neruxomist/sal_1_k_ponomar26_49_18et/1к_Пономарьова_26_49_18пов_3.jpg';
import sal_1_k_ponomar26_49_18et_4 from './img/neruxomist/sal_1_k_ponomar26_49_18et/1к_Пономарьова_26_49_18пов_4.jpg';

//7
import sal_1_k_ponomar26_40_24et_1 from './img/neruxomist/sal_1-k_ponomar26_40_24et/1кПономарьова26_24пов_40_1.png';
import sal_1_k_ponomar26_40_24et_2 from './img/neruxomist/sal_1-k_ponomar26_40_24et/1кПономарьова26_24пов_40_2.png';
import sal_1_k_ponomar26_40_24et_3 from './img/neruxomist/sal_1-k_ponomar26_40_24et/1кПономарьова26_24пов_40_3.png';
import sal_1_k_ponomar26_40_24et_4 from './img/neruxomist/sal_1-k_ponomar26_40_24et/1кПономарьова26_24пов_40_4.png';
import sal_1_k_ponomar26_40_24et_5 from './img/neruxomist/sal_1-k_ponomar26_40_24et/1кПономарьова26_24пов_40_5.png';
//8
import sal_1_k_ponomar26_19_46_1 from './img/neruxomist/sal_1-k_ponomar26_19_46/1.png';
import sal_1_k_ponomar26_19_46_2 from './img/neruxomist/sal_1-k_ponomar26_19_46/2.png';
import sal_1_k_ponomar26_19_46_3 from './img/neruxomist/sal_1-k_ponomar26_19_46/3.png';
import sal_1_k_ponomar26_19_46_4 from './img/neruxomist/sal_1-k_ponomar26_19_46/4.png';
import sal_1_k_ponomar26_19_46_5 from './img/neruxomist/sal_1-k_ponomar26_19_46/5.png';


//9
import sal_1k_ponomar26_6_40_1 from './img/neruxomist/sal_1k_ponomar26_6_40/1.png';
import sal_1k_ponomar26_6_40_2 from './img/neruxomist/sal_1k_ponomar26_6_40/2.png';
import sal_1k_ponomar26_6_40_3 from './img/neruxomist/sal_1k_ponomar26_6_40/3.png';
import sal_1k_ponomar26_6_40_4 from './img/neruxomist/sal_1k_ponomar26_6_40/4.png';
import sal_1k_ponomar26_6_40_5 from './img/neruxomist/sal_1k_ponomar26_6_40/5.png';
//10
import sal_1k_dokivska_10_14_42_1 from './img/neruxomist/sal_1k_dokivska_10_14_42/1.png';
import sal_1k_dokivska_10_14_42_2 from './img/neruxomist/sal_1k_dokivska_10_14_42/2.png';
import sal_1k_dokivska_10_14_42_3 from './img/neruxomist/sal_1k_dokivska_10_14_42/3.png';
import sal_1k_dokivska_10_14_42_4 from './img/neruxomist/sal_1k_dokivska_10_14_42/4.png';
import sal_1k_dokivska_10_14_42_5 from './img/neruxomist/sal_1k_dokivska_10_14_42/5.png';

//11
import sal_dokivska_10_10_4_35_1 from './img/neruxomist/sal_dokivska_10_10_4_35/1.png';
import sal_dokivska_10_10_4_35_2 from './img/neruxomist/sal_dokivska_10_10_4_35/2.png';
import sal_dokivska_10_10_4_35_3 from './img/neruxomist/sal_dokivska_10_10_4_35/3.png';
import sal_dokivska_10_10_4_35_4 from './img/neruxomist/sal_dokivska_10_10_4_35/4.png';
import sal_dokivska_10_10_4_35_5 from './img/neruxomist/sal_dokivska_10_10_4_35/5.png';
//12
import sal_1k_ponomar26_3_15_39_1 from './img/neruxomist/sal_1-k_ponomar26_3_15_39/1.png';
import sal_1k_ponomar26_3_15_39_2 from './img/neruxomist/sal_1-k_ponomar26_3_15_39/2.png';
import sal_1k_ponomar26_3_15_39_3 from './img/neruxomist/sal_1-k_ponomar26_3_15_39/3.png';
import sal_1k_ponomar26_3_15_39_4 from './img/neruxomist/sal_1-k_ponomar26_3_15_39/4.png';
import sal_1k_ponomar26_3_15_39_5 from './img/neruxomist/sal_1-k_ponomar26_3_15_39/5.png';
//13
import sal_1k_ponomar26_5_4_32_1 from './img/neruxomist/sal_1-k_ponomar26_5_4_32/1.png';
import sal_1k_ponomar26_5_4_32_2 from './img/neruxomist/sal_1-k_ponomar26_5_4_32/2.png';
import sal_1k_ponomar26_5_4_32_3 from './img/neruxomist/sal_1-k_ponomar26_5_4_32/3.png';
import sal_1k_ponomar26_5_4_32_4 from './img/neruxomist/sal_1-k_ponomar26_5_4_32/4.png';
import sal_1k_ponomar26_5_4_32_5 from './img/neruxomist/sal_1-k_ponomar26_5_4_32/5.png';
//14
import sal_ponomar18a_9_43_1 from './img/neruxomist/sal_ponomar18a_9_43/1.png';
import sal_ponomar18a_9_43_2 from './img/neruxomist/sal_ponomar18a_9_43/2.png';
import sal_ponomar18a_9_43_3 from './img/neruxomist/sal_ponomar18a_9_43/3.png';
import sal_ponomar18a_9_43_4 from './img/neruxomist/sal_ponomar18a_9_43/4.png';
import sal_ponomar18a_9_43_5 from './img/neruxomist/sal_ponomar18a_9_43/5.png';
//15
import sal_1k_ponomar_4et_27_1 from './img/neruxomist/sal_1k_ponomar_4et_27/1.png';
import sal_1k_ponomar_4et_27_2 from './img/neruxomist/sal_1k_ponomar_4et_27/2.png';
import sal_1k_ponomar_4et_27_3 from './img/neruxomist/sal_1k_ponomar_4et_27/3.png';
import sal_1k_ponomar_4et_27_4 from './img/neruxomist/sal_1k_ponomar_4et_27/4.png';
import sal_1k_ponomar_4et_27_5 from './img/neruxomist/sal_1k_ponomar_4et_27/5.png';
//16
import sal_ponomar_26_12_30_1 from './img/neruxomist/sal_ponomar_26_12_30/1.png';
import sal_ponomar_26_12_30_2 from './img/neruxomist/sal_ponomar_26_12_30/2.png';
import sal_ponomar_26_12_30_3 from './img/neruxomist/sal_ponomar_26_12_30/3.png';
import sal_ponomar_26_12_30_4 from './img/neruxomist/sal_ponomar_26_12_30/4.png';
import sal_ponomar_26_12_30_5 from './img/neruxomist/sal_ponomar_26_12_30/5.png';
////17
import ren_1k_ponomar26_5_5_30_1 from './img/neruxomist/ren_1k_ponomar26_5_5_30/1.png';
import ren_1k_ponomar26_5_5_30_2 from './img/neruxomist/ren_1k_ponomar26_5_5_30/2.png';
import ren_1k_ponomar26_5_5_30_3 from './img/neruxomist/ren_1k_ponomar26_5_5_30/3.png';
import ren_1k_ponomar26_5_5_30_4 from './img/neruxomist/ren_1k_ponomar26_5_5_30/4.png';
import ren_1k_ponomar26_5_5_30_5 from './img/neruxomist/ren_1k_ponomar26_5_5_30/5.png';
///18
import sal_1k_dokivska10_8_15_28_1 from './img/neruxomist/sal_1k_dokivska10_8_15_28/1.png';
import sal_1k_dokivska10_8_15_28_2 from './img/neruxomist/sal_1k_dokivska10_8_15_28/2.png';
import sal_1k_dokivska10_8_15_28_3 from './img/neruxomist/sal_1k_dokivska10_8_15_28/3.png';
import sal_1k_dokivska10_8_15_28_4 from './img/neruxomist/sal_1k_dokivska10_8_15_28/4.png';
import sal_1k_dokivska10_8_15_28_5 from './img/neruxomist/sal_1k_dokivska10_8_15_28/5.png';
///19
import sal_1k_dokivska10_10_19_25_1 from './img/neruxomist/sal_1k_dokivska10_10_19_25/1.png';
import sal_1k_dokivska10_10_19_25_2 from './img/neruxomist/sal_1k_dokivska10_10_19_25/2.png';
import sal_1k_dokivska10_10_19_25_3 from './img/neruxomist/sal_1k_dokivska10_10_19_25/3.png';
import sal_1k_dokivska10_10_19_25_4 from './img/neruxomist/sal_1k_dokivska10_10_19_25/4.png';
import sal_1k_dokivska10_10_19_25_5 from './img/neruxomist/sal_1k_dokivska10_10_19_25/5.png';
////20
import sal_3_k_ponomar26_107_7et_1 from './img/neruxomist/sal_3_k_ponomar26_107_7et/1.png';
import sal_3_k_ponomar26_107_7et_2 from './img/neruxomist/sal_3_k_ponomar26_107_7et/2.png';
import sal_3_k_ponomar26_107_7et_3 from './img/neruxomist/sal_3_k_ponomar26_107_7et/3.png';
import sal_3_k_ponomar26_107_7et_4 from './img/neruxomist/sal_3_k_ponomar26_107_7et/4.png';
import sal_3_k_ponomar26_107_7et_5 from './img/neruxomist/sal_3_k_ponomar26_107_7et/5.png';
///21
import ren_1к_dokivska_10_18_40_1 from './img/neruxomist/ren_1к_dokivska_10_18_40/1.png';
import ren_1к_dokivska_10_18_40_2 from './img/neruxomist/ren_1к_dokivska_10_18_40/2.png';
import ren_1к_dokivska_10_18_40_3 from './img/neruxomist/ren_1к_dokivska_10_18_40/3.png';
import ren_1к_dokivska_10_18_40_4 from './img/neruxomist/ren_1к_dokivska_10_18_40/4.png';
import ren_1к_dokivska_10_18_40_5 from './img/neruxomist/ren_1к_dokivska_10_18_40/5.png';






//комерційна

//1
import ponomareva26_5_2_1 from './img/neruxomist/komerciina/ponomareva26_5_2/1.png';
import ponomareva26_5_2_2 from './img/neruxomist/komerciina/ponomareva26_5_2/2.png';
import ponomareva26_5_2_3 from './img/neruxomist/komerciina/ponomareva26_5_2/3.png';
import ponomareva26_5_2_4 from './img/neruxomist/komerciina/ponomareva26_5_2/4.png';
import ponomareva26_5_2_5 from './img/neruxomist/komerciina/ponomareva26_5_2/5.png';

//2
import bc_ponomar7_3pov_1 from './img/neruxomist/komerciina/bc_ponomar7_3pov/1.jpg';
import bc_ponomar7_3pov_2 from './img/neruxomist/komerciina/bc_ponomar7_3pov/2.jpg';
import bc_ponomar7_3pov_3 from './img/neruxomist/komerciina/bc_ponomar7_3pov/3.jpg';
import bc_ponomar7_3pov_4 from './img/neruxomist/komerciina/bc_ponomar7_3pov/4.jpg';
import bc_ponomar7_3pov_5 from './img/neruxomist/komerciina/bc_ponomar7_3pov/5.png';
//3
import bc_ponomar7_4pov_1 from './img/neruxomist/komerciina/bc_ponomar7_4pov/1.jpg';
import bc_ponomar7_4pov_2 from './img/neruxomist/komerciina/bc_ponomar7_4pov/2.jpg';
import bc_ponomar7_4pov_3 from './img/neruxomist/komerciina/bc_ponomar7_4pov/3.jpg';
import bc_ponomar7_4pov_4 from './img/neruxomist/komerciina/bc_ponomar7_4pov/4.jpg';
import bc_ponomar7_4pov_5 from './img/neruxomist/komerciina/bc_ponomar7_4pov/5.png';




//майстри
//меблі
import naGodinu from './img/masterdom/mebel/vashsusid.jpeg';
import artMebel from './img/masterdom/mebel/art.jpg';

// сантехнік
import daniil1 from './img/masterdom/santexnik/daniil/daniil1.jpg';


//електрик
import sergeiElectrik from './img/masterdom/electric/sergei.jpeg';
import dzgerelo from './img/masterdom/electric/dzgerelo_givlenia.jpg';
import electriktehnika from './img/masterdom/electric/electriktehnika.png';
import vstanovinvert from './img/masterdom/electric/vstanovinvert.jpg';




//ремонт телефонів
import maksik from './img/masterdom/remtelefon/maksik.png';
// клінінг
import robotokna from './img/masterdom/klining/robotokna.jpeg';
import paroochisnik from './img/masterdom/klining/paroochisnik.jpeg';
// капітальний ремонт
import plitka from './img/masterdom/kapitalremont/plitka.png';
import vladfilipchuk from './img/masterdom/kapitalremont/vladfilipchuk.jpg';


//Підкаатегорії, типи бюті
//манік
import avokado from './img/beauty/manik/avokado.png';
import okstudio from './img/beauty/manik/okstudio.png';
import lika from './img/beauty/manik/lika.png';
import ania_nails from './img/beauty/manik/ania_nails.jpg';
import lnkmanic from './img/beauty/manik/lnkmanic.jpg';



//Брови
import lashAlenka from './img/beauty/brovi/lash_alenka_kyiv.png';
import lami_mi_mi from './img/beauty/brovi/lami_mi_mi.jpg';
import m_ua_dm from './img/beauty/brovi/m_ua_dm.jpg';
import zhlobina_by_lami from './img/beauty/brovi/zhlobina_by.jpg';




//Макіяж
import m_ua_dm_maki from './img/beauty/maki/m_ua_dm_maki.jpg';
import patrinastia from './img/beauty/maki/patrinastia.jpg';


import manicureLo from './img/beauty/manik/manicure_loo.jpeg';
import pingvin from './img/beauty/manik/pingvin.jpeg';
import salvadore from './img/beauty/manik/salvadore.png';
// Перукарні
import tastudio from './img/beauty/hair/tastudio.jpg';
import ann_si from './img/beauty/hair/ann_si.jpg';
import nail_hair from './img/beauty/hair/nail_hair.jpg';


//Барбер
import guga_barber from './img/beauty/barber/guga_barber.jpg';
// Косметологія
import kvitko from './img/beauty/kosmo/kvitko.jpg';
import strelchuk from './img/beauty/kosmo/strelchuk.jpg';


// Масаж
import massageviktoria from './img/beauty/massage/massageviktoria.jpg';
import milevskag from './img/beauty/massage/milevskag.jpg';


//Видалення волосся
import lazerart from './img/beauty/kosmo/lazerart.png';
import i_wish_laser from './img/beauty/vidalvolos/i_wish_laser.jpg';
import shugarinviktoria from './img/beauty/vidalvolos/shugarinviktoria.jpg'



// тату
import tapokTattoo from './img/beauty/tatoo/TapokTattoo.jpg';
import nichTattoo from './img/beauty/tatoo/Nich_Tattoo.jpg';
import smrchtatto from './img/beauty/tatoo/Smrchtatto.jpg';


// Підкаатегорії, типи комунал
import ujkg from './img/komunal/ujkg.png';
import gas from './img/komunal/gas.png';
import ek from './img/komunal/ek.png';
import kte from './img/komunal/kte.png';
import naftogas from './img/komunal/naftogas.png';
import kotsubinuk from './img/komunal/kotsubinuk.png';

// Підкаатегорії, типи дитячі садочок
import svitliachok from './img/ditee/ditiachisadok/svitliachok.png';
import lastivka from './img/ditee/ditiachisadok/lastivka.png';
import bilchenia from './img/ditee/ditiachisadok/bilchenia.png';
import atlantone from './img/ditee/ditiachisadok/atlantone.png';
// Підкаатегорії, тип школи
import licei1 from './img/ditee/school/licei1.png';
import licei2 from './img/ditee/school/licei2.png';
// Підкаатегорії, тип розвиток
import jerome from './img/ditee/rozvitok/jerome.png';
import schastiatut from './img/ditee/rozvitok/schastiatut.jpeg';
import artefact from './img/ditee/rozvitok/artefact.png';
import kitty_grammar from './img/ditee/rozvitok/kitty_grammar.jpg';
import liliiameleshko from './img/ditee/rozvitok/liliiameleshko.jpg';
import englishtutor from './img/ditee/rozvitok/englishtutor.png';
import movator from './img/ditee/rozvitok/movator.jpg';

//свято для дітей
import kotsuibakids from './img/ditee/sviato/kotsuibakids.jpg';
//одяг
import smilik from './img/ditee/odiagdite/smilik.jpg';




// Підкаатегорії, типи доставка
import vodakotsuba from './img/dostavka/voda.png';
import krinica from './img/dostavka/krinica.png';
import teritiriasmaku from './img/dostavka/teritiriasmaku.jpeg';
import novus from './img/dostavka/novus.jpg'; 
import foradost from './img/dostavka/foradost.png';
import pizavv from './img/dostavka/pizavv.png';
import tarantino from './img/dostavka/tarantino.png';
import sushistory1 from './img/dostavka/sushistory.png';
import pesto from './img/dostavka/pesto.png';
import readefleet from './img/dostavka/readefleet.jpg';
import sancho from './img/dostavka/sancho.jpg';
import express from './img/dostavka/express.png';



// Підкаатегорії, типи спорт
import trenzal from './img/sport/trenzal.png';
import fitness from './img/sport/fittness1.png';
import tanci from './img/sport/tanci.png';

//  спорт зал
import richhealt from './img/sport/zal/richhealt.jpeg';
import grafit from './img/sport/zal/grafit.png';

//фітнес
import kango from './img/sport/fitness/kango.png';
import danilov from './img/sport/fitness/danilov.jpeg';
import topfit from './img/sport/fitness/topfit.jpg';

//  спорт спмооборона
import boevieiskustva from './img/sport/boevieiskustva.png';
import fusin from './img/sport/samooborona/fusin.jpeg';
import stakhovTeam from './img/sport/samooborona/stakhov_team.jpg';
import danse_studio_style from './img/sport/danse/danse_studio_style.png';

//  финанси банки
import privat from './img/finansi/bank/privat.png';
//  финанси обмін
import zupinka from './img/finansi/obmin/zupinka.png';

//ділові нотар
import dokivska10 from './img/dilovi/notar/dokivska10.png';
import ponomarevanotar from './img/dilovi/notar/ponomarevanotar.png';
// принн
import kcb_print from './img/dilovi/print/kcb-print.jpg';
// турист
import poihal from './img/dilovi/turist/poihal.jpg';
//Фото Анімації
import animation from './img/dilovi/video/animation.jpg';


//  авто магазин
import magazin1 from './img/avto/magazin/magazin1.jpeg';
//  авто сто
import telega from './img/avto/sto/telega.jpeg';
import remtorg from './img/avto/sto/remtorg.png';
//  авто шиномонтаж
import argon from './img/avto/shinimontaj/argon.png';
import gurtivnia from './img/avto/shinimontaj/gurtivnia.png';
//мийка/детейл
import gsaf from './img/avto/moika/gsaf.jpg';
import soulcar from './img/avto/moika/soulcar.jpg';
import nanowash from './img/avto/moika/nanowash.jpg';



// паркінг
import zagalnaparking from './img/avto/parking/zagalnaparking.png';

//догляд за речами
import fourseson from './img/dogladodagu/atelie/fourseson.png'
import olrad from './img/dogladodagu/atelie/olrad.jpg'
import olenka from './img/dogladodagu/atelie/olenka.png'


import vzutiadikiv39 from './img/dogladodagu/vzutia/vzutiadikiv39.png'
import zaliznichnavzut from './img/dogladodagu/vzutia/zaliznichna.png'
import vzutiaponor26 from './img/dogladodagu/vzutia/vzutiaponor26.jpg'


// продаж речей
import art1211 from './img/prodagrechei/mebli/art_1211.jpg';

// магазини продукт
import kramnichca from './img/magazin/produkt/kramnichca.png';
import magazpon26 from './img/magazin/produkt/magazpon26-2.png';
import miasopon26 from './img/magazin/produkt/miasopon26.png';
import igland from './img/magazin/produkt/igland.png';
import topmarket from './img/magazin/produkt/topmarket.png';
// магазини заморозка
import galiabaluvana from './img/magazin/zamorozka/galia-baluvana.png';
import bosmachno from './img/magazin/zamorozka/bosmachno.jpeg';

// магазини зоо
import zoomagzatlant2 from './img/magazin/zoomag/zoomagzatlant2.png';
import zoomurka from './img/magazin/zoomag/zoomurka.png';
import zooclub from './img/magazin/zoomag/zooclub.png';
// магазини алко
import beercentr from './img/magazin/alkomag/beercentr.png';
import bulkabeer from './img/magazin/alkomag/bulkabeer.png';
// магазини будівельний
import dniprom from './img/magazin/budivmag/dniprom.png';
import stroimaster from './img/magazin/budivmag/stroimaster.png';
import mramor from './img/magazin/budivmag/mramor.png';
import budmaterial from './img/magazin/budivmag/budmaterial.jpeg';

// магазини догляд
import watsons from './img/magazin/doglad/watsons.png';
// магазини квіти
import podaruutobi from './img/magazin/kviti/podaruutobi.png';
import flowerscoffee from './img/magazin/kviti/flowerscoffee.png';
import kyivshar from './img/magazin/kviti/kyivshar.png';
import sunrisedokiv from './img/magazin/kviti/sunrisedokiv.png';
import sunrisezaliz from './img/magazin/kviti/sunrisezaliz.png';
import podarunkikvit from './img/magazin/kviti/podarunkikvit.png';

//ресторани кафе
//Кава
import smaragdova from './img/restorankafe/kava/smaragdova.jpeg';
import vesna from './img/restorankafe/kava/vesna.jpeg';
import chinazes from './img/restorankafe/kava/chinazes.jpg';
import kcb from './img/restorankafe/kava/kcb.jpeg';
import mirical from './img/restorankafe/kava/mirical.jpeg';
import beanse from './img/restorankafe/kava/beanse.png';
import yellow from './img/restorankafe/kava/yellow.png';
import myway from './img/restorankafe/kava/myway.jpg';

//кафе ресторани
import granat from './img/restorankafe/restoran/granat.png';
import rozmarin from './img/restorankafe/restoran/rozmarin.png';
import pizzavv from './img/restorankafe/kafe/pizzavv.png';
import gnizdo from './img/restorankafe/kafe/gnizdo.png';
import lacheburec from './img/restorankafe/kafe/lacheburec.png';
import instasushi from './img/restorankafe/kafe/instasushi.jpeg';
import sushistory from './img/restorankafe/kafe/sushistory.png';
import irpiskanavugilli from './img/restorankafe/kafe/irpiskanavugilli.jpg';
import domiano from './img/restorankafe/kafe/domiano.jpg';
import teritiriasmaku2 from './img/restorankafe/kafe/teritiriasmaku2.jpg';


// аптеки 
import silaprirodi from './img/zdorovia/apteka/silaprirodi.png';
import podorojnik from './img/zdorovia/apteka/podorojnik.png';
import socialna1 from './img/zdorovia/apteka/socialna1.png';
import socialna2 from './img/zdorovia/apteka/socialna2.jpeg';
import kalina from './img/zdorovia/apteka/kalina.png';

//лікарні
import dopomoga from './img/zdorovia/likarni/dopomoga.png'
import oltim from './img/zdorovia/likarni/oltim.png';
import ambulatoria from './img/zdorovia/likarni/ambulatoria.png';
//лаболатори
import sinevo from './img/zdorovia/labolatorii/sinevo.png';
import dila from './img/zdorovia/labolatorii/dila.png';
//стоматология
import smileplus from './img/zdorovia/stomatologia/smileplus.png';
import zubr from './img/zdorovia/stomatologia/zubr.png';

//  пошта укр
import ukrposts from './img/post/ukrpost/ukrpost.png';
//  пошта нова
import vidilenia1 from './img/post/nova/vidilenia1.png';
import vidilenia2 from './img/post/nova/vidilenia2.png';
import vidilenia3 from './img/post/nova/vidilenia3.jpg';
import bdjilka from './img/post/nova/bdjilka.png';
import pon261 from './img/post/nova/pon26-1.png';
import forapon26 from './img/post/nova/forapon26.png';
import ponomareva2 from './img/post/nova/ponomareva2.png';
import eva from './img/post/nova/eva.png';
import bilesuxe from './img/post/nova/bilesuxe.png';
//  пошта розетка
import rozetkavid from './img/post/rozetka/rozetkavid.png';

// охорона та безпека
import policeoffice from './img/ohorona/police.png';
import policeohorona from './img/ohorona/policeohorona.png';
import sheriff from './img/ohorona/sheriff.png';

// зоо
// ветклінике
import undvet from './img/zoo/vtkliniki/undvet.png';

//цнап
import cnapkotsuba from './img/admin/cnap.png';

//подарунки
import smachnibuketikotsuba from './img/podarunki/smachnibuketi.jpg';
import solodkibuketi from './img/podarunki/solodkibuketi.jpg';
import rechipodarki from './img/podarunki/rechipodarki.jpg';

// відпочинок
import parhub from './img/vidpochinok/bani/parhub.jpg';




//Топ-куток
import ann_si_1 from './img/beauty/hair/ann_si/1.jpg';
import ann_si_2 from './img/beauty/hair/ann_si/2.jpg';
import ann_si_3 from './img/beauty/hair/ann_si/3.jpg';
import ann_si_4 from './img/beauty/hair/ann_si/4.jpg';
import ann_si_5 from './img/beauty/hair/ann_si/5.jpg';

import volkivska_1 from './img/beauty/bad/volkivska/1.png';
import volkivska_2 from './img/beauty/bad/volkivska/2.jpg';
import volkivska_3 from './img/beauty/bad/volkivska/3.jpg';
import volkivska_4 from './img/beauty/bad/volkivska/4.jpg';
import volkivska_5 from './img/beauty/bad/volkivska/5.jpg';


import sancho_1 from './img/dostavka/sancho/sancho1.png';
import sancho_2 from './img/dostavka/sancho/sancho2.png';
import sancho_3 from './img/dostavka/sancho/sancho3.png';
import sancho_4 from './img/dostavka/sancho/sancho4.jpg';
import sancho_5 from './img/dostavka/sancho/sancho5.jpg';

import lazerart_1 from './img/beauty/kosmo/lazerart/1.png';
import lazerart_2 from './img/beauty/kosmo/lazerart/2.png';
import lazerart_3 from './img/beauty/kosmo/lazerart/3.png';
import lazerart_4 from './img/beauty/kosmo/lazerart/4.png';
import lazerart_5 from './img/beauty/kosmo/lazerart/5.png';

import strelchuk_1 from './img/beauty/kosmo/strelchuk/1.jpg';
import strelchuk_2 from './img/beauty/kosmo/strelchuk/2.jpg';
import strelchuk_3 from './img/beauty/kosmo/strelchuk/3.jpg';
import strelchuk_4 from './img/beauty/kosmo/strelchuk/4.jpg';
import strelchuk_5 from './img/beauty/kosmo/strelchuk/5.jpg';





//персональні сторінки
//сантехнік
//Іванюк
import bgIvanuk from './img/masterdom/santexnik/ivanuk/4.jpg';
import avatarIvan from './img/masterdom/santexnik/ivanuk/avatar.jpeg';
import ivanuk1 from './img/masterdom/santexnik/ivanuk/5.jpg';
import ivanuk2 from './img/masterdom/santexnik/ivanuk/6.jpeg';
import ivanuk3 from './img/masterdom/santexnik/ivanuk/7.jpg';
import ivanuk4 from './img/masterdom/santexnik/ivanuk/8.jpeg';
import ivanuk5 from './img/masterdom/santexnik/ivanuk/9.jpeg';
import ivanuk6 from './img/masterdom/santexnik/ivanuk/10.jpeg';
import ivanuk7 from './img/masterdom/santexnik/ivanuk/11.jpg';
import ivanuk8 from './img/masterdom/santexnik/ivanuk/12.jpg';
import ivanuk9 from './img/masterdom/santexnik/ivanuk/13.jpeg';
import ivanuk10 from './img/masterdom/santexnik/ivanuk/14.jpg';


//кондиціонер
//Іванюк
import ivanukKondAvatar from './img/masterdom/kondicioner/ivaniuk/avatarIvanKond1.jpeg';
import bgKondIvanuk from './img/masterdom/kondicioner/ivaniuk/bg_kond_ivan.jpg';
import kondIvanuk1 from './img/masterdom/kondicioner/ivaniuk/1.jpg';
import kondIvanuk2 from './img/masterdom/kondicioner/ivaniuk/2.jpg';
import kondIvanuk3 from './img/masterdom/kondicioner/ivaniuk/3.jpg';
import kondIvanuk4 from './img/masterdom/kondicioner/ivaniuk/4.jpg';
import kondIvanuk5 from './img/masterdom/kondicioner/ivaniuk/5.jpg';
import kondIvanuk6 from './img/masterdom/kondicioner/ivaniuk/6.jpg';
import kondIvanuk7 from './img/masterdom/kondicioner/ivaniuk/7.jpg';

//комуналка
//Укренергооблік
import bgUkrenergooblik from './img/komunal/lichilnik/ukrenergooblik.jpg';
import aboutUkrenergooblik from './img/komunal/lichilnik/aboutukrenergo.png';


import ukrenergooblik3 from './img/komunal/lichilnik/3.png';
import ukrenergooblik4 from './img/komunal/lichilnik/4.png';
import ukrenergooblik5 from './img/komunal/lichilnik/5.png';
import ukrenergooblik6 from './img/komunal/lichilnik/6.png';
import ukrenergooblik7 from './img/komunal/lichilnik/7.jpg';
import ukrenergooblik8 from './img/komunal/lichilnik/8.jpg';
import ukrenergooblik9 from './img/komunal/lichilnik/9.png';
import ukrenergooblik10 from './img/komunal/lichilnik/10.png';

//доставка
//Максім
import bgMaksim from './img/dostavka/maksim/bgmaksim.jpg';
import fotomain from './img/reklama/fotomain.png';

import reklama1 from './img/reklama/reklama1.png';
import reklama2 from './img/reklama/reklama2.png';
import reklama4 from './img/reklama/reklama4.png';
import reklama5 from './img/reklama/reklama5.png';
import reklama6 from './img/reklama/reklama6.png';
import reklama7 from './img/reklama/reklama7.png';
import reklama8 from './img/reklama/reklama8.png';
import reklama9 from './img/reklama/reklama9.png';
import videoAboutReklama from './img/reklama/1.mp4';

import lobach from './img/reklama/lobach.mp4';

import bgreklama from './img/reklama/bgreklama.jpg';

export const IpkContext = createContext(null);
IpkContext.displayName = "IpkContext";

const InfoContext = ({ children }) => {

  const vips = [

    {
      id:445,
      name: '"Elena Cosmetology"',
      status: true,
      vip: false,
      address: 'Коцюбинське',
      fotowork: [strelchuk_1, strelchuk_2, strelchuk_3, strelchuk_4, strelchuk_5],              
      descrip: ['Мене звати - Олена'],
      olldescrips:['✅ Я вже 12 років допомагаю жінкам лікувати свою шкіру та підтримувати її здоров’я та молодість', '✅ Надаю всі види косметологічних послуг', '✅  Не зволікайте,телефонуйте або пишіть - я з радістю допоможу Вам', '✅ Дівчатка, здорова та сяюча шкіра -це не мрія,а реальність '],
      workTime: ['', '', ''],
      tel:'tel:+380975355385',
      instagram: 'https://www.instagram.com/elena___kosmetologg.zt',
      viber: 'viber://chat?number=+380975355385',
    

     
    },

    {
      id:483,
      name: 'Студія "Laser Art"',
      names: "Б'юті сфера",
      status: true,
      vip: false,
      address: ' вул. Доківська 10, корп. 8',
      fotowork: [lazerart_1, lazerart_2, lazerart_3, lazerart_4, lazerart_5],      
      zamovlena: 'https://bookon.ua/laser-art',
      nameZamovlena: 'Онлайн запис',        
      descrip: ['Пропонуємо Вам:'],
      olldescrips:['✔ Лазерна епіляція', '✔ Косметологія (апаратна,  ін’єкційна, естетична)', '✔ Масаж (ручний, апаратний)', '✔ Косметика на розлив', 'Наші переваги:', '💉Косметологи з медосвітою', '✅Сертифіковане обладнання та препарати'],
      workTime: ['пн-нд 10:00-19:00', '', ''],
      instagram: 'https://www.instagram.com/laser_art.ua/', 
      tel:'tel:+380983138772',
      location:"https://maps.app.goo.gl/EBzas19vY4CYtdPG7",
   
    },


    {
      id:6210, 
      name: 'Піцерія "SANCHO PIZZA"',
      status: true,
      vip: false,
      names: 'Кафе, Ресторани, Паби',
      address: ' вул. Доківська 14',
      fotowork:[ sancho_1, sancho_2, sancho_3, sancho_4, sancho_5],  
      descrip:['Пропонуємо Вам:'],
      olldescrips:['🍕 Найсмачнішу піцу в Коцюбинському', '🍕 Від класики до екзотики 🍕🍕🍕', '🍕 Скуштуйте - смачно буде всім!💙💛', '🍕 Доставляємо по всьому Коцюбинському 🛴'],
      zamovlena: 'https://www.sanchopizza.com.ua/',
      nameZamovlena: 'Замовити доставку',
      workTime: ['пн-нд 11:00 - 21:00', '', ''], 
      tel:'tel:+380688419311',
      network:'https://www.sanchopizza.com.ua/',
      location: 'https://maps.app.goo.gl/ipUC5Tqfk6hLz27f7',
      telegram:'tg://resolve?domain=sanchopizza',
      instagram: 'https://www.instagram.com/sancho_pizza_ua?igsh=dTB6dWM0cGVqenhp'

    },   
    {
      id:4101,
      name: 'Аліна Волківська LR🍀бізнес онлайн💶',
      status: true,
      vip: false,
      names: 'БАДи та Косметика',
      fotowork:[ volkivska_1, volkivska_2, volkivska_3, volkivska_4, volkivska_5],  
      descrip:['Пропоную:'],          
      olldescrips: ['Коктейлі для схуднення', 'Гелі Алоє для очищення кишківника та імунітету', 'Вітаміни для дітей та дорослих. Люксова косметика', 'Продукція виробництва Німеччина'],
      workTime: ['', '', ''],
      instagram: 'https://www.instagram.com/volkovskaiaalina',
      tel:'tel:+380939511447',
    telegram:'tg://resolve?domain=VolkovskaiaLR', 
    },

    {
      id:321,
      name: 'УкрЕнергоОблік ',
      // more: 'Дізнатися більше',
      status: true,
      vip: true,
      
      names: 'Повірка лічильників',
      foto: [aboutUkrenergooblik],
      fotowork:[ ukrenergooblik3, ukrenergooblik4, ukrenergooblik5, ukrenergooblik6, ukrenergooblik9],
      descrip:['Послуги:'],
      olldescrips: ['☑ Втановлення теплолічильників під ключ(з опломбуванням від КТЕ) - від 5500 грн', 
      '☑ Повірка теплолічильників під ключ (з розпломбуванням та опломбуванням від КТЕ) - від 1400 грн + 250 грн заміна живлення ',
      '☑ Повірка водяних лічильників - від 400 грн'],
      tel:'tel:+380959014990', 
      facebook:'https://www.facebook.com/UkrEnerhoOblik/',
    },
          

    {
      id:211,
      name: 'Олексій ',
      names:'Сантехнік',
      more: 'Дізнатися більше',
      status: true,
      vip: true,
      age: 35,
      foto: [ ivanukKondAvatar ],
     
      fotowork:[ ivanuk1, ivanuk2, ivanuk3, ivanuk4, ivanuk5, ivanuk6, ivanuk7,ivanuk8, ivanuk9, ivanuk10],
      descrip: ['Виконую роботи такі як:'],
      olldescrips: [
      'Монтаж, заміна, чищення  бойлера', 
      'Встановлення або заміна пральної машини, посудомийної машини, радіаторів, лічильників води',  
      'Монтаж або ремонт душових кабін, ванн, джакузі, мийок, умивальників,  унітазів, біде',
      'і т.д.',  
    ],
      tel:'tel:+380660966450',
      viber: 'viber://chat?number=+380660966450',
      instagram: 'https://instagram.com/santeh_kiev_kcb/', 
      network:'https://ipk.net.ua/2/21/211',
     
    },

    {
      id:437,
      name: 'Beauty Master "Anna Si"',
      names:'Перукарні',
      status: true,
      vip: false,
      address: ' ',
      foto: [ann_si],  
      fotowork:[ann_si_1, ann_si_2, ann_si_3, ann_si_4, ann_si_5 ],            
      descrip: [''],
      olldescrips: ['Anna Si - Перукар - Колорист', 'Сучасні техніки фарбування та стрижок', 'Біо завивка'],
      workTime: ['', '', ''],
      instagram: 'https://www.instagram.com/anna_si_8/',
      tel:'tel:+380932575758',
      // location:"https://maps.app.goo.gl/TkCoWh7HADLgVNFG9", 
    },

    {
      id:2101,
      name: 'Встановлення та чищення кондиціонерів ',
      names:'Майстер по кондиціонерам',
      more: 'Дізнатися більше',
      status: true,
      vip: false,
      age: 35,
      foto: [ivanukKondAvatar],
      fotowork:[kondIvanuk1, kondIvanuk2, kondIvanuk3, kondIvanuk4, kondIvanuk5, kondIvanuk6, kondIvanuk7 ],

      descrip: ['Виконую роботи такі як:'],
      olldescrips: [ 
      ' Монтаж та демонтаж  будь-яких кондиціонерів, мульти-спліт систем, напівпромислових',  
      'Профілактика та чистка кондиціонерів всіх типів та брендів',
      'Ремонт кондиціонерів всіх типів та брендів',  
    ],
      tel:'tel:+380660966450',
      viber: 'viber://chat?number=+380660966450',
      network:'https://ipk.net.ua/2/210/2101',
      instagram: '', 
    },
    {
      id:2511,
      name: ' Віталій Лобач',
      names:'Концерти',
      status: true,
      vip: false,
      address: '',
       video: lobach,
      fotowork:[ reklama1, reklama2, reklama4, reklama5, reklama6, reklama7, reklama8, reklama9],
      zamovlena: 'https://kotsiubynske.karabas.com/vitaliy-lobach-37/order/',
      nameZamovlena: 'Купити квитки',
      header:'Послуги:',
      descrip: ['22 березня 18:00 Будинок культури'],
      olldescrips: [''],
      tel:'tel:+380968053879',
       location:"https://maps.app.goo.gl/kD8yzwkGnJKYh2UEA", 
     
    },
    



    {
      id:2511,
      name: 'Розширена персональна  сторінка',
      names:'Платне розміщення ЛЕНДІНГ',
      more: 'Перейти на сторінку',
      status: true,
      vip: false,
      address: '',
       video: videoAboutReklama,
      fotowork:[ reklama1, reklama2, reklama4, reklama5, reklama6, reklama7, reklama8, reklama9],
      // video: videoAboutReklama,
      header:'Послуги:',
      descrip: ['Виконую роботи такі як:'],
      olldescrips: ['Знаходиться в конкретному розділі та на головній сторінці (ТОП)','Короткий опис Вашого бізнесу або Вашої роботи  на картці та необмежений на персональній сторінці','Текст до 170 символів на картці та необмежений на персональній сторінці',
      'До 10-ти фотографій та одне відео', 'Ціна - 1000 грн/міс'],
      telegram:'tg://resolve?domain=KotsiuBilka',
      email: 'mailto: ipk.kcb@gmail.com',
      facebook:'https://www.facebook.com/',
      network:'https://ipk.net.ua/26/261/2611',
      instagram: 'https://www.instagram.com/kotsyubilka', 
    },
    {
      id:2522,
      name: 'Ваша назва "Бізнесу"',
      names:'Платне розміщення ТОП',
      status: true,
      vip: false,
      address: '',
      fotowork:[ reklama4, reklama5, reklama2, reklama8],
      descrip: ['Виконую роботи такі як:'],
      olldescrips: ['Знаходиться в конкретному розділі та на головній сторінці (ТОП)','Короткий опис Вашого бізнесу або Вашої роботи','Текст до 170 символів',
      'До 5-ти фотографій', 'Ціна - 500 грн/міс'],              
      tel:'',
      telegram:'tg://resolve?domain=KotsiuBilka',
      email: 'mailto: ipk.kcb@gmail.com',
      network:'https://ipk.net.ua/26/262',  
      instagram: 'https://www.instagram.com/kotsyubilka',   
    }, 
    {
      id:2531,
      name: 'Ваша назва "Бізнесу"',
      names:'Безкоштовне розміщення',
      status: true,
      vip: false,
      address: '',
      fotowork:[ reklama2],
      descrip: ['Виконую роботи такі як:'],
      olldescrips: ['Знаходиться в конкретному розділі ','Короткий опис Вашого бізнесу або Вашої роботи - до 170 символів',
      'Одне фото (Крім розділу Нерухомість)'],                   
      telegram:'tg://resolve?domain=KotsiuBilka',
      facebook:'',
      email: 'mailto: ipk.kcb@gmail.com',
      network:'https://ipk.net.ua/26/263',
      instagram: 'https://www.instagram.com/kotsyubilka',
     
       
    },   


  ]

    const kategoris = [{
            id: 1,
            name: 'Нерухомість',
            foto: nedvijemost,
            types: [
              {
              id: 11,
              name:'Житлова нерухомість',
              foto: nedvijemost,
              lists: [
                 {
                  id:1129,
                  rentsal: 'Оренда',
                  kimnat: 1,
                  vlasnik: 'Посередник',
                  // colorvlasnik:'0da514',
                  name: '1-к  вул. Доківська 10',
                  foto: [ren_1к_dokivska_10_18_40_1, ren_1к_dokivska_10_18_40_2, ren_1к_dokivska_10_18_40_3, ren_1к_dokivska_10_18_40_4, ren_1к_dokivska_10_18_40_5],
                  interv: null,
                  currency: '$',
                  descrip: ['Здається однокімнатна квартира після капітального ремонту.Квартира розташована в новому будинку 2022року зданий в експлуатацію. Квартира здається перший раз. Ремонт, вбудовані меблі та техніка нові. '],
                  price: 268.29,
                  flor: 18,
                  area: 40,
                  priceTime: 100,
                  priceTimeGrn: 500,
                  florTime: 2,
                  areaTime: 3,
                  status: true,
                  vip: false,
                  tel:'tel:+380674041007',
                  location: 'https://maps.app.goo.gl/RAtguWAHP53xWuKn9',
                  network: 'https://www.olx.ua/d/obyavlenie/orenda-1-km-st-m-akademmstechko-kotsyubinske-vul-dokvska-10-IDXEZNa.html?isPreviewActive=0&sliderIndex=11',
                },
                {
                  id:1128,
                  rentsal: 'Продаж',
                  kimnat: 3,
                  vlasnik: 'Посередник',
                  // colorvlasnik:'0da514',
                  name: '3-к вул. Пономарьова 26',
                  foto: [sal_3_k_ponomar26_107_7et_1, sal_3_k_ponomar26_107_7et_2, sal_3_k_ponomar26_107_7et_3, sal_3_k_ponomar26_107_7et_4, sal_3_k_ponomar26_107_7et_5],
                  interv: null,
                  currency: '$',
                  descrip: ['Простора сучасна квартира, облаштована меблями і побутовою технікою. Три окремі кімнати,два санвузла, два балкона. Квартира не вуглова.', 'Є відео квартири. Перегляд по домовленості.'],
                  price: 88500,
                  flor: 7,
                  area: 107,
                  priceTime: 1500,
                  priceTimeGrn: 70000,
                  florTime: 2,
                  areaTime: 3,
                  status: true,
                  vip: false,
                  tel:'tel:+380973934491',
                  location: 'https://maps.app.goo.gl/Mo73CUnrpnWvvo286',
                  network: 'https://www.olx.ua/d/obyavlenie/prodam-3k-kvartiru-v-kotsyubinskomu-vul-ponomarova-26-IDXlOJY.html',
                },
                {
                  id:1127,
                  rentsal: 'Продаж',
                  kimnat: 1,
                  vlasnik: 'Власник',
                  // colorvlasnik:'0da514',
                  name: '1-к  вул. Доківська 10, корп. 10',
                  foto: [sal_1k_dokivska10_10_19_25_1, sal_1k_dokivska10_10_19_25_2, sal_1k_dokivska10_10_19_25_3, sal_1k_dokivska10_10_19_25_4, sal_1k_dokivska10_10_19_25_5],
                  interv: null,
                  currency: '$',
                  descrip: ['Продаж квартири - студії в смт Коцюбинське Київській області, ЖК АТЛАНТ 2. Квартира знаходиться на 19 поверсі. Площа 25,6/14,4 квадратних метрів. Квартира видова, з ремонтом, укомплектована меблями і технікою. Тариф на електроенергію розділений на денний і нічний. Підлога - плитка Великий балкон можна використовувати як кабінет.'],
                  price: 29000,
                  flor: 19,
                  area: 25.6,
                  priceTime: 1500,
                  priceTimeGrn: 70000,
                  florTime: 2,
                  areaTime: 3,
                  status: true,
                  vip: false,
                  tel:'tel:+380668431392',
                  location: 'https://maps.app.goo.gl/TQ3hkV1zdvjoqVPu5',
                  network: 'https://www.olx.ua/d/obyavlenie/studya-v-zhk-atlant-smt-kotsyubinske-kivska-obl-IDOR6Cq.html',
                },
                {
                  id:1126,
                  rentsal: 'Продаж',
                  kimnat: 1,
                  vlasnik: 'Без комісії',
                  // colorvlasnik:'0da514',
                  name: '1-к  вул. Доківська 10, корп. 8',
                  foto: [sal_1k_dokivska10_8_15_28_1,sal_1k_dokivska10_8_15_28_2, sal_1k_dokivska10_8_15_28_3, sal_1k_dokivska10_8_15_28_4,sal_1k_dokivska10_8_15_28_5],
                  interv: null,
                  currency: '$',
                  descrip: ['Продається квартира-студія від власника. Квартира розташована на 15-му поверсі 25-ти поверхового будинку.', 'Висота стелі 2,55 м, броньовані вхідні двері, міжкімнатні двері відсутні, стяжка підлоги, стан стін-штукатурка', 'Якщо цікавить програма єОселя, ціна квартири становитиме 28 000 у.о.'],
                  price: 25900,
                  flor: 15,
                  area: 28.2,
                  priceTime: 1500,
                  priceTimeGrn: 70000,
                  florTime: 2,
                  areaTime: 3,
                  status: true,
                  vip: false,
                  tel:'tel:+380734361727',
                  location: 'https://maps.app.goo.gl/TQ3hkV1zdvjoqVPu5',
                  network: 'https://www.olx.ua/d/obyavlenie/prodazh-smart-kvartiri-IDXELJ6.html',
                },

                // {
                //   id:1125,
                //   rentsal: 'Продаж',
                //   kimnat: 1,
                //   vlasnik: 'Власник',
                //   // colorvlasnik:'0da514',
                //   name: '1-к вул. Пономарьова 26 корп. 5',
                //   foto: [sal_ponomar_26_12_30_1, sal_ponomar_26_12_30_2, sal_ponomar_26_12_30_3, sal_ponomar_26_12_30_4, sal_ponomar_26_12_30_5],
                //   interv: null,
                //   currency: '$',
                //   descrip: ['Продаж 1к квартири- студі з якісним ремонтом,ніхто не проживав,в новому будинку', 'Повна комплектація : меблі,техніка,інтернет проведений.Право власності більше 3 років( податки мінімальні)'],
                //   price: 47499,
                //   flor: 12,
                //   area: 30,
                //   priceTime: 1500,
                //   priceTimeGrn: 70000,
                //   florTime: 2,
                //   areaTime: 3,
                //   status: true,
                //   vip: false,
                //   tel:'tel:+380982308487',
                //   location: 'https://maps.app.goo.gl/H7aBwm6HtcQ5Vkhs6',
                //   network: 'https://www.olx.ua/d/uk/obyavlenie/prodazh-kvartiri-z-remontom-vd-hazyana-IDVfLtS.html',
                // },
              
                // {
                //   id:1124,
                //   rentsal: 'Продаж',
                //   kimnat: 1,
                //   vlasnik: 'Посередник',
                //   // colorvlasnik:'0da514',
                //   name: '1-к вул. Пономарьова 6/2',
                //   foto: [sal_1k_ponomar_4et_27_1, sal_1k_ponomar_4et_27_2, sal_1k_ponomar_4et_27_3, sal_1k_ponomar_4et_27_4, sal_1k_ponomar_4et_27_5],
                //   interv: null,
                //   currency: '$',
                //   descrip: ['Квартира знаходиться на 4/9 пов. ', 'Площа 27/13/4 кв.м. Чудовий стан. Все залишається. Через дорогу ліс, в сусідньому будинку поліклініка. Поряд школа.', 'Комісія агентства 5%'],
                //   price: 28000,
                //   flor: 4,
                //   area: 27,
                //   priceTime: 1500,
                //   priceTimeGrn: 70000,
                //   florTime: 2,
                //   areaTime: 3,
                //   status: true,
                //   vip: false,
                //   tel:'tel:+380672326088',
                //   location: 'https://maps.app.goo.gl/tTjkrCBEBnSKP6A29',
                //   network: 'https://www.olx.ua/d/uk/obyavlenie/prodam-odnokmnatnu-kvartiru-v-kotsyubinskomu-IDX14Ua.html',
                // },
                // {
                //   id:1123,
                //   rentsal: 'Продаж',
                //   kimnat: 1,
                //   vlasnik: 'Посередник',
                //   // colorvlasnik:'0da514',
                //   name: '1-к вул. Пономарьова 18а',
                //   foto: [sal_ponomar18a_9_43_1, sal_ponomar18a_9_43_2, sal_ponomar18a_9_43_3, sal_ponomar18a_9_43_4, sal_ponomar18a_9_43_5],
                //   interv: null,
                //   currency: '$',
                //   descrip: ['Квартира 43 кв.м. , кімната 18 кв.м., кухня 8 кв.м.,балкон з кухні. Гарний жилий стан дозволяє заїхати в день угоди. Укомплектована меблями і технікою. Поруч зупинка транспорту, магазини, кафе, парк, м.Академмістечко 7 хвилин',],
                //   price: 39900,
                //   flor: 9,
                //   area: 43,
                //   priceTime: 1500,
                //   priceTimeGrn: 70000,
                //   florTime: 2,
                //   areaTime: 3,
                //   status: true,
                //   vip: false,
                //   tel:'tel:+380668100910',
                //   location: 'https://maps.app.goo.gl/qSfHaQqTurnFL2B2A',
                //   network: 'https://www.olx.ua/d/obyavlenie/prodazh-1-kmn-ponomareva-18a-park-gaz-kotsyubinske-IDVp14d.html?isPreviewActive=0&sliderIndex=5',
                // },
                // {
                //   id:1122,
                //   rentsal: 'Продаж',
                //   kimnat: 1,
                //   vlasnik: 'Посередник',
                //   // colorvlasnik:'0da514',
                //   name: '1-к вул. Пономарьова 26,  корп 5',
                //   foto: [sal_1k_ponomar26_5_4_32_1, sal_1k_ponomar26_5_4_32_2, sal_1k_ponomar26_5_4_32_3, sal_1k_ponomar26_5_4_32_4, sal_1k_ponomar26_5_4_32_5],
                //   interv: null,
                //   currency: '$',
                //   descrip: ['Продається 1-кімнатна квартира-студія в ЖК “Атлант2”, вул. Пономарьова, смт Коцюбинське.Пропонуємо вам унікальну можливість стати власником сучасної квартири з дизайнерським ремонтом у стилі лофт.',],
                //   price: 38000,
                //   flor: 4,
                //   area: 32,
                //   priceTime: 1500,
                //   priceTimeGrn: 70000,
                //   florTime: 2,
                //   areaTime: 3,
                //   status: true,
                //   vip: false,
                //   tel:'tel:+380980189188',
                //   location: 'https://maps.app.goo.gl/71qhM1U2HyTgDVTQ9',
                //   network: 'https://www.olx.ua/d/uk/obyavlenie/prodayu-1-k-kvartiru-zhk-atlant2-kotsyubinske-IDWD35e.html?isPreviewActive=0&sliderIndex=0',
                // },
                // {
                //   id:1121,
                //   rentsal: 'Продаж',
                //   kimnat: 1,
                //   vlasnik: 'Посередник',
                //   // colorvlasnik:'0da514',
                //   name: '1-к вул. Пономарьова 26,  корп 3',
                //   foto: [sal_1k_ponomar26_3_15_39_1, sal_1k_ponomar26_3_15_39_2, sal_1k_ponomar26_3_15_39_3, sal_1k_ponomar26_3_15_39_4, sal_1k_ponomar26_3_15_39_5],
                //   interv: null,
                //   currency: '$',
                //   descrip: ['','Коцюбинське,вул.Пономарьова 26,корпус 3,красива новобудова ж/к "Атлант",поверх 15/24,площа 39/16/8,ремонт,лоджія застеклена,первага квартири в житловій кімнаті два вікна можна зробити дві кімнати.'],
                //   price: 41000,
                //   flor: 15,
                //   area: 39,
                //   priceTime: 1500,
                //   priceTimeGrn: 70000,
                //   florTime: 2,
                //   areaTime: 3,
                //   status: true,
                //   vip: false,
                //   tel:'tel:+380632470498',
                //   location: 'https://maps.app.goo.gl/ChPFmmqhzaGR5hkZ7',
                //   network: 'https://www.olx.ua/d/uk/obyavlenie/prodam-1-u-kotsyubinske-novobudova-vul-ponomarova-26-zh-k-atlant-IDWQBkw.html',
                // },

                {
                  id:1120,
                  rentsal: 'Продаж',
                  kimnat: 1,
                  vlasnik: 'Посередник',
                  // colorvlasnik:'0da514',
                  name: '1-к вул. Доківська 10,  корп 10',
                  foto: [sal_dokivska_10_10_4_35_1, sal_dokivska_10_10_4_35_2, sal_dokivska_10_10_4_35_3, sal_dokivska_10_10_4_35_4, sal_dokivska_10_10_4_35_5],
                  interv: null,
                  currency: '$',
                  descrip: ['','Продаж 1к квартири 35м2, 4/25 поверх ( дуже тепла), З правом власності, 7 хв Академістечко . Підʼїзд з Консьержем, у дворі садочок та школа. В гарному стані, можливо з меблями та технікою'],
                  price: 47000,
                  flor: 4,
                  area: 35.5,
                  priceTime: 1500,
                  priceTimeGrn: 70000,
                  florTime: 2,
                  areaTime: 3,
                  status: true,
                  vip: false,
                  tel:'tel:+380676911815',
                  location: 'https://maps.app.goo.gl/FGX6w5iM7aWy67Fr5',
                  network: 'https://www.olx.ua/d/uk/obyavlenie/zhk-atlant-kotsyubinske-vul-dokvska-10-korp-10-odnokm-z-remontom-IDTrQfv.html?isPreviewActive=0&sliderIndex=13',
                },
        
                // {
                //   id:1119,
                //   rentsal: 'Продаж',
                //   kimnat: 1,
                //   vlasnik: 'Посередник',
                //   // colorvlasnik:'0da514',
                //   name: '1-к вул. Доківська 10,  корп 4',
                //   foto: [sal_1k_dokivska_10_14_42_1, sal_1k_dokivska_10_14_42_2, sal_1k_dokivska_10_14_42_3, sal_1k_dokivska_10_14_42_4, sal_1k_dokivska_10_14_42_5],
                //   interv: null,
                //   currency: '$',
                //   descrip: ['','Продаж 1-кімнатної квартири з євроремонтом у ЖК Атлант. У квартирі зроблено ремонт в 2020 році,є все необхідне для комфортного проживання:кондиціонер,електрична плита,витяжка, ліжко,шафа,бойлер,холодильник.'],
                //   price: 54900,
                //   flor: 14,
                //   area: 42,
                //   priceTime: 1500,
                //   priceTimeGrn: 70000,
                //   florTime: 2,
                //   areaTime: 3,
                //   status: true,
                //   vip: false,
                //   tel:'tel:+380504245536',
                //   location: 'https://maps.app.goo.gl/Q1VFHHM8eL5hhRns8',
                //   network: 'https://www.olx.ua/d/uk/obyavlenie/prodazh-kvartiri-dokvska-vul-10k4-smt-kotsyubinske-IDWXB7M.html',
                // },
                // {
                //   id:1118,
                //   rentsal: 'Продаж',
                //   kimnat: 2,
                //   vlasnik: 'Без комісії',
                //   // colorvlasnik:'0da514',
                //   name: '2-к вул. Пономарьова 26,  корп 3',
                //   foto: [sal_1k_ponomar26_6_40_1, sal_1k_ponomar26_6_40_2, sal_1k_ponomar26_6_40_3, sal_1k_ponomar26_6_40_4, sal_1k_ponomar26_6_40_5],
                //   interv: null,
                //   currency: '$',
                //   descrip: ['','Планування: кухня-студія та окрема кімната. Квартира готова до заселення — повністю обладнана технікою: духова шафа, варильна поверхня, витяжка, мікрохвильова піч, холодильник, пральна машина.'],
                //   price: 52500,
                //   flor: 6,
                //   area: 40,
                //   priceTime: 1500,
                //   priceTimeGrn: 70000,
                //   florTime: 2,
                //   areaTime: 3,
                //   status: true,
                //   vip: false,
                //   tel:'tel:+380504234145',
                //   location: 'https://maps.app.goo.gl/T8WGWxX15kQQPRK79',
                //   network: 'https://www.olx.ua/d/uk/obyavlenie/bez-vro-2-ka-z-vroremontom-ta-meblyami-kotsyubinske-zhk-atlant-IDVdTSH.html?isPreviewActive=0&sliderIndex=5',
                // },
                // {
                //   id:1117,
                //   rentsal: 'Продаж',
                //   kimnat: 1,
                //   vlasnik: 'Без комісії',
                //   // colorvlasnik:'0da514',
                //   name: '1-к вул. Доківська 10',
                //   foto: [sal_dokivska_10_5_41_1, sal_dokivska_10_5_41_2, sal_dokivska_10_5_41_3, sal_dokivska_10_5_41_4],
                //   interv: null,
                //   currency: '$',
                //   descrip: ['В квартирі розведена електрику, воду, вирівняні повністю стіни з використанням армуючої сітки, підведено інтернет, встановлені красиві та якісні двері. В квартирі встановлений індивідуальний лічильник тепла та на коридорі в місці розташування лічильника води змонтовано фільтрувальна установка води.'],
                //   price: 46000,
                //   flor: 5,
                //   area: 41.3,
                //   priceTime: 1500,
                //   priceTimeGrn: 70000,
                //   florTime: 2,
                //   areaTime: 3,
                //   status: true,
                //   vip: false,
                //   tel:'tel:+380737781055',
                //   location: 'https://maps.app.goo.gl/bnfTwgC7pv2HWWot5',
                //   network: 'https://www.olx.ua/d/obyavlenie/1-kmnatna-kvartira-zhk-atlant-kotsyubinske-41-3-kv-m-IDVbpKk.html',
                // },
                // {
                //   id:1116,
                //   rentsal: 'Оренда',
                //   kimnat: 2,
                //   vlasnik: 'Посередник',
                //   // colorvlasnik:'0da514',
                //   name: '1-к вул. Пономарьова 26,  корп 1',
                //   foto: [ren_ponomar26_1_17_46_1, ren_ponomar26_1_17_46_2, ren_ponomar26_1_17_46_3, ren_ponomar26_1_17_46_4, ren_ponomar26_1_17_46_5],
                //   interv: null,
                //   currency: '$',
                //   descrip: ['Пропонуємо в оренду 2-кімнатну квартиру з щомісячною оплатою, можливо на тривалий термін.', ' У квартирі зроблений гарний ремонт, є всі необхідні меблі та техніка.'],
                //   price: 317.07,
                //   flor: 17,
                //   area: 75,
                //   priceTime: 100,
                //   priceTimeGrn: 500,
                //   florTime: 2,
                //   areaTime: 3,
                //   status: true,
                //   vip: false,
                //   tel:'tel:+380934560032',
                //   location: 'https://maps.app.goo.gl/H14MGUoo9hp63H3B7',
                //   network: 'https://www.olx.ua/d/obyavlenie/orenda-2-k-kvartiri-smt-kotsyubinske-vul-ponomarova-IDOq7OP.html',
                // },
                // {
                //   id:1115,
                //   rentsal: 'Продаж',
                //   kimnat: 1,
                //   vlasnik: 'Без комісії',
                //   // colorvlasnik:'0da514',
                //   name: '1-к вул. Пономарьова 26,  корп 4',
                //   foto: [sal_1_k_ponomar26_19_46_1, sal_1_k_ponomar26_19_46_2, sal_1_k_ponomar26_19_46_3, sal_1_k_ponomar26_19_46_4, sal_1_k_ponomar26_19_46_5],
                //   interv: null,
                //   currency: '$',
                //   descrip: ['Квартира ремонтом. Все робили для себе, якісно та з душею.  Квартира оснащена всім потрібним для проживання. Можна заїхати і жити. Кухня укомплектована (варильна поверхня, плита, холодильник). Містка шафа - купе. С/в оснащений, пральна машинка, бойлер.', ''],
                //   price: 52999,
                //   flor: 19,
                //   area: 46,
                //   priceTime: 1500,
                //   priceTimeGrn: 70000,
                //   florTime: 2,
                //   areaTime: 3,
                //   status: true,
                //   vip: false,
                //   tel:'tel:+380961786299',
                //   location: 'https://maps.app.goo.gl/u2G5W38tyS6aJQvG8',
                //   network: 'https://www.olx.ua/d/obyavlenie/bez-prodam-1k-kvartiru-46m2-zhk-atlant-metro-akademgorodok-IDWgPVP.html',
                // },
                // {
                //   id:1114,
                //   rentsal: 'Продаж',
                //   kimnat: 1,
                //   vlasnik: 'Без комісії',
                //   // colorvlasnik:'0da514',
                //   name: '1-к вул. Пономарьова 26,  корп 3',
                //   foto: [sal_1_k_ponomar26_40_24et_1, sal_1_k_ponomar26_40_24et_2, sal_1_k_ponomar26_40_24et_3, sal_1_k_ponomar26_40_24et_4, sal_1_k_ponomar26_40_24et_5 ],
                //   interv: null,
                //   currency: '$',
                //   descrip: ['Квартира з ремонтом. Все робили для себе, якісно та з душею. Сучасний ремонт. Квартира укомплектована всім потрібним для проживання. Можна заїхати і жити. Кухня укомплектована (варильна поверхня, плита, холодильник, барна стійка). Містка шафа - купе.', ''],
                //   price: 48999,
                //   flor: 24,
                //   area: 40,
                //   priceTime: 1500,
                //   priceTimeGrn: 70000,
                //   florTime: 2,
                //   areaTime: 3,
                //   status: true,
                //   vip: false,
                //   tel:'tel:+380961786299',
                //   location: 'https://maps.app.goo.gl/T8WGWxX15kQQPRK79',
                //   network: 'https://www.olx.ua/d/uk/obyavlenie/bez-prodam-1k-kvartiru-zhk-atlant-metro-akademgorodok-IDTwddw.html',
                // },
               
                {
                  id:1112,
                  rentsal: 'Продаж',
                  kimnat: 1,
                  vlasnik: 'Посередник',
                  // colorvlasnik:'0da514',
                  name: '1-к  вул. Пономарьова 26',
                  foto: [sal_1_k_ponomar26_49_18et_1, sal_1_k_ponomar26_49_18et_2, sal_1_k_ponomar26_49_18et_3, sal_1_k_ponomar26_49_18et_4 ],
                  interv: null,
                  currency: '$',
                  descrip: ['По функціонуванню, як двокімнатна. Велика кухня вітальня 20,6м2 з виходом на лоджію. Вбудована кухня з мікрохвильовою піччю, духовою шафою, витяжкою, холодильником та кондиціонером. Кімната 12,2м2, просторий коридор 10м2 з шафою купе, сумісний санвузол 4,8м2. Всі меблі та техніка залишаються.'],
                  price: 57000,
                  flor: 18,
                  area: 49,
                  priceTime: 1500,
                  priceTimeGrn: 70000,
                  florTime: 2,
                  areaTime: 3,
                  status: true,
                  vip: false,
                  tel:'tel:+380677163620',
                  location: 'https://maps.app.goo.gl/2E8R762Bb1ynThZd6',
                  network: 'https://www.olx.ua/d/uk/obyavlenie/1-kmnatna-49m2-z-remontom-v-zhk-atlant-ponomarova-IDWIxek.html',
                },
              
                
                // {
                //   id:116,
                //   rentsal: 'Продаж',
                //   vlasnik: 'Без комісії',
                //   kimnat: 3,
                //   // colorvlasnik:'0da514',
                //   name: '3-к вул. Пономарьова 26 корп.1',
                //   foto: [ _3ponomar26_1_107_1, _3ponomar26_1_107_2, _3ponomar26_1_107_3, _3ponomar26_1_107_4, _3ponomar26_1_107_5, _3ponomar26_1_107_6],
                //   interv: null,
                //   currency: '$',
                //   descrip: [' У квартирі залишається все!Велика кухня 17 кв. з балконом.  Кімната-гостина 23кв.м., з каміном та шкіряними меблями, друга кімната зроблена під спальню повністю обладнана як на фото, в якій є ще один балкон, дитяча кімната, передпокої 20кв.м. Два інвекторні кондиціонери. Роздільний с/в, встановлені два бойлери.', ],
                //   price: 103000,
                //   flor: 15,
                //   area: 107,
                //   priceTime: 1500,
                //   priceTimeGrn: 70000,
                //   florTime: 2,
                //   areaTime: 3,
                //   status: true,
                //   vip: false,
                //   tel:'tel:+380933030000',
                //   network:'https://www.olx.ua/d/uk/obyavlenie/kotsyubinske-zhk-atlant-2-z-yaksnim-dorogim-remontom-IDWfx1w.html',

                //   location: 'https://maps.app.goo.gl/QGGik92HHtpwokcv6'
                // },
                
                // {
                //   id:118,
                //   rentsal: 'Оренда',
                //   kimnat: 3,
                //   vlasnik: 'Посередник',
                //   // colorvlasnik:'0da514',
                //   name: '3-к вул. Пономарьова 26,  корп 4',
                //   foto: [_3ponomar26_1_106_18_1, _3ponomar26_1_106_18_2, _3ponomar26_1_106_18_3, _3ponomar26_1_106_18_4, _3ponomar26_1_106_18_5, _3ponomar26_1_106_18_6 ],
                //   interv: null,
                //   currency: '$',
                //   descrip: ['У квартирі виконано якісний, сучасний ремонт. Все робили для себе. Кімнати роздільні. Кухня укомплектована всім потрібним для проживання. 2с/в.  Встановлені лічильник на опалення, що дозволяє значно економити на к/п', ''],
                //   price: 536.56,
                //   flor: 18,
                //   area: 106,
                //   priceTime: 100,
                //   priceTimeGrn: 500,
                //   florTime: 2,
                //   areaTime: 3,
                //   status: true,
                //   vip: false,
                //   tel:'tel:+380961786299',
                //   location: 'https://maps.app.goo.gl/Rh6q7gKvonR1BiQi7',
                //   network: 'https://www.olx.ua/d/uk/obyavlenie/arenda-3k-kvartiry-zhk-atlant-metro-akademgorodok-IDUZCVl.html?isPreviewActive=0&sliderIndex=8',
                // },
                
               
                {
                  id:114,
                  rentsal: 'Продаж',
                  vlasnik: 'Посередник',
                  kimnat: 2,
                  // colorvlasnik:'0da514',
                  name: 'Продам 2-кім кв. вул Доківська 10 корпус 12',
                  foto: [dokivska_119_1, dokivska_119_2, dokivska_119_3, dokivska_119_4],
                  interv: null,
                  currency: '$',
                  descrip: [' Загальна площа 40,56 м2( можливо вільне плавання відсутність несучих стін). Не кутова!', 'Можливо встановити індивідуальний лічильник на опалення.', 'Тип угоди: Переуступка! Здача 2024р.' ],
                  price: 34000,
                  flor: 16,
                  area: 40.56,
                  priceTime: 1500,
                  priceTimeGrn: 60000,
                  florTime: 2,
                  areaTime: 3,
                  status: true,
                  vip: false,
                  tel:'tel:+380504469897',
                  network:'https://www.olx.ua/d/uk/obyavlenie/zhk-atlant-2km-40-56kv-16-24-poverh-pereustupka-IDUwYco.html',
                },
                {
                  id:115,
                  rentsal: 'Продаж',
                  vlasnik: 'Посередник',
                  kimnat: 3,
                  // colorvlasnik:'0da514',
                  name: '3-к вул. Пономарьова 26 корп.1',
                  foto: [ ponomar26_20_28_1, ponomar26_20_28_2, ponomar26_20_28_3, ponomar26_20_28_4, ponomar26_20_28_5],
                  interv: null,
                  currency: '$',
                  descrip: ['Продається 3к квартира по вул. Пономарьова 26/1',  'Загальна площа 120кв.м., житлова 70кв.м., кухня 16кв.м., 3 балкона, санвузол роздільний,заскленний загальний балкон на підїзді,додатково встановленні двері на підїзді,кладова', '', ],
                  price: 100000,
                  flor: 22,
                  area: 120,
                  priceTime: 1500,
                  priceTimeGrn: 70000,
                  florTime: 2,
                  areaTime: 3,
                  status: true,
                  vip: false,
                  tel:'tel:+380679127057',
                  location: 'https://maps.app.goo.gl/mgMdQVBh8GAjaFY97',
                  network:'https://www.olx.ua/d/uk/obyavlenie/tsnu-znizheno-oselya-vdnovlennya-3-k-120m2-zhk-atlant-100000-IDUvuwI.html',
                },

               
              
               
              ]
              },
              {
                id: 12,
                name:'Комерційна нерухомість',
                foto: oficeNedv,
                lists: [
                  
                 
                  {
                    id:123,
                    rentsal: 'Оренда',
                    vlasnik: 'Без комісії',
                    // colorvlasnik:'0da514',
                    name: 'Приміщення 281 кв.м. вул. Пономарьова 7.',
                    foto: [bc_ponomar7_3pov_1, bc_ponomar7_3pov_2, bc_ponomar7_3pov_3, bc_ponomar7_3pov_4, bc_ponomar7_3pov_5 ],
                    interv: null,
                    currency: 'грн',
                    descrip: [' Стан: після будівельних робіт, чиста стяжка, панорамні вікна;','Планування: відкритий простір (open space). Висота стелі: понад 3 м; ',  ' Освітлення: природне світло завдяки великим панорамним вікнам.','Ціна - договірна' ],
                    price: 0.02439,
                    flor: 3,
                    area: 281,
                    priceTime: 1500,
                    priceTimeGrn: 2000,
                    florTime: 2,
                    areaTime: 3,
                    status: true,
                    vip: false,
                    tel:'tel:+380503463502',
                    location: 'https://maps.app.goo.gl/oVyL93TmyBQ8uQr8A',
                    
                  },
                  {
                    id:124,
                    rentsal: 'Оренда',
                    vlasnik: 'Без комісії',
                    // colorvlasnik:'0da514',
                    name: 'Приміщення 269 кв.м. вул. Пономарьова 7.',
                    foto: [bc_ponomar7_4pov_1, bc_ponomar7_4pov_2, bc_ponomar7_4pov_3, bc_ponomar7_4pov_4, bc_ponomar7_4pov_5 ],
                    interv: null,
                    currency: 'грн',
                    descrip: [' Стан: після будівельних робіт, чиста стяжка, панорамні вікна;','Планування: відкритий простір (open space). Висота стелі: понад 3 м; ',  ' Освітлення: природне світло завдяки великим панорамним вікнам.','Ціна - договірна' ],
                    price: 0.02439,
                    flor: 4,
                    area: 269,
                    priceTime: 1500,
                    priceTimeGrn: 2000,
                    florTime: 2,
                    areaTime: 3,
                    status: true,
                    vip: false,
                    tel:'tel:+380503463502',
                    location: 'https://maps.app.goo.gl/oVyL93TmyBQ8uQr8A',
                    
                  },
                 
                 
                ]
                
              },
            ]

        },
        { 
            id: 2,
            name: 'Майстри по дому',         
            foto: master,
            types: [ 
              {
              id: 21,
              name:'Сантехнік',
              foto: santexnik,
              lists: [
                {
                  id:211,
                  name: 'Олексій ',
                  more: 'Дізнатися більше',
                  status: true,
                  vip: false,
                  age: 35,
                  foto: [ avatarIvan ],

                  fotowork:[ ivanuk1, ivanuk2, ivanuk3, ivanuk4, ivanuk5, ivanuk6, ivanuk7,ivanuk8, ivanuk9, ivanuk10],
                  descrip: ['Виконую роботи такі як:'],
                  olldescrips: [
                  'Монтаж, заміна, чищення  бойлера', 
                  'Встановлення або заміна пральної машини, посудомийної машини, радіаторів, лічильників води',  
                  'Монтаж або ремонт душових кабін, ванн, джакузі, мийок, умивальників,  унітазів, біде',
                  'і т.д.',  
                ],
                  tel:'tel:+380660966450',
                  viber: 'viber://chat?number=+380660966450',
                  instagram: 'https://instagram.com/santeh_kiev_kcb/', 
                  network:'https://ipk.net.ua/2/21/211',
                  nameclient:'Задоволених клієнтів',
                  nameprojects:'Виконаних проектів',
                  nameexpience:'Років досвіду',
                  client: 500,
                  projects: 37,
                  experience: 10,
                  clientTime: 10,
                  projectsTime: 5,
                  nameblock1:'ПРО МЕНЕ',
                  nameblock2:'ВИКОНАНІ РОБОТИ',
                  nameblock3:'ЦІНИ НА РОБОТИ',


                  nameWork:'Сантехнік в Коцюбинському',
                  bgdescrip: 'Виконую роботи як по ремонту так і встановленню сантехніки. Все виконую якісно та у поставлені терміни.',
                  fotomaindescrip: 'Мене звати Олексій',
                  fotodescrip: ['Я маю достатньо великий досвід  робіт у сфері сантехніки, тому я впевнений, що зможу надати вам послуги високої якості.', ' Також я надаю допомогу у підборі комплектуючих для ремонту вашої сантехніки та можу запропоную  оптимальне рішення ціни та якості. Як правило, в більшості випадків я прибуваю на замовлення вже з комплектуючими для ремонту сантехніки.', 'На всю виконану роботу я надаю гарантію до 1 року. Оплата за виконану роботу здійснюється після виконання замовлення.'],
                  workTime: ['пн-нд 10.00-20.00', '', ''],
                  bgpesonal: bgIvanuk,
                  btnbgtel:'Викликати майстра',
                  priceservise: [
                    { name: "Втановлення бойлера/Заміна бойлера", price: "від 1500/900 грн" },
                    { name: "Підключення пральної або посудомийної машини", price: "від 600 грн" },
                    { name: "Встановлення радіаторів/Заміна радіаторів", price: "від 1500/600 грн" },
                    { name: "Встановлення лічильників/Заміна лічильників", price: "від 1500/600 грн" },
                    { name: "Встановлення душових кабін", price: "від 1600 грн" },
                    { name: "Встановлення ванн/Ремонт ванн", price: "від 1500/600 грн" },
                    { name: "Встановлення джакузі/Ремонт джакузі", price: "від 2500/2000 грн" },
                    { name: "Встановлення Умивальників/Кухонних мийок", price: "від 800 грн" },
                    { name: "Встановлення унітазів/Встановлення біде", price: "від 1500 грн" },
                    { name: "Мінімальний виклик в межах Коцюбинського", price: "від 300 грн" },
                  ]

                },
                {
                  id:212,
                  name: 'Данііл ',
                
                  status: true,
                  vip: false,
                  age: 35,
                  foto: [daniil1],
                  descrip: 'Сервісний інженер з очистки води',
                  olldescrips: [
                  'Монтаж та повне обслуговування очисних систем для води.', 
                  'Таких як Зворотній осмос і Колони.',  
                  'Осмос: Заміна всіх картриджів,мембрани; доустановка мінералізатора; дезинфекція; повна перевірка роботи;',
                  'Колони та кабінети: перевірка та корекція показників клапана; заміна картриджів ВВ20; чистка клапана;',
      
                ],
                  tel:'tel:+380973696199',
                  telegram: 'tg://resolve?domain=Catcin906',
                },


              ]
              },
              {
                id: 22,
                name:'Електрик',
                foto: electric,
                lists: [
                  {
                    id:225,
                    name: 'Встановлення інверторів під ключ – якісно та надійно!',              
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [vstanovinvert],
                    descrip: 'Опис робіт:',
                    olldescrips: [
                    'Повний комплекс робіт: проєктування, монтаж, налаштування.',  
                    'Якісні матеріали та гарантія на роботу.',
                    'Підключення до мережі, резервне живлення, заземлення.',
                    'Консультація та підтримка.',
                    'Телефонуйте або пишіть для безкоштовної консультації!'
                  ],
                    tel:'tel:+380688059173',
                    viber: 'viber://chat?number=+380688059173',
                    network: 'https://project11746391.tilda.ws/',
                    instagram: '', 
                    client: 350,
                    projects: 55,
                    experience: 10
                  },
                  {
                    id:224,
                    name: 'Ремонт електрики та побутової техніки',              
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [electriktehnika],
                    descrip: 'Виконую роботи такі як:',
                    olldescrips: [
                    'Послуги з усунення несправностей в електриці ',  
                    'Послуги з усунення несправностей в сантехніці',
                    'Ремонт побутової техніки.'
                  ],
                    tel:'tel:+380507318675',
                    viber: 'viber://chat?number=+380507318675',
                    instagram: '', 
                    client: 350,
                    projects: 55,
                    experience: 10
                  },
                  {
                    id:223,
                    name: 'Автономне живлення',
                  
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [dzgerelo],
                    descrip: 'Виконую підключення:',
                    olldescrips: [
                    'Батарей',  
                    'Інверторів',
                    'Генераторів',
                    'В будинках квартирах, пром приміщеннях',  
                  ],
                    tel:'tel:+380672388677',
                    instagram: '', 
                    client: 350,
                    projects: 55,
                    experience: 10
                  },
                  {
                    id:221,
                    name: 'Ігор ',
                  
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [electric],
                    descrip: 'Виконую роботи такі як:',
                    olldescrips: [
                    'Електромонтажні роботи будь-якої складності',  
                    'Встановлення або демонтаж електрощитів та лічильників електроенергії',
                    'Установка чи демонтаж трансформаторів, дроселів',
                    'Складання, підключення або демонтаж люстр, бра, світильників',  
                  ],
                    tel:'tel:+380975131430',
               
                    instagram: '', 
                    client: 350,
                    projects: 55,
                    experience: 10
                  },
                  {
                    id:222,
                    name: 'Сергій ',
                  
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [sergeiElectrik],
                    descrip: 'Виконую роботи такі як:',
                    olldescrips: [
                    'Пропоную послуги електрика', 
                    'Повний та частковий монтаж проводки',  
                    'Ремонт електро установок,',
                    'частково пристрої мобільних телефонів та акустичних систем, та багато іншого, все по домовленості',
        
                  ],
                    tel:'tel:+380972768290',
                  
                    instagram: '', 
                    client: 350,
                    projects: 55,
                    experience: 10
                  },
                ]
              },
              {
                id: 23,
                name:'Меблеві майстри',
                foto: mebel,
                lists: [
                  {
                    id:231,
                    name: 'Shun Mebel ',          
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [shunmebel],
                    descrip: 'Виконую роботи такі як:',
                    olldescrips: [
                    'Меблі під замовлення Коцюбинське', 
                    'Розробка та виготовлення меблів',  
                    'Індивідуальний підхід до приміщень',
                    'Візуалізація проектів/доставка/монтаж',  
                  ],
                    tel:'tel:+380509994167',
                    // viber: 'viber://chat?number=+380509994167',
                    instagram: 'https://www.instagram.com/shummebel?igsh=MzRlODBiNWFlZA==', 
                  
                  },
                  {
                    id:232,
                    name: 'Іван ',          
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [naGodinu],
                    descrip: 'Виконую роботи такі як:',
                    olldescrips: [
                      'Майстр на годину',
                    'Збір готових меблів', 
                    'Ремонт меблів',  
                
                  ],
                    tel:'tel:+380968028094',
                    // viber: 'viber://chat?number=+380968028094',
                
                  
                  },
                  {
                    id:233,
                    name: 'Меблі від "А" до "Я" ',          
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [artMebel],
                    descrip: 'Виконую роботи такі як:',
                    olldescrips: [
                      'Виготовлення меблів будь-якої складності та на будь-який смак.',  
                
                  ],
                  telegram:'tg://resolve?domain=ART7Luxe',
                    tel:'tel:+380988770087',
                    // viber: 'viber://chat?number=+380988770087',
                
                  
                  },
                ]
              },
              {
                id: 24,
                name:'Майстри з ремонту холодильників',
                foto: holodilnik, 
                lists: [
                  {
                    id:241,
                    name: 'Сергій ',
                  
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [holodilnik],
                    descrip: 'Виконую роботи такі як:',
                    olldescrips: [
                    'Ремонт холодильників будь-якої складності', 
                  
                  ],
                  tel:'tel:+380968894855',
                  
                  },
                  {
                    id:241,
                    name: 'Вячеслав ',
                  
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [holodvachslav],
                    descrip: 'Виконую роботи такі як:',
                    olldescrips: [
                    'заміна мотора-компресора, заміна терморегулятора, усунення промерзання теплоізоляції, усуваємо засмічення капілярної труби, заміна капілярної труби, заміна конденсаторів, заміна фільтра осушувача,заправка холодоагентом, та інше...', 
                  ],
                  tel:'tel:+380982525226',
                  // viber: 'viber://chat?number=+380667212593',
                  network: 'https://masterholodservice.com.ua/'
                  },
                  
                ]
              },
              {
                id: 25,
                name:'Майстpи з ремонту мікрохвильовок',
                foto: svh,
                lists: [
                  {
                    id:251,
                    name: 'Олексій ',          
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [svh],
                    descrip: 'Виконую роботи такі як:',
                    olldescrips: [
                    'Ремонт мікрохвилльовок будь-якої складності', 
                    'Будь-який бренд',  
                 
            
                  ],
                    tel:'tel:+380985142798',
                 
                  },
                  
                ]
              },
              
              {
                id: 26,
                name:'Майстри з ремонту посудомийок',
                foto: posudom,
                lists: [
                  {
                    id:261,
                    name: 'Генадій ',          
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [posudom],
                    descrip: 'Виконую роботи такі як:',
                    olldescrips: [
                    'Ремонт посудомийної машини будь якої складності', 
                    'Будь-який бренд',  
                    'Встановлення та заміна посудомийних машин',
            
                  ],
                    tel:'tel:+380971032949',
                    // viber: 'viber://chat?number=+380971032949',       
                  },
                  {
                    id:261,
                    name: 'Евген ',          
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [posudom],
                    descrip: 'Виконую роботи такі як:',
                    olldescrips: [
                    'Ремонт посудомийної машини будь якої складності', 
                    'Будь-який бренд',  
                    'Встановлення та заміна посудомийних машин',
            
                  ],
                    tel:'tel:+380983708430',
                    // viber: 'viber://chat?number=+380983708430',       
                  },
                  
                  
                ]
              },
              {
                id: 27,
                name:'Майстри з ремонту пральних машин',
                foto: sma,
                lists: [
                  {
                    id:271,
                    name: 'Євген ',          
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [sma],
                    descrip: 'Виконую роботи такі як:',
                    olldescrips: [
                    'Ремонт пральної машини будь якої складності', 
                    'Будь-який бренд',  
                    'Встановлення та заміна пральних машин',
            
                  ],
                    tel:'tel:+380983708430',
                    // viber: 'viber://chat?number=+380983708430',
                   
                  
                  },
                  
                ]
              },
              {
                id: 28,
                name:'Майстри з ремонту варильних поверхонь',
                foto: varochna,
                lists: [
                  {
                    id:281,
                    name: 'Ігор',          
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [varochna],
                    descrip: 'Виконую роботи такі як:',
                    olldescrips: [
                    'Ремонт індукційних плит будь якої складності', 
                    'Встановлення індукційних плит',   
                  ],
                    tel:'tel:+380975131430',
                           
                  },
                  
                ]
              },
              {
                id: 29,
                name:'Майстри з ремонту духових шаф',
                foto: duhovka,
                lists: [
                  {
                    id:291,
                    name: 'Tech Help',          
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [duhovka],
                    descrip: 'Виконую роботи такі як:',
                    olldescrips: [
                    'Ремонт духовок  будь якої складності', 
                    'Встановлення та підключення ',   
                  ],
                    tel:'tel:+380635790053',
             
                  },
                  
                ]
              },
              {
                id: 210,
                name:'Майстри по кондиціонерам',
                foto: kondicioner,
                lists: [
                  {
                    id:2101,
                    name: 'Олексій ',
                    names:'Майстер по кондиціонерам',
                    // more: 'Дізнатися більше',
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [ivanukKondAvatar],
                    fotowork:[kondIvanuk1, kondIvanuk2, kondIvanuk3, kondIvanuk4, kondIvanuk5, kondIvanuk6, kondIvanuk7 ],
              
                    descrip: ['Виконую роботи такі як:'],
                    olldescrips: [ 
                    ' Монтаж та демонтаж  будь-яких кондиціонерів, мульти-спліт систем, напівпромислових',  
                    'Профілактика та чистка кондиціонерів всіх типів та брендів',
                    'Ремонт кондиціонерів всіх типів та брендів',  
                  ],
                    tel:'tel:+380660966450',
                    viber: 'viber://chat?number=+380660966450',
                    instagram: '', 
                    network:'https://ipk.net.ua/2/210/2101',
                    nameclient:'Обслуговуваних кондиціонерів',
                    nameprojects:'Встановлених кондиціонерів',
                    nameexpience:'Років досвіду',
                    client: 1700,
                    projects: 750,
                    clientTime: 70,
                    projectsTime: 30,
                    experience: 7,
                    nameblock1:'ПРО МЕНЕ',
                    nameblock2:'ВИКОНАНІ РОБОТИ',
                    nameblock3:'ЦІНИ НА РОБОТИ',
                    nameWork:'Майстер по кондиціонерам в Коцюбинському',
                    bgdescrip: 'Виконую роботи як по ремонту так і встановленню  та чищенню кондиціонерів. Все виконую якісно та надаю гарантію на роботу.',
                    fotomaindescrip: 'Мене звати Олексій',
                    fotodescrip: ['Я надаю проповний спектр послуг, включаючи сервіс, професійне встановлення, регулярне технічне обслуговування, чищення, заправку, ремонт та заміну деталей. ', ' Я дбаю про те, щоб ваш кондиціонер працював на 100%.', 'Я гарантую надійність, ефективність та оперативність виконання робіт'],
                    workTime: ['пн-нд 10.00-20.00', '', ''],
                    bgpesonal: bgKondIvanuk,
                    btnbgtel:'Викликати майстра',
                    priceservise: [
                      { name: " Стандартний монтаж кондиціонера настінного типу 07-09", price: "від 4500 грн" },
                      { name: " Стандартний монтаж кондиціонера настінного типу 12-15", price: "від 5200 грн" },
                      { name: "Сервісне обслуговування кондиціонера", price: "від 900 грн" }, 
                      { name: "Демонтаж кондиціонера", price: "від 1500 грн" },
     
                      { name: "Ремонт плати управління", price: "від 1500 грн" },
                      { name: "Заміна конденсатора кондиціонера", price: "від 500 грн" },
                      { name: "Ремонт дренажної системи кондиціонера", price: "від 400 грн" },
                       { name: "Заправка кондиціонера фреоном (фреон R-22, R-410, R-32)", price: "250 грн. / 100г" },
                       { name: "Пайка труби", price: "від 400 грн" },
                       { name: "Діагностика кондиціонера", price: "від 300 грн" },
                      
                    ]
                  },
                  
                ]
              },
              {
                id: 211,
                name:'Майстри по вікнам',
                foto: vikna,
                lists: [
                  {
                    id:2114,
                    name: 'Майстри по вікнах',  
                    address: ' вул. Доківська, 1-Б 2-й поверх магазину "Будматеріали"',        
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [budmaterialvikna],
                    descrip: 'Пропонуємо Вам',
                    olldescrips: [ 
                    'Продаж, встановлення, обслуговування вікон, балконів', 
                    'Виносні, внутрішні та зовнішні оздоблювальні роботи.',  
                    'Комплектуючі до вікон.',
                  ],
                    tel:'tel:+380669798918',
                    viber: 'viber://chat?number=+380669798918',
                    location: 'https://maps.app.goo.gl/x6pWtxyLC9v18bDu9',
          
                  
                  },
                  {
                    id:2113,
                    name: 'Майстр по вікнах',          
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [mastervikon],
                    descrip: 'Виконую роботи такі як:',
                    olldescrips: [ 
                    'Герметизація та регулювання вікон', 
                    'Москитні сітки, тонування та бронювання скла.',  
                    'Робимо все що зв’язано з вікнами, діагностика по Коцюбинському безкоштовно',
                  ],
                    tel:'tel:+380638640418',
                    viber: 'viber://chat?number=+380638640418',
          
                  
                  },
                  {
                    id:2111,
                    name: ' Вікна Технік ',          
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [moskitni],
                    descrip: 'Виконую роботи такі як:',
                    olldescrips: [
                    'Виготовлення Москітних сіток для вікон, дверей, балконів', 
                    'Замір та монтаж по Коцюбинському бескоштовний',  
                  ],
                    tel:'tel:+380669560482',
                    // viber: 'viber://chat?number=+380669560482',
          
                  
                  },

                  {
                    id:2112,
                    name: 'Олександр',          
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [vikna],
                    descrip: 'Виконую роботи такі як:',
                    olldescrips: [
                    'Виготовлення металопластикових вікон', 
                    'Монтаж металопластикових вікон',  
                    'Регулювання металопластикових вікон',
                  ],
                    tel:'tel:+380509675011',
                    // viber: 'viber://chat?number=+380509675011',
          
                  
                  },
                  
                  
                ]
              },
              {
                id: 212,
                name:'Майстри з газового обладнання',
                foto: gaz,
                lists: [
                  {
                    id:2121,
                    name: 'Микола',          
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [gaz],
                    descrip: 'Ремонт газового обладняння',
                    olldescrips: [
                    'Ремонт, заміна, встановлення, підключення, чищення, профілактика, консультація ГАЗ. колонок, плит, поверхонь, духовок, котлів, конвекторів, кранів, шланг та ін...',                  
                    'По всім питанням телефонуйте',  
                  ],
                    tel:'tel:+380981233390',                    
                  },
           
                ]
              },
              {
                id: 213,
                name:'Клінінгові послуги',
                foto: klining,
                lists: [
                  {
                    id:2133,
                    name: '',          
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [klining],
                    descrip: 'Послуги з прибирання',
                    olldescrips: [
                    "Пропоную послуги з прибирання! Хімчистка м'яких меблів, мийка вікон (будь-якої складності)",                  
                    'Всі питання в Месенджер або Viber чи Telegram',  
                  ],
                    tel:'tel:+380978701373',
                    viber: 'viber://chat?number=+380978701373',
                    telegram: 'tg://resolve?domain=IrinaRasulova94'                    
                  },
                  {
                    id:2132,
                    name: 'Світлана',          
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [robotokna],
                    descrip: 'Оренда робота для миття вікон',
                    olldescrips: [
                    'Оренда 300 грн/доба',                  
                    'По всім питанням телефонуйте',  
                  ],
                    tel:'tel:+380983039111',                    
                  },
                  {
                    id:2133,
                    name: 'Світлана',          
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [paroochisnik],
                    descrip: 'Оренда пароочисника',
                    olldescrips: [
                      'Пароочисник Karcher SC 4 EasyFix Premium!',
                      'Потужний та надійний пароочисник для проведення генерального прибирання вашого житла.',
                    
                    'Оренда 350 грн/доба',                  
                   
                  ],
                    tel:'tel:+380932797042',  

                               
                  },
                ]
              },
              {
                id: 214,
                name:'Майстри з ремонту телефонів, ноутбуків',
                foto: remtelefon,
                lists: [
                  {
                    id:2141,
                    name: 'МАКСік Сервіс',          
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [maksik],
                    descrip: 'Виконую роботи такі як:',
                    olldescrips: [
                    'Ви розбили тачскрін або дисплей на телефоні (планшеті) - це до нас.', 
                    'Злетіла прошивка - не проблема.',  
                    'Матриця ноутбука не хоче радувати Вас яскравими квітами - можемо допомогти.',  
                  ],
                    tel:'tel:+380961127323',
                    network: 'https://maksikservice.com.ua/ua/',
                    location: 'https://maps.app.goo.gl/oSJDZJ1LgRHxnyrV8'            
                  },
                 
                ]
              },
              {
                id: 215,
                name:' Майстри з ремонту замків',
                foto: remzamkov,
                lists: [
                  {
                    id:2151,
                    name: 'Сергій',          
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [remzamkov],
                    descrip: 'Виконую роботи такі як:',
                    olldescrips: [
                    'Установка дверних замків',
                    'Ремонт замків', 
                    'Заміна дверних замків',  
                    '',  
                  ],
                    tel:'tel:+380981216986',
                       
                  },
                 
                ]
              },
              {
                id: 216,
                name:'Капітальний ремонт квартир',
                foto: kaprem,
                lists: [
                  {
                    id:2163,
                    name: 'Влад',          
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [vladfilipchuk],
                    descrip: 'Маю порушення зі слуху',
                    olldescrips: [
                      'Можна написати у вайбер, також у мене є мобільний додаток сурдоперекладу.',
                      'Виконую роботи такі як:',
                      'Якісно будь які плиточні роботи.',
                    'Виконаю штукатурні роботи ',
                    'Фарбування стін',
                    'Збірка меблів та інше',  
                    'Можлива консультація через відеодзвінок з сурдоперекладом.'
                     
                
                  ],
                  telegram: 'tg://resolve?domain=vlad_filipchuk95',
                  instagram: 'https://www.instagram.com/vlad_filipchuk95',
                    viber: 'viber://chat?number=+380634961326',
                
                  
                  },
                  {
                    id:2162,
                    name: 'Плитка. Укладання кафелю. ',          
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [plitka],
                    descrip: 'Виконую роботи такі як:',
                    olldescrips: [
                      'Якісно будь які плиточні роботи. ',
                    'Консультація безкоштовна.', 
                     
                
                  ],
                    tel:'tel:+380933907620',
                    viber: 'viber://chat?number=+380933907620',
                
                  
                  },
                  {
                    id:2161,
                    name: 'Іван ',          
                    status: true,
                    vip: false,
                    age: 35,
                    foto: [naGodinu],
                    descrip: 'Виконую роботи такі як:',
                    olldescrips: [
                      'Дрібна малярка, укладка ламінату, плиточні роботи',
                    'Роботи з електрикою/сантехнікою', 
                    'Монтаж сантехнічних приладів', 
                    'Збір і ремонт готових меблів',  
                    'Сервіс і монтаж кондиціонерів (закладні)', 
                    'Утеплення балконів',  
                
                  ],
                    tel:'tel:+380968028094',
                    // viber: 'viber://chat?number=+380968028094',
                
                  
                  },
                 
                ]
              },
              
            ]

        },
       
        { 
            id: 3,
            name: 'Комунальні послуги',
            foto: komunalka,
                types: 
          [
            {
              id: 31,
              name:'Жек, ЖЕО, ОСББ, УК',
              foto: osbb,
              lists: 
              [
                {
                  id:311,
                
                  name: 'ТОВ "УК Коцюбинське"',
                  status: true,
                  vip: false,
                  address: 'вулиця Пономарьова, 26, корпус 2, оф. 173,',
                  foto: [kotsubinuk],
                  workTime: ['пн-чт: 9:00-18:00', 'пт: 9:00-17:00', '', 'сб-нд: вихідний' ], 
                  tel:'tel:+380673265066',
                  facebook:'https://m.facebook.com/people/%D0%A2%D0%9E%D0%92-%D0%A3%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D1%96%D0%BD%D1%81%D1%8C%D0%BA%D0%B0-%D0%BA%D0%BE%D0%BC%D0%BF%D0%B0%D0%BD%D1%96%D1%8F-%D0%9A%D0%BE%D1%86%D1%8E%D0%B1%D0%B8%D0%BD%D1%81%D1%8C%D0%BA%D0%B5/100063531045430/?locale=pt_BR',
                  location: 'https://maps.app.goo.gl/QVZsA12DtrrJqTs3A' 
                }, 
                {
                  id:312,
                
                  name: 'УЖКГ "Біличі"',
                  status: true,
                  vip: false,
                  address: 'смт. Коцюбинське, вул. Доківська 5',
                  foto: [ujkg],
                  pokazi: 'tel:+380938690078',
                  pay: 'https://www.ipay.ua/ua/bills/oplata-kommunalnyh-uslug-kotsyubinskoe-belichi-cherez-internet',
                  workTime: ['пн-чт: 8:00-17:00', 'пт: 8:00-15:45', 'обід: 12:00-12:45', 'сб-нд: Вихідний' ], 
                  tel:'tel:+380673401570', 
                  facebook:'https://www.facebook.com/profile.php?id=100052730443381',
                  location: 'https://maps.app.goo.gl/DRcBsLMpryhiXESS7', 
                }, 
                {
                  id:313,
                
                  name: 'Аварійна служба "Ліфт-3"',
                  status: true,
                  vip: false,
                  address: 'смт. Коцюбинське',
                  foto: [elevator],
                  workTime: [ ], 
                  tel:'tel:+380504402438', 
                  network: 'https://ukrlift.ua/',
                }, 
            
                      
              ]         
            },
            {
              id: 32,
              name:'Встановлення та повірка лічильників',
              foto: lichilnik,
              lists: 
              [
                {
                  id:321,
                  name: 'УкрЕнергоОблік ',
                  // more: 'Дізнатися більше',
                  status: true,
                  vip: false,
                  address: 'Повірка лічильників',
                  foto: [aboutUkrenergooblik],
                  fotowork:[ ukrenergooblik3, ukrenergooblik4, ukrenergooblik5, ukrenergooblik6, ukrenergooblik7,ukrenergooblik8, ukrenergooblik9, ukrenergooblik10],
                  header:'Послуги:',
                  descrip: ['Встановлення теплолічильника під ключ - від 5500 грн', 
                  'Повірка теплолічильника під ключ - від 1400 грн + 250 грн заміна живлення ',
          
                  'Повірка водяних лічильників - від 400 грн'],
                  workTime: ['',], 
                  forma: 'sdvdv',
                  tel:'tel:+380959014990', // треба додати
                  // telegram:'380678367676',// треба додати
                  facebook:'https://www.facebook.com/UkrEnerhoOblik/',
                  // network:'http://localhost:3000/3/32/321', // треба додати
                  nameclient:'Повірених лічильників',
                  nameprojects:'Встановлених лічильників',
                  nameexpience:'Років досвіду',
                  nameblock1:'ПРО НАС',
                  nameblock2:'ВИКОНАНІ РОБОТИ',
                  nameblock3:'ЦІНИ НА РОБОТИ',

                  client: 4000,
                  projects: 1000,
                  experience: 7,
                  clientTime: 150,
                  projectsTime: 70,
                  bgdescrip: 'Короткий опис саме чим Ви займаєтесь, які надаєте послуги, або яку продукцію пропонуєте до 130 символів',
                  fotomaindescrip: 'Компанія "УкрЕнергоОблік"',
                  fotodescrip: ['Я мають достатньо великий досвід  робіт у сфері сантехніки,тому я впевнений що зможу надати вам послуги високої якості', ' Також я надаю допомогу у підборі комплектуючих для ремонту вашої сантехніки та запропоную Вам оптимальне рішення ціни та якості. Як правило в більшості випадків я прибуваю на замовлення вже з комплектуючими для ремонту сантехніки.', 'На всю виконану роботу я надаю гарантію до 1 року.Оплата за виконану роботу здійснюється після виконання замовлення.'],
                  nameWork:'Встановлення та повірка лічильників',
                  bgpesonal: bgUkrenergooblik,
                  btnbgtel:'Замовити повірку',
                  priceservise: [
                    { name: "Втановлення теплолічильників під ключ(з опломбуванням від КТЕ)", price: "від 5500 грн" },
                    { name: "Повірка теплолічильників під ключ (з розпломбуванням та опломбуванням від КТЕ)", price: "від 1600 грн" },
                    { name: "Заміна живлення теплолічильника", price: "від 1600 грн" },
                    { name: "Повірка лічильників води", price: "від 300 грн" },
                  ]
                },
                      
              ]         
            },
            {
              id: 33,
              name:'Адреси розміщення та телефони постачальників послуг',
              foto: kominikacii,
              lists: 
              [
                {
                  id:331,
                
                  name: 'ТОВ "КИЇВСЬКА ОБЛАСНА ЕК"',
                  status: true,
                  vip: false,
                  address: 'м. Ірпінь, вул. Університетська, буд. 2Ф/1, 120 ',
                  foto: [ek],
                  pokazi: 'https://koec.com.ua/page?root=63',
                  pay: 'https://www.ipay.ua/ua/bills/oplata-uslug-ooo-kievskaya-oblastnaya-ek-online',
                  workTime: ['пн-чт: 8:00-17:00', 'пт: 8:00-15:45', 'обід: 13:00-13:45', 'Вихідний' ], 
                  tel:'tel:+380800304909',
                  pokaziTelegram:'tg://resolve?domain=DTEKKyivskielectromerezhibot',
                  network: 'https://koec.com.ua/',
                  location: 'https://maps.app.goo.gl/KVjRbw2UWQnttbFB9' 
                }, 
                {
                id:332,
                
                name: 'ТОВ «ГK «Нафтогаз України»',
                status: true,
                vip: false,
                // address: 'м. Боярка, вул. Білогородська, 17',
                foto: [naftogas],
                pokazi: 'https://gas.ua/uk/indicators',
                pay: 'https://www.ipay.ua/ua/bills/oplata-uslug-nak-naftogaz-ukrainy-online',
                workTime: ['пн-чт: 8:00-17:00', 'пт: 8:00-15:45', 'без перерви'], 
                tel:'tel:+380983002888',
                network: 'https://gas.ua/uk/home',
                // location: 'https://www.google.com/maps/place/%D1%83%D0%BB.+%D0%91%D0%B5%D0%BB%D0%BE%D0%B3%D0%BE%D1%80%D0%BE%D0%B4%D1%81%D0%BA%D0%B0%D1%8F,+17,+%D0%91%D0%BE%D1%8F%D1%80%D0%BA%D0%B0,+%D0%9A%D0%B8%D0%B5%D0%B2%D1%81%D0%BA%D0%B0%D1%8F+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+08150/@50.3303999,30.2890073,17z/data=!4m16!1m9!3m8!1s0x40d4b5cc669b396d:0xbd70c4f4e1e821ce!2z0YPQuy4g0JHQtdC70L7Qs9C-0YDQvtC00YHQutCw0Y8sIDE3LCDQkdC-0Y_RgNC60LAsINCa0LjQtdCy0YHQutCw0Y8g0L7QsdC70LDRgdGC0YwsIDA4MTUw!3b1!8m2!3d50.3308007!4d30.2895071!10e5!16s%2Fg%2F1tdd8c04!3m5!1s0x40d4b5cc669b396d:0xbd70c4f4e1e821ce!8m2!3d50.3308007!4d30.2895071!16s%2Fg%2F1tdd8c04?entry=ttu' 
              }, 
                {
                  id:333,
                
                  name: 'ТОВ «Газорозподільні мережі України» ',
                  status: true,
                  vip: false,
                  address: 'м. Боярка, вул. Білогородська, 17',
                  foto: [gas],
                  
                  pay: 'https://www.ipay.ua/ua/bills/kyivska-filiia-gazmerezhi-ukrainy',
                  workTime: ['пн-чт: 8:00-17:00', 'пт: 8:00-15:45', 'без перерви'], 
                  tel:'tel:+380443646104',
                  network: 'https://kv.grmu.com.ua/',
                  location: 'https://maps.app.goo.gl/1cPo5pRm9FGmUtsC6' 
                }, 
                {
                  id:334,
                
                  name: 'УЖКГ "Біличі"',
                  status: true,
                  vip: false,
                  address: 'смт. Коцюбинське, вул. Доківська 5',
                  foto: [ujkg],
                  pokazi: 'tel:+380938690078',
                  pay: 'https://www.ipay.ua/ua/bills/oplata-kommunalnyh-uslug-kotsyubinskoe-belichi-cherez-internet',
                  workTime: ['пн-чт: 8:00-17:00', 'пт: 8:00-15:45', 'обід: 12:00-12:45', 'сб-нд: Вихідний' ], 
                  tel:'tel:+380673401570', 
                  facebook:'https://www.facebook.com/belichi/?locale=ru_RU',
                  location: 'https://maps.app.goo.gl/ysCT5r8QTiCKQCxn6', 
                }, 
                {
                  id:335,
                
                  name: 'КП «КИЇВТЕПЛОЕНЕРГО»',
                  status: true,
                  vip: false,
                  address: 'м. Київ, вул. Вернадського буд.63А',
                  foto: [kte],
                  pokazi: 'https://kte.kmda.gov.ua/my-cabinet/send_counters/guest_send_current_new',
                  pay: 'https://www.ipay.ua/ua/bills/oplata-uslug-kp-kievteploenergo-online',
                  workTime: ['ср: 9:00-18:00', 'пт: 9:00-16:45', 'обід: 13:00-13:45', 'сб-нд: Вихідний'], 
                  tel:'tel:+380673401570', 
                  network: 'https://kte.kmda.gov.ua/',
                  location: 'https://maps.app.goo.gl/QgiCQuQkY1zfzasU8', 
                }, 
                      
              ]         
            },
            
          ]
        },
        { 
            id: 4,
            name: "Б'юті сфера",
            foto: beauty,
            types: [
              {
              id: 41,
              name:'Манікюр',
              foto: manik,
              lists: [
                {
                  id:4110,
                  name: 'Annails',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 28',
                  foto: [ania_nails],              
                  descrip: [' Послуги манікюру/ педикюру /нарощування нігтів. Тривала носка, доступні ціни, всі етапи дезинфекції та стерилізації інструменту, час виконання роботи 1,5-2 години'],
                  workTime: ['', '', ''],
                  tel:'tel:+380991582443',
                  instagram: 'https://www.instagram.com/____annails___',
                  location:"https://maps.app.goo.gl/9X7JHgDMAcCeW41b9", 
           
                },
                {
                  id:411,
                  name: 'Салон краси "AVOKADO"',
                  status: true,
                  vip: false,
                  address: ' вул. Доківська 10, корп. 3',
                  foto: [avokado],              
                  descrip: [' Манікюр, педикюр'],
                  workTime: ['пн-нд 10.00-20.00', '', ''],
                  instagram: 'https://www.instagram.com/avokadobeauty/',
                  tel:'tel:+380739147717',
                  location:"https://maps.app.goo.gl/TkCoWh7HADLgVNFG9", 
           
                },
               
                {
                  id:413,
                  name: 'Ok.studio Nail',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 13a',
                  foto: [okstudio],              
                  descrip: [' Манікюр, Педикюр, Нарощування нігтів, Навчання'],
                  workTime: ['', '', ''],
                  instagram: 'https://www.instagram.com/ok.studio_nail/',
                  facebook:' https://www.facebook.com/OK.studio.nail',
                  tel:'tel:+380995282589',
                  location:"https://maps.app.goo.gl/kmxzJVCCUzM6gofM7", 
           
                },
                {
                  id:414,
                  name: 'SSS Lavrenyk',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 32',
                  foto: [manik],              
                  descrip: [' Манікюр, Педикюр, Нарощування нігтів, Комплекс за годину'],
                  workTime: ['', '', ''],
                  instagram: 'https://www.instagram.com/sss_lavrenyk_/',
                  location:"https://maps.app.goo.gl/JsEGtVrs27E1tDJn8", 
           
                },
                {
                  id:415,
                  name: 'Салон краси "LiKa"',
                  status: true,
                  vip: false,
                  address: 'вул. Бакала 44-В',
                  foto: [lika],              
                  descrip: ['  Манікюр, Педикюр'],
                  workTime: ['', '', ''],
                  instagram: 'https://www.instagram.com/lika_kotsyubinskoye/',
                  tel:'tel:+380689385074',
                  location:"https://maps.app.goo.gl/MUZFSxR6fZ5AXU2K6", 
           
                },
                {
                  id:416,
                  name: 'Простір краси "SALVADORE"',
                  status: true,
                  vip: false,
                  address: 'вул. Бакала 44-А',
                  foto: [salvadore],              
                  descrip: ['  Манікюр, Педикюр'],
                  workTime: ['пн-нд 10:00-21:00', '', ''],
                  instagram: 'https://www.instagram.com/salvadore_beauty_space/',
                  tel:'tel:+380689385074',
                  location:"https://maps.app.goo.gl/uD7TqD2JEzZMVQP38", 
           
                },
                {
                  id:417,
                  name: 'Manicure Lo',
                  status: true,
                  vip: false,
                  address: 'вул. Залізнична 1Е',
                  foto: [manicureLo],               
                  descrip: ['  Манікюр, Педикюр'],
                  workTime: ['', '', ''],
                  instagram: 'https://www.instagram.com/_manicure_lo/',
                  tel:'tel:+380980807847',
                  location:"https://maps.app.goo.gl/uNpf6puRhS9N8WTQ7", 
           
                },
                {
                  id:418,
                  name: 'Pingvin Manik',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 7А',
                  foto: [pingvin],               
                  descrip: ['Манікюр, Педикюр, Брови'],
                  workTime: ['', '', ''],
                  instagram: 'https://www.instagram.com/pingvin_manik/',
              
                  location:"https://maps.app.goo.gl/UDk15Yrrsj3R8ASq5", 
           
                },
                {
                  id:419,
                  name: 'Vikstydio',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 13а',
                  foto: [manik],              
                  descrip: [' Пилю як Бог вже 7 років, Психолог з фрезером, Моя супер сила швидкість, Комплекс за 55 хв ❤️ '],
                  workTime: ['', '', ''],
                  instagram: 'https://www.instagram.com/vikstydio_nail/',
                  location:"https://maps.app.goo.gl/kmxzJVCCUzM6gofM7", 
           
                },
                {
                  id:4111,
                  name: 'LNK Manic',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 13',
                  foto: [lnkmanic],              
                  descrip: [' Креативний майстер ✨ Зроблю той самий чіткий квадрат ✅'],
                  workTime: ['', '', ''],
                  tel:'tel:+380635841464',
                  instagram: 'https://www.instagram.com/lnk.manik ',
                  location:"https://maps.app.goo.gl/eTMuFsSR7SeQjYo16",
                 
           
                },
                
            
              ]
              },
              {
                id: 42,
                name:'Брови, вії',
                foto: brovi,
                lists: [
                  {
                    id:421,
                    name: 'Салон краси "AVOKADO"',
                    status: true,
                    vip: false,
                    address: ' вул. Доківська 10, корп. 3',
                    foto: [avokado],              
                    descrip: ['Стрижки, фарбування; Брови, вії; Макіяж; Шугаринг; Лазерна епіляція'],
                    workTime: ['пн-нд 10.00-20.00', '', ''],
                    instagram: 'https://www.instagram.com/avokadobeauty/',
                    tel:'tel:+380739147717',
                    location:"https://maps.app.goo.gl/TkCoWh7HADLgVNFG9", 
                  },
                  {
                    id:422,
                    name: 'Салон краси "Hair & Nail"',
                    status: true,
                    vip: false,
                    address: 'вулиця Доківська, 14/1 ТЦ "АТЛАНТ" 3-й пов.',
                    foto: [watsons],              
                    descrip: ['Фарбування, Укладання волосся, Манікюр, Педикюр, Брови, Вії'],
                    workTime: ['пн-нд 9:00-21:00', '', ''],
                    // instagram: 'https://www.instagram.com/hair.nail.studio/',
                    tel:'tel:+380686681823',
                    network: 'https://hairnail.kiev.ua/',
                    location:"https://maps.app.goo.gl/8JmmaG7xkqT7QLuP7", 
             
                  },
                  // {
                  //   id:423,
                  //   name: 'Brows By Alina',
                  //   status: true,
                  //   vip: false,
                  //   address: 'вул. Пономарьова 13а',
                  //   foto: [brovi],              
                  //   descrip: ['Корекція та окрашування брів, Ламінування вій та брів'],
                  //   workTime: ['', '', ''],
                  //   instagram: 'https://www.instagram.com/brows.by_alina/',
                  //   location:"https://maps.app.goo.gl/ZisUKKrxPXW8M5x8A", 
             
                  // },
                  // {
                  //   id:424,
                  //   name: 'BrowArt',
                  //   status: true,
                  //   vip: false,
                  //   address: 'вул. Доківська, 14/1 ТЦ "АТЛАНТ"',
                  //   foto: [watsons],              
                  //   descrip: ['Перманент брів та губ, Ламінування та фарбування брів, Ламінуваня та нарощування вій, Навчання'],
                  //   workTime: ['', '', ''],
                  //   instagram: 'https://www.instagram.com/brow_lash_tut/',
                  //   location:"https://maps.app.goo.gl/qekDeZpzCTR9npms7", 
             
                  // },
                  {
                    id:425,
                    name: 'Lash Alenka Kyiv',
                    status: true,
                    vip: false,
                    address: 'вул. Пономарьова 13',
                    foto: [lashAlenka],              
                    descrip: ['Нарощування вій, Ламінування вій'],
                    workTime: ['', '', ''],
                    tel:'tel:+380632735093',
                    instagram: 'https://www.instagram.com/lash_alenka_kyiv/',
                    location:"https://maps.app.goo.gl/X6ux3Jo2h18AJTji9", 
             
                  },
                  {
                    id:426,
                    name: 'Lami_mi_mi',
                    status: true,
                    vip: false,
                    address: 'вул. Пономарьова 6А',
                    foto: [lami_mi_mi],              
                    descrip: ['Ламінування вій з любов’ю до кожної вії.',   ' Зроблю гарні вії, які будуть вас тішити від 6 до 8 тижнів.', ' Стерильність понад усе!', ' Досвід роботи більше 5 років.'],
                    workTime: ['', '', ''],
                    tel:'tel:+380935767949',
                    instagram: 'https://instagram.com/lami_mi_mi',
                    location:"https://maps.app.goo.gl/qGMVZhAoEE6R52ZU8", 
             
                  },
                  {
                    id:427,
                    name: 'M.UA.DM',
                    status: true,
                    vip: false,
                    address: 'вул. Доківська 10 корп 9',
                    foto: [m_ua_dm],              
                    descrip: ['Корекція/ Фарбування/ ламі брів та вій' ],
                    workTime: ['', '', ''],
                    tel:'tel:+380986553315',
                    instagram: 'https://www.instagram.com/m.ua.dm',
                    location:"https://maps.app.goo.gl/Y2CWAHjaSrtgAuCX9", 
                   
                  },
                  {
                    id:428,
                    name: 'Zhlobina_by_lami',
                    status: true,
                    vip: false,
                    address: 'вул. Доківська 10, корп. 8',
                    foto: [zhlobina_by_lami],              
                    descrip: ['Всього 1хв. на запис📩', ' І на 5-6 тижнів можна забути про туш😍 ', ' Ціни доступні кожній,працюю тільки з високоякісними складниками❤️, Ламінування вій, Ламінування брів, Фарбування вій ' ],
                    workTime: ['', '', ''],
                    tel:'tel:+380635155083',
                    instagram: 'https://www.instagram.com/zhlobina_by_lami',
                  
                  },
                ]
              },
              {
                id: 43,
                name:'Перукарні',
                foto: parehmaher,
                lists: [
                  {
                    id:437,
                    name: 'Beauty Master "Anna Si"',
                    status: true,
                    vip: false,
                    address: '',
                    foto: [ann_si],              
                    descrip: ['Anna Si - Перукар - Колорист. ' , ' Сучасні техніки фарбування та стрижок. ', ' Біо завивка'],
                    workTime: ['', '', ''],
                    instagram: 'https://www.instagram.com/anna_si_8/',
                    tel:'tel:+380932575758',
                    location:"", 
                  },
                  {
                    id:431,
                    name: 'Салон краси "AVOKADO"',
                    status: true,
                    vip: false,
                    address: ' вул. Доківська 10, корп. 3',
                    foto: [avokado],              
                    descrip: ['Манікюр, педикюр; Стрижки, фарбування; Брови, вії; Макіяж;'],
                    workTime: ['пн-нд 10.00-20.00', '', ''],
                    instagram: 'https://www.instagram.com/avokadobeauty/',
                    tel:'tel:+380739147717',
                    location:"https://maps.app.goo.gl/TkCoWh7HADLgVNFG9", 
                  },
                  {
                  id:432,
                  name: 'Салон краси "LiKa"',
                  status: true,
                  vip: false,
                  address: 'вул. Бакала 44-В',
                  foto: [lika],              
                  descrip: ['Салон краси: перукарня, манікюр'],
                  workTime: ['', '', ''],
                  instagram: 'https://www.instagram.com/lika_kotsyubinskoye/',
                  tel:'tel:+380689385074',
                  location:"https://maps.app.goo.gl/MUZFSxR6fZ5AXU2K6", 
           
                },
                {
                  id:433,
                  name: 'Салон краси "Hair & Nail"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська, 14/1 ТЦ "АТЛАНТ" 3-й пов.',
                  foto: [watsons],              
                  descrip: ['Перукарня, Фарбування, Укладання волосся, Манікюр, Педикюр'],
                  workTime: ['пн-нд 9:00-21:00', '', ''],
                  // instagram: 'https://www.instagram.com/hair.nail.studio/',
                  tel:'tel:+380686681823',
                  network: 'https://hairnail.kiev.ua/',
                  location:"https://maps.app.goo.gl/8JmmaG7xkqT7QLuP7", 
           
                },
                {
                  id:434,
                  name: 'Простір краси "SALVADORE"',
                  status: true,
                  vip: false,
                  address: 'вул. Бакала 44-А',
                  foto: [salvadore],              
                  descrip: ['Манікюр, педикюр; Стрижки, фарбування; Брови, вії; Депіляція; Барбершоп'],
                  workTime: ['пн-нд 10:00-21:00', '', ''],
                  instagram: 'https://www.instagram.com/salvadore_beauty_space/',
                  tel:'tel:+380689385074',
                  location:"https://maps.app.goo.gl/uD7TqD2JEzZMVQP38", 
           
                },
                {
                  id:435,
                  name: 'Твій перукар "Ruslana Haircut"',
                  status: true,
                  vip: false,
                  foto: [parehmaher],              
                  descrip: ['Чоловічі/жіночі/дитячі стрижки; Фарбування волосся; Укладки/накрутки волосся'],
                  workTime: ['', '', ''],
                  instagram: 'https://www.instagram.com/ruslana.haircut/',
               
                  location:"", 
           
                },
                {
                  id:436,
                  name: 'Студія краси "Ta Stydio"',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 26 корп. 5',
                  foto: [tastudio],              
                  descrip: ['Стрижки, фарбування, ботокс, кератин'],
                  workTime: ['', '', ''],
                  instagram: 'https://www.instagram.com/ta_stydio/',
                  location:"https://maps.app.goo.gl/c8zuXty7AjthDUwz8", 
           
                },
                ]
              },
              {
                id: 50,
                name:'Barbershop / Для чоловіків',
                foto: barber,
                lists: [
                  {
                    id:441,
                    name: 'Guga Barber',
                    status: true,
                    vip: false,
                    address: ' вул.Залізнична 2',
                    foto: [guga_barber],              
                    descrip: ['Чоловічі стрижки та оформлення бороди'],
                    workTime: ['', '', ''],
                    instagram: 'https://www.instagram.com/guga_barber_ua/',
                    tel:'tel:+380961002410',
                    location:"https://maps.app.goo.gl/exeUxk2ULk5zMYKt8", 
                  },
                ]
                },
              {
                id: 44,
                name:'Косметологія',
                foto: kosmetologia,
                lists: [
                  {
                    id:442,
                    name: 'Студія "Laser Art"',
                    status: true,
                    vip: false,
                    address: ' вул. Доківська 10, корп. 8',
                    foto: [lazerart],              
                    descrip: ['Лазерна епіляція, косметологія, масаж. Косметологи з медосвітою, сертифіковане обладнання та препарати.'],
                    workTime: ['пн-нд 10:00-19:00', '', ''],
                    instagram: 'https://www.instagram.com/laser_art.ua/', 
                    tel:'tel:+380983138772',
                    location:"https://maps.app.goo.gl/EBzas19vY4CYtdPG7",
                    // network: 'https://bookon.ua/laser-art' 
                  },
                  {
                    id:441,
                    name: 'Салон краси "AVOKADO"',
                    status: true,
                    vip: false,
                    address: ' вул. Доківська 10, корп. 3',
                    foto: [avokado],              
                    descrip: ['Манікюр, педикюр; Стрижки, Брови, вії;  Шугаринг; Лазерна епіляція; Брови, вії; Макіяж;'],
                    workTime: ['пн-нд 10.00-20.00', '', ''],
                    instagram: 'https://www.instagram.com/avokadobeauty/',
                    tel:'tel:+380739147717',
                    location:"https://maps.app.goo.gl/TkCoWh7HADLgVNFG9", 
                  },
                 
                  {
                    id:443,
                    name: 'Косметологія "Cosmo Prof"',
                    status: true,
                    vip: false,
                    address: ' вулиця Доківська, 14/1 ТЦ "АТЛАНТ"',
                    foto: [watsons],              
                    descrip: ['Косметологія; Ін‘єкції Botox; Перманентний макіяж'],
                    workTime: ['', '', ''],
                    instagram: 'https://www.instagram.com/cosm0prof/', 
                    tel:'tel:+380935490446',
                    location:"https://maps.app.goo.gl/8JmmaG7xkqT7QLuP7",
                   
                  },
        
                  // {
                  //   id:444,
                  //   name: 'Косметологія "Oxi Cosmetology"',
                  //   status: true,
                  //   vip: false,
                  //   address: ' вулиця Доківська, 14/1 ТЦ "АТЛАНТ"',
                  //   foto: [watsons],              
                  //   descrip: ['Біоревіталізація, мезотерапія, Аугментація губ, Ботокс, Мікроголковий Rf ліфтинг, Мікротоки'],
                  //   workTime: ['', '', ''],
                  //   instagram: 'https://www.instagram.com/oxicosmetology',
                  //   location:"https://maps.app.goo.gl/8JmmaG7xkqT7QLuP7",
                   
                  // },
                  {
                    id:445,
                    name: '"Cosmetology Kvitko"',
                    status: true,
                    vip: false,
                    address: ' вулиця Доківська, 10 корп. 11"',
                    foto: [kvitko],              
                    descrip: ['Всі види косметичних послуг. Натуральність, безпека, якість. Приємні ціни. Консультація безкоштовна'],
                    workTime: ['', '', ''],
                    tel:'tel:+380952918401',
                    instagram: 'https://www.instagram.com/kosmetology_kvitko/',
                    location:"https://maps.app.goo.gl/geg7e8THD7p8yRNx5",
                   
                  },
                  {
                    id:445,
                    name: '"Elena Cosmetology"',
                    status: true,
                    vip: false,
                    address: 'Коцюбинське',
                    foto: [strelchuk],              
                    descrip: ['Надаю всі види косметологічних послуг. Працюю 12 років. Індивідуальний підхід. Підбираю правильний домашній догляд. Лікую акне '],
                    workTime: ['', '', ''],
                    tel:'tel:+380975355385',
                    instagram: 'https://www.instagram.com/elena___kosmetologg.zt',
          
                   
                  },
                ]
              },
              {
                id: 45,
                name:'Макіяж',
                foto: makiyaj,
                lists: [
                  {
                    id:451,
                    name: 'Салон краси "AVOKADO"',
                    status: true,
                    vip: false,
                    address: ' вул. Доківська 10, корп. 3',
                    foto: [avokado],              
                    descrip: ['Манікюр, педикюр; Стрижки, Брови, вії;  Шугаринг; Лазерна епіляція; Брови, вії; Макіяж;'],
                    workTime: ['пн-нд 10.00-20.00', '', ''],
                    instagram: 'https://www.instagram.com/avokadobeauty/',
                    tel:'tel:+380739147717',
                    location:"https://maps.app.goo.gl/TkCoWh7HADLgVNFG9", 
                  },
                  {
                    id:452,
                    name: 'Простір краси "SALVADORE"',
                    status: true,
                    vip: false,
                    address: 'вул. Бакала 44-А',
                    foto: [salvadore],              
                    descrip: ['Манікюр, педикюр; Стрижки, фарбування; Брови, вії; Депіляція; Барбершоп'],
                    workTime: ['пн-нд 10:00-21:00', '', ''],
                    instagram: 'https://www.instagram.com/salvadore_beauty_space/',
                    tel:'tel:+380689385074',
                    location:"https://maps.app.goo.gl/uD7TqD2JEzZMVQP38", 
             
                  },
                  {
                    id:453,
                    name: 'Patrinastia',
                    status: true,
                    vip: false,
                    address: '',
                    foto: [patrinastia],              
                    descrip: ['Макіяж та зачіска за 2 години; Курс «МАКІЯЖ ДЛЯ СЕБЕ»'],
                    workTime: ['', '', ''],
                    instagram: 'https://www.instagram.com/patrinastia/', 
             
                  },
                  {
                    id:427,
                    name: 'Makeupbrows Mamedova',
                    status: true,
                    vip: false,
                    address: 'вул. Доківська 10 корп 9',
                    foto: [m_ua_dm_maki],              
                    descrip: ['Всі види макіяжу,  Курс сам собі візажист' ],
                    workTime: ['', '', ''],
                    tel:'tel:+380986553315',
                    instagram: 'https://www.instagram.com/makeupbrows.mamedova',
                    location:"https://maps.app.goo.gl/Y2CWAHjaSrtgAuCX9", 
                    
                  },
                ]
              },
              {
                id: 46,
                name:'Масаж',
                foto: masaj,
                lists: [
                  {
                    id:462,
                    name: 'Студія "Laser Art"',
                    status: true,
                    vip: false,
                    address: ' вул. Доківська 10, корп. 8',
                    foto: [lazerart],              
                    descrip: ['Лазерна епіляція, косметологія, масаж. Косметологи з медосвітою, сертифіковане обладнання та препарати.'],
                    workTime: ['пн-нд 10:00-19:00', '', ''],
                    instagram: 'https://www.instagram.com/laser_art.ua/', 
                    tel:'tel:+380983138772',
                    location:"https://maps.app.goo.gl/EBzas19vY4CYtdPG7",
                    // network: 'https://bookon.ua/laser-art' 
                  },
                  {
                    id:461,
                    name: 'Масаж. салон "WELLNESS"',
                    status: true,
                    vip: false,
                    address: 'вул. Залізнична 1Б',
                    foto: [masaj],              
                    descrip: [' 27 видів масажу, Медична освіта'],
                    workTime: ['пн-нд 08:00-20:00', 'нд- вихідний', ''],
                    instagram: 'https://www.instagram.com/massage.ktsb/',
                    tel:'tel:+380982730413',
                    location:"https://maps.app.goo.gl/cwowUhbNJ78bVppPA", 
             
                  },
              
                  {
                    id:463,
                    name: 'Toma Massage Therapy',
                    status: true,
                    vip: false,
                    address: 'вул. Пономарьова 13А',
                    foto: [masaj],              
                    descrip: ['МАСАЖ:вакуумно-роликовий, кавітація, rf-ліфтинг, обличчя(гуаша), спини, кінезіотейпування'],
                    workTime: ['', '', ''],
                    instagram: 'https://www.instagram.com/toma.massage_therapy/', 
                    tel:'tel:+380684094517',
                    location:"https://maps.app.goo.gl/ZisUKKrxPXW8M5x8A",
                   
                  },
                  {
                    id:464,
                    name: 'Вікторія майстр Масажу',
                    status: true,
                    vip: false,
                    address: 'Коцюбинське',
                    foto: [massageviktoria],              
                    descrip: ['Масаж класичний, Антицелюлітний, Лімфодренаж, Шийно-комірцева зона'],
                    workTime: ['', '', ''],
                    instagram: 'https://www.instagram.com/Bilousv89/', 
                    tel:'tel:+380674656804',
     
                   
                  },
                  {
                    id:465,
                    name: 'Салон краси "AVOKADO"',
                    status: true,
                    vip: false,
                    address: ' вул. Доківська 10, корп. 3',
                    foto: [avokado],              
                    descrip: ['Манікюр, педикюр; Стрижки, Брови, вії;  Шугаринг; Лазерна епіляція; Брови, вії; Макіяж; Масаж'],
                    workTime: ['пн-нд 10.00-20.00', '', ''],
                    instagram: 'https://www.instagram.com/avokadobeauty/',
                    tel:'tel:+380739147717',
                    location:"https://maps.app.goo.gl/TkCoWh7HADLgVNFG9", 
                  },
                  {
                    id:466,
                    name: 'Антицелюлітний масаж',
                    status: true,
                    vip: false,
                    address:  'Коцюбинське',
                    foto: [milevskag],              
                    descrip: ['Антицелюлітний масаж, Кавітація; Ліполазер, Rf-ліфтнг облича та тіла;  Мікротоки; Лазерна епіляція;'],
                    workTime: ['', '', ''],
                    instagram: 'https://www.instagram.com/milevskag/',
                    tel:'tel:+3806675001',
                  
                  },
                ]
              },
              {
                id: 47,
                name:'Перманентний макіяж',
                foto: permanent,
                lists: [
                  // {
                  //   id:471,
                  //   name: 'BrowArt',
                  //   status: true,
                  //   vip: false,
                  //   address: 'вул. Доківська, 14/1 ТЦ "АТЛАНТ"',
                  //   foto: [watsons],              
                  //   descrip: ['Перманент брів та губ, Ламінування та фарбування брів, Ламінуваня та нарощування вій, Навчання'],
                  //   workTime: ['', '', ''],
                  //   instagram: 'https://www.instagram.com/brow_lash_tut/',
                  //   location:"https://maps.app.goo.gl/qekDeZpzCTR9npms7", 
             
                  // },
                  {
                    id:472,
                    name: 'Косметологія "Cosmo Prof"',
                    status: true,
                    vip: false,
                    address: ' вулиця Доківська, 14/1 ТЦ "АТЛАНТ"',
                    foto: [watsons],              
                    descrip: ['Косметологія; Ін‘єкції Botox; Перманентний макіяж'],
                    workTime: ['', '', ''],
                    instagram: 'https://www.instagram.com/cosm0prof/', 
                    tel:'tel:+380935490446',
                    location:"https://maps.app.goo.gl/8JmmaG7xkqT7QLuP7",
                   
                  },
                  {
                    id:473,
                    name: 'Салон краси "Hair & Nail"',
                    status: true,
                    vip: false,
                    address: 'вулиця Доківська, 14/1 ТЦ "АТЛАНТ" 3-й пов.',
                    foto: [watsons],              
                    descrip: ['Фарбування, Укладання волосся, Манікюр, Педикюр, Перманент'],
                    workTime: ['пн-нд 9:00-21:00', '', ''],
                    // instagram: 'https://www.instagram.com/hair.nail.studio/',
                    tel:'tel:+380686681823',
                    network: 'https://hairnail.kiev.ua/',
                    location:"https://maps.app.goo.gl/8JmmaG7xkqT7QLuP7", 
             
                  },
                  {
                    id:474,
                    name: 'Kristina Saprykina PM',
                    status: true,
                    vip: false,
                    address: '',
                    foto: [permanent],              
                    descrip: ['Перманентний макіяж; Міні тату'],
                    workTime: ['', '', ''],
                    instagram: 'https://www.instagram.com/kristina_saprykina_pm/',
                    tel:'tel:+380985720979',
                    
             
                  },
                ]
              },
              {
                id: 48,
                name:'Видалення волосся',
                foto: epilacia,
                lists: [
                  {
                    id:483,
                    name: 'Студія "Laser Art"',
                    status: true,
                    vip: false,
                    address: ' вул. Доківська 10, корп. 8',
                    foto: [lazerart],              
                    descrip: ['Лазерна епіляція, косметологія, масаж. Косметологи з медосвітою, сертифіковане обладнання та препарати.'],
                    workTime: ['пн-нд 10:00-19:00', '', ''],
                    instagram: 'https://www.instagram.com/laser_art.ua/', 
                    tel:'tel:+380983138772',
                    location:"https://maps.app.goo.gl/EBzas19vY4CYtdPG7",
                    // network: 'https://bookon.ua/laser-art' 
                  },
                
                  {
                    id:481,
                    name: 'Салон краси "AVOKADO"',
                    status: true,
                    vip: false,
                    address: ' вул. Доківська 10, корп. 3',
                    foto: [avokado],              
                    descrip: ['Манікюр, педикюр; Стрижки, Брови, вії;  Шугаринг; Лазерна епіляція; Брови, вії; Макіяж;'],
                    workTime: ['пн-нд 10.00-20.00', '', ''],
                    instagram: 'https://www.instagram.com/avokadobeauty/',
                    tel:'tel:+380739147717',
                    location:"https://maps.app.goo.gl/TkCoWh7HADLgVNFG9", 
                  },
                  {
                    id:482,
                    name: 'Простір краси "SALVADORE"',
                    status: true,
                    vip: false,
                    address: 'вул. Бакала 44-А',
                    foto: [salvadore],              
                    descrip: ['Манікюр, педикюр; Стрижки, фарбування; Брови, вії; Депіляція; Барбершо'],
                    workTime: ['пн-нд 10:00-21:00', '', ''],
                    instagram: 'https://www.instagram.com/salvadore_beauty_space/',
                    tel:'tel:+380689385074',
                    location:"https://maps.app.goo.gl/uD7TqD2JEzZMVQP38", 
             
                  },
                  
                  {
                    id:484,
                    name: 'Електроепіляція Шугаринг',
                    status: true,
                    vip: false,
                    address: 'вул. Пономарьова 13А',
                    foto: [epilacia],              
                    descrip: ['Електроепіляція; Шугаринг; Ламі брів та вій'],
                    workTime: ['', '', ''],
                    instagram: 'https://www.instagram.com/nat_epil', 
                    tel:'tel:+380503560222',
                    location:"https://maps.app.goo.gl/EBzas19vY4CYtdPG7",
                
                  },

                  {
                    id:485,
                    name: 'I Wish Laser',
                    status: true,
                    vip: false,
                    address: 'вул. Доківська 14/1',
                    foto: [i_wish_laser],              
                    descrip: ['Лазерна епіляція та воскова депіляція. ', ' Laser -' , ' Lumenis'],
                    workTime: ['', '', ''],
                    instagram: 'https://www.instagram.com/i.wish.laser/', 
                    tel:'tel:+380967514656',
                    location:"https://maps.app.goo.gl/8JmmaG7xkqT7QLuP7",
                
                  },
                  {
                    id:486,
                    name: 'Вікторія майстр Шугарінгу',
                    status: true,
                    vip: false,
                    address: 'Коцюбинське',
                    foto: [shugarinviktoria],              
                    descrip: ['Цукрова епіляція, Шугарінг, Шугарінг Київська область'],
                    workTime: ['', '', ''],
                    instagram: 'https://www.instagram.com/Bilousv89/', 
                    tel:'tel:+380674656804',
     
                   
                  },
                  {
                    id:487,
                    name: 'Лазерна епіляція',
                    status: true,
                    vip: false,
                    address:  'Коцюбинське',
                    foto: [milevskag],              
                    descrip: ['Антицелюлітний масаж, Кавітація; Ліполазер, Rf-ліфтнг облича та тіла;  Мікротоки; Лазерна епіляція;'],
                    workTime: ['', '', ''],
                    instagram: 'https://www.instagram.com/milevskag/',
                    tel:'tel:+380633930922',
                  
                  },
                ]

              },
              {
                id: 49,
                name:'Татуювання',
                foto: tatoo,
                lists: [
                  {
                    id:481,
                    name: 'Tapok Tattoo',
                    status: true,
                    vip: false,
                    address: ' вул. Доківська 9',
                    foto: [tapokTattoo],              
                    descrip: ['Я тату майстриня з досвідом 5 років. Працюю в стилях : графіка, гравюра, мінімалізм, лайнворк. Малюю власні та індивідуальні ескізи, також можу втілити ваш ескіз (якщо він в моєму стилі).'],
                    workTime: ['', '', ''],
                    instagram: 'https://www.instagram.com/tapok.tattoo/',
                    tel:'tel:+380960668168',
                    location:"https://maps.app.goo.gl/j912ri48d5QRYTh26", 
                  },
                  {
                    id:481,
                    name: 'Ніч Тату',
                    status: true,
                    vip: false,
                    address: ' вул. Доківська 14/1, 2 пов.',
                    foto: [nichTattoo],              
                    descrip: ['Тату стилі, в яких працює майстр: мінімалізм, тонкі лінії, графіка, геометрія, гравюра, віпшейдинг, дотворк та інші (не реалізм). '],
                    workTime: ['', '', ''],
                    instagram: 'https://www.instagram.com/nich_tattoo_kotsubinske/',
                    telegram:'tg://resolve?domain=alionana13',
                    location:"https://maps.app.goo.gl/8JmmaG7xkqT7QLuP7", 
                  },
                  {
                    id:481,
                    name: 'Smrchtatto',
                    status: true,
                    vip: false,
                    address: ' вул. Доківська 14/1',
                    foto: [smrchtatto],              
                    descrip: ["Б'ю татуювання в тату студії "],
                    workTime: ['', '', ''],
                    instagram: 'https://www.instagram.com/smrchtattoo',
                    telegram:'tg://resolve?domain=alionana13',
                    tel:'tel:+380500708179',
                    location:"https://maps.app.goo.gl/8JmmaG7xkqT7QLuP7",
                  },
                  
                ]
               
              },
              {
                id: 410,
                name:'БАДи та Косметика',
                foto: bad,
                lists: [
                  {
                    id:4101,
                    name: 'Аліна Волківська LR',
                    status: true,
                    vip: false,
                    address: '',
                    foto: [volkivska_1],              
                    descrip: ['Коктейлі для схуднення. Гелі Алоє для очищення кишківника та імунітету. Вітаміни для дітей та дорослих. Люксова косметика. Продукція виробництва Німеччина'],
                    workTime: ['', '', ''],
                    instagram: 'https://www.instagram.com/volkovskaiaalina',
                    tel:'tel:+380939511447',
                  telegram:'tg://resolve?domain=VolkovskaiaLR', 
                  },
                
                ]
               
              },
              {
                id: 411,
                name:'Бані, Сауни, СПА',
                foto: bani,
                lists: 
                [
                  {
                    id:4111,
                    name: 'Сауна "ParHub"',
                    status: true,
                    vip: false,
                    address: 'вул. Пономарьова 7',
                    foto: [parhub],              
                    descrip: ['ParHub – сучасна сауна в Коцюбинському',
                    'Ідеальне місце для відпочинку з комфортною парною та затишною атмосферою', 'Бронювання по телефону'],
                    workTime: ['', '', ''],
                    instagram: 'https://www.instagram.com/par.hub.relax',
                    tel:'tel:+380962881241',
                    location:"https://maps.app.goo.gl/hq3DS8wmeGH87G3S9", 
             
                  },
              
                 
            
                ]         
              },
            ]
            
            
        },
        { 
          id: 5,
          name: 'Для дітей',
          foto: childrens,
          types: 
          [
            {
              id: 51,
              name:'Дитячі садочки',
              foto: ditachisadochok,
              lists: 
              [

                {
                  id: 511,
                  name: 'ЗДО №1 "Ластівка"',
                  status: true,
                  vip: false,
                  address: ' вул. Доківська 18',
                  foto: [lastivka],
                  descrip:['Заклад дошкільної освіти (ясла-садок) комбінованого типу', 'Тип садочку -  Державний'],
                  workTime: ['пн-пт: 7:00-18:30', '', ''], 
                  tel:'tel:+380672821616',
                  location: 'https://maps.app.goo.gl/tSd8UToFcTkkgUDw9',
            
                },
                {
                  id: 512,
                  name: 'ЗДО №2 "Світлячок"',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова буд. 20',
                  foto: [svitliachok],
                  descrip:['Заклад дошкільної освіти (ясла-садок) комбінованого типу', 'Тип садочку -  Державний'],
                  workTime: ['пн-пт: 7:00-19:00', '', ''], 
                  tel:'tel:+380636955265',
                  location: 'https://maps.app.goo.gl/yMnNrfU5piRsAeWx6',
                  network: 'https://svitlyachok-irpin.dytsadok.org.ua/',
                },
                
                 {
                  id: 513,
                  name: 'Приватний дитячий садок-"Білченя"',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 26 корп. 4,',
                  foto: [bilchenia],
                  descrip:['Дитячий садок ', 'Підготовка до школи онлайн', 'Тип садочку -  Приватний'],
                  workTime: ['пн-сб: 8:00-19:00', 'нд: вихідний', ''], 
                  tel:'tel:+380961387943',
                  location: 'https://maps.app.goo.gl/yZwzh6j7oWD5ikSg8',
                  network: 'https://bilchenya.net/sadochok/',
                  instagram: 'https://www.instagram.com/bilchenya.mereza/', 

                },

                {
                  id: 514,
                  name: 'Прив. дит. садок-"ATLANT ONE SCHOOL"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14а',
                  foto: [atlantone],
                  descrip:['Дитячий садок ', 'Підготовка до школи', 'Гуртки та секції', 'Тип садочку -  Приватний'],
                  workTime: ['пн-пт: 8:00-18:00', 'сб-нд: вихідний', ''], 
                  tel:'tel:+380933650951',
                  location: 'https://maps.app.goo.gl/Eiq37Cj99RF2XVdEA',
                  network: 'https://www.atlantone.school/',
                  instagram: 'https://www.instagram.com/atlantoneschool.kyiv/', 

                },
                {
                  id: 515,
                  name: 'Центр розвитку "Щастя тут"',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 26 корп. 2',
                  foto: [schastiatut],
                  descrip:['Підготовка до школи', 'Англійська мова', 'Малювання,  Логопед', 'Хореографія,  Музика'],
                  workTime: ['пн-пт: 11:00-20:00', '', ''], 
                  // tel:'tel:+380977247478',
                  location: 'https://maps.app.goo.gl/xfGymPnSyNtndXUJ8',
                  instagram: 'https://www.instagram.com/shchastya_tut/',
                },
                          
              ]         
            },
            {
              id: 52,
              name:'Школи, Ліцеї',
              foto: shool,
              lists: 
              [
                {
                  id: 521,
                  name: 'Коцюбинський Ліцей №1 (ЛПшГ №1)',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 6/4',
                  foto: [licei1],
                  descrip:['Ліцей з початковою школою та гімназією',  'Бюджетування -  Державне'],
                  workTime: ['пн-пт: 8:00-18:00', 'сб-нд: вихідний', ''], 
                  tel:'tel:+380988130505',
                  location: 'https://maps.app.goo.gl/o1kd4B92ehiwQm8z5',
                  instagram: 'https://www.instagram.com/kotsyubynskyi.lyceum1',

                },
                {
                  id: 522,
                  name: 'Коцюбинський Ліцей №2 (ЛПшГ №2)',
                  status: true,
                  vip: false,
                  address: 'вул. Бакала 1',
                  foto: [licei2],
                  descrip:['Ліцей з початковою школою та гімназією',  'Бюджетування -  Державне'],
                  workTime: ['пн-пт: 8:00-19:00', 'сб-нд: вихідний', ''], 
                  tel:'tel:+380991107531',
                  location: 'https://maps.app.goo.gl/REVjJFncvRYSGLht5',
                  network: 'http://kotsiubynske-lyceum.edukit.kiev.ua/',

                },
                {
                  id: 523,
                  name: 'Прив. школа "ATLANT ONE SCHOOL"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14а',
                  foto: [atlantone],
                  descrip:[ 'Школа повного дня', 'Гуртки та секції', 'Тип школи -  Приватний'],
                  workTime: ['пн-пт: 8:00-18:00', 'сб-нд: вихідний', ''], 
                  tel:'tel:+380933650951',
                  location: 'https://maps.app.goo.gl/Eiq37Cj99RF2XVdEA',
                  network: 'https://www.atlantone.school/%d1%88%d0%ba%d0%be%d0%bb%d0%b0/',
                  instagram: 'https://www.instagram.com/atlantoneschool.kyiv?igsh=MzRlODBiNWFlZA==', 

                },
                
                      
              ]         
            },
            {
              id: 53,
              name:'Центри дитячого розвитку',
              foto: centryrozvitku,
              lists: 
              [
                {
                  id: 531,
                  name: 'Прив. школа "ATLANT ONE SCHOOL"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14а',
                  foto: [atlantone],
                  descrip:['Ранній розвиток', 'Підготовка до школи', 'Гуртки та секції',],
                  workTime: ['пн-пт: 8:00-18:00', 'сб-нд: вихідний', ''], 
                  tel:'tel:+380933650951',
                  location: 'https://maps.app.goo.gl/Eiq37Cj99RF2XVdEA',
                  network: 'https://www.atlantone.school/%d0%b3%d1%83%d1%80%d1%82%d0%ba%d0%b8-%d1%82%d0%b0-%d1%81%d0%b5%d0%ba%d1%86%d1%96%d1%97/',
                  instagram: 'https://www.instagram.com/atlantoneschool.kyiv?igsh=MzRlODBiNWFlZA==', 

                },
                {
                  id: 531,
                  name: 'Центр  освіти "Jerome Education Space"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 10 корп. 5',
                  foto: [jerome],
                  descrip:['Ранній розвиток', 'Підготовка до школи', 'Англійська мова', 'Розвиток мовлення'],
                  workTime: ['пн-пт: 10:00-21:00', 'сб-нд: вихідний', ''], 
                  tel:'tel:+380683713110',
                  location: 'https://maps.app.goo.gl/W87mCxGGuVUMvVK98',
                  network: 'https://jerome.in.ua/',
                  instagram: 'https://www.instagram.com/jerome_space/', 

                },
                {
                  id: 533,
                  name: 'Школа програмування для дітей "Digit Logic"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14/1 3-й пов.',
                  foto: [watsons],
                  descrip:['Візуальне програмування', 'Minecraft Education Edition 1.0', 'Програмування Arduino', 'Вікова категорія  - від 7 до 16 р.'],
                  workTime: ['пн-пт: 11:00-20:00', 'сб: 12:00-14:00', ''], 
                  tel:'tel:+380977247478',
                  location: 'https://maps.app.goo.gl/UJGcmdMmw6xThhFM7',
                  network: 'https://www.digit-logic.com.ua/',
                  facebook: 'https://www.facebook.com/DigitLogic.com.ua',
                },
                {
                  id: 534,
                  name: 'Центр розвитку "Щастя тут"',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 26 корп. 2',
                  foto: [schastiatut],
                  descrip:['Підготовка до школи', 'Англійська мова', 'Малювання,  Логопед', 'Хореографія,  Музика'],
                  workTime: ['пн-пт: 11:00-20:00', '', ''], 
                  // tel:'tel:+380977247478',
                  location: 'https://maps.app.goo.gl/xfGymPnSyNtndXUJ8',
                  instagram: 'https://www.instagram.com/shchastya_tut/',
                },
                {
                  id: 535,
                  name: 'Мережа музичних студій "Артефакт"',
                  status: true,
                  vip: false,
                  address: 'вул. Бакала буд. 44/1',
                  foto: [artefact],
                  descrip:['Індивідуальні уроки : Вокал, Гітара, Фортепіано, Укулеле, Барабани'],
                  workTime: ['пн-нд: 10:00-21:00', '', ''], 
                  tel:'tel:+380968814096',
                  facebook: 'https://www.facebook.com/artefact.mus/',  
                  location: 'https://maps.app.goo.gl/hVqLpsE1Tku4g9N36',
                  network: 'https://artefactschool.com.ua/',
                },
                {
                  id: 536,
                  name: 'Простір розвитку "Kitty Grammar"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 7А',
                  foto: [kitty_grammar],
                  descrip:['Англійська мова від 2 до 102 років 😊 ', 'Рвнній розвиток англійською', 'Підготовка до іспитів ', 'Speaking clubs', 'Занурення в атмосферу англійської мови '],
                  workTime: ['', '', ''], 
                  tel:'tel:+380953985461',
                  instagram: 'https://www.instagram.com/kitty_grammar',  
                  telegram:'tg://resolve?domain=kitty_grammar',
                  location: 'https://maps.app.goo.gl/HZ8Jzqph2KNdkNX77',
  
                },
                 {
                  id: 537,
                  name: 'LiLi English School',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14а',
                  foto: [liliiameleshko],
                  descrip:['Offline та Online заняття для дітей та дорослих',],
                  workTime: ['', '', ''], 
                  tel:'tel:+380993026026',
                  instagram: 'https://www.instagram.com/lili_english_school/',  
                  location: 'https://maps.app.goo.gl/Eiq37Cj99RF2XVdEA',

                },
                {
                  id: 538,
                  name: 'Pепетитор англійськоі мови',
                  status: true,
                  vip: false,
                  address: 'Коцюбинське',
                  foto: [englishtutor],
                  descrip:['Пропоную послуги репетитора англійськоі мови онлайн для дітей та дорослих.', '6 років досвіду в міжнародній компанії.', 'Пробне заняття безкоштовно'],
                  workTime: ['', '', ''], 
                  tel:'tel:+380966759214',
                  instagram: 'https://www.instagram.com/Irene.075/',  
                },
                {
                  id: 539,
                  name: 'Логопедичний Центр "Моватор"',
                  status: true,
                  vip: false,
                  address: 'вул. Клавдіївська, 40А',
                  foto: [movator],
                  descrip:['Допомагаємо дітям та дорослим подолати мовленнєві труднощі, покращити вимову, розширити словниковий запас та розвинути комунікативні навички'],
                  workTime: ['', '', ''], 
                  tel:'tel:+380679551515',
                  instagram: 'https://www.instagram.com/logoped_movator',  
                  location: 'https://maps.app.goo.gl/KbNY6B6B9DoXr6ao7',
                },


                
                
                      
              ]         
            },
            {
              id: 54,
              name:'Спортивні секції',
              foto: sportdite,
              lists: 
              [
                {
                  id: 541,
                  name: 'Приватна школа "ATLANT ONE SCHOOL"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14а',
                  foto: [atlantone],
                  descrip:['Школа танців "Full Out Dance Center"', 'СК "PITBULL: кікбоксинг, акробатика, боротьба, ММА, хортинг, бокс, греплінг, кросфіт, фітнес"',],
                  workTime: ['пн-пт: 8:00-18:00', 'сб-нд: вихідний', ''], 
                  tel:'tel:+380933650951',
                  location: 'https://maps.app.goo.gl/Eiq37Cj99RF2XVdEA',
                  network: 'https://www.atlantone.school/%d0%b3%d1%83%d1%80%d1%82%d0%ba%d0%b8-%d1%82%d0%b0-%d1%81%d0%b5%d0%ba%d1%86%d1%96%d1%97/',
                  instagram: 'https://www.instagram.com/atlantoneschool.kyiv/', 

                },
                {
                  id:542,
                  name: 'Fusin School',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова  6/4 Ліцей №1 (Школа №18) ',
                  foto: [fusin],
                  descrip: ['Дисципліни: Бокс, Кікбоксинг, Самооборона, ММА, Джиу Джитсу, УШУ-саньда, Лайт-Кросфіт, Стрейчинг, Табата'],
                  workTime:['', '', ''], 
                  tel:'tel:+380969732827',
                  instagram: 'https://www.instagram.com/fusinzal_kotsiubynske/',
                  location:'https://www.google.com/maps/place/%D0%9E%D0%B1%D1%89%D0%B5%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F+%D1%88%D0%BA%D0%BE%D0%BB%D0%B0+I-III+%D1%81%D1%82%D0%B5%D0%BF%D0%B5%D0%BD%D0%B5%D0%B9+%E2%84%9618,+%D1%83%D0%BB.+%D0%9F%D0%BE%D0%BD%D0%BE%D0%BC%D0%B0%D1%80%D1%91%D0%B2%D0%B0,+6%2F4,+%D0%9A%D0%BE%D1%86%D1%8E%D0%B1%D0%B8%D0%BD%D1%81%D0%BA%D0%BE%D0%B5,+%D0%9A%D0%B8%D0%B5%D0%B2%D1%81%D0%BA%D0%B0%D1%8F+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+08298/@50.4922863,30.3293197,18.25z/data=!4m6!3m5!1s0x472b3326745072ad:0xd3f54a829bea5415!8m2!3d50.492157!4d30.3293845!16s%2Fg%2F12hly4j0p?entry=ttu', 
                  network:'https://fusinjia.wixsite.com/fusin?lang=uk',
                },
                {
                  id: 543,
                  name: 'Danilov School ',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 17',
                  foto: [danilov],
                  descrip: ['Школа танцю та фітнесу, Групові заняття для дорослих та дітей, Індивідуальні тренування, Онлайн-тренування'],
                  workTime: ['', '',], 
                  tel:'tel:+380965344440',
                  instagram: 'https://www.instagram.com/danilov_school/',
                  location:"https://maps.app.goo.gl/zpkwx5nr7mWjzSM38",
              
                },
               
                {
                  id: 544,
                  name: 'Студія танцю та фітнесу "Style"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14/1 ',
                  foto: [danse_studio_style],
                  descrip: ['Спортивно-бальні танці, Дискотека, Хіп-Хоп, Джаз-фанк,Шаффл танець, Зумба, Фітнес мікс, Розтяжка, Сальса'],
                  workTime: ['', '',], 
                  tel:'tel:+380638838733',
                  instagram: 'https://www.instagram.com/dancestudio_style/',
                  location:"https://maps.app.goo.gl/vMdnjYgHR4Z5ZZkC8",
              
                },  
                {
                  id:545,
                  name: 'СК "Stakhov Team"',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова  6/4',
                  foto: [stakhovTeam],
                  descrip: ['Тренування для дітей та підлітків  за напрямками: Кікбоксинг, Бокс , Муай-тай '],
                  workTime:['', '', ''], 
                  tel:'tel:+380939742854',
                  instagram: 'https://www.instagram.com/stakhov_team/',
                  location:'https://maps.app.goo.gl/nDSMqU1dxvvCjySj9', 
            
                },                  
              ]         
            },
            {
              id: 55,
              name:'Дитячі свята / Аніматори',
              foto: sviatodite,
              lists: 
              [
                {
                  id: 551,
                  name: 'Kotsiuba Kids Party',
                  status: true,
                  vip: false,
                  address: 'Коцюбинське',
                  foto: [kotsuibakids],
                  descrip:['Ми зробимо Ваше свято незабутнім',  'Аніматори, піньяти, квест та шоу-програми', 'Свята під ключ'],
                  workTime: ['', '', ''], 
                  tel:'tel:+380962808646',
                  instagram: 'https://www.instagram.com/kotsiuba_kids_party/',
                  
                  

                },
               
                
                      
              ]         
            },
            {
              id: 56,
              name:'Дитячий одяг/Взуття/Іграшки',
              foto: odiaddite,
              lists: 
              [
                {
                  id: 561,
                  name: 'Smilik Kids',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14/1, 2 поверх',
                  foto: [smilik],
                  descrip:['Речі від 0 до 12 років',  'Білизна турецьких виробників', 'Шкарпетки , колготи, джинси, шапки, рукавички і багато іншого', 'Великий асортимент іграшок', 'Товари для розвитку і творчості'],
                  workTime: ['', '', ''], 
                  tel:'tel:+380969633204',
                  instagram: 'https://www.instagram.com/smilik_kids/',
                  location:'https://maps.app.goo.gl/imMhGsjTEYXgWJUN9', 
                  viber: 'https://invite.viber.com/?g2=AQB6S1GVVYjRqkjAoydLNpRUfbTMdh9qS8mq0mBPK8%2F5bU3fUXoMCJIad%2FTVOPkc.&lang=uk',

                },
               
                
                      
              ]         
            },
          ]
        },
        { 
          id: 6,
          name: 'Доставки',
          foto: dostavka,
          types: 
          [
            {
              id: 61,
              name:'Вода',
              foto: voda,
              lists: 
              [
                {
                  id:611,
                
                  name: 'Коцюбинська Вода',
                  status: true,
                  vip: false,
                  address: 'смт. Коцюбинське, вул. Залізнична, 1Б',
                  foto: [vodakotsuba],
                  descrip:['Ціна за бутиль - від 70 грн'],
                  zamovlena: 'https://kotsyubinska-voda.com.ua/',
                  workTime: ['пн-пт: 9:00-18:00', '', 'без перерви'], 
                  tel:'tel:+380679162240',
                  location: 'https://maps.app.goo.gl/37ANPDutJa3YhEXq7',
                  facebook: 'https://www.facebook.com/kotsyubinska.voda/?locale=uk_UA',
                }, 
                {
                  id:612,                
                  name: 'Небесна Криниця',
                  status: true,
                  vip: false,
                  address: 'вул. Миколи Василенка, 7',
                  foto: [krinica],
                  descrip:['Ціна за бутиль - від 179 грн'],
                  zamovlena: 'https://krinitsa.com.ua/ua',
                  workTime: ['пн-пт: 9:00-18:00', '', 'сб-нд: вихідний'], 
                  tel:'tel:+380444558455',
                  location: '',
                  facebook: 'https://www.facebook.com/n.krinitsa/',
                  network: 'https://krinitsa.com.ua/ua',
                }, 
                      
              ]         
            },
            {
              id: 62,
              name:'Готова їжа',
              foto: ija,
              lists: 
              [
                 {
                  id:6210, 
                  name: 'Піцерія "SANCHO PIZZA"',
                  status: true,
                  vip: false,
                  address: ' вул. Доківська 14',
                  foto: [sancho],
                  descrip:['Найсмачніша піца в Коцюбинському', 'Від класики до екзотики 🍕🍕🍕', 'Доставляємо по всьому Коцюбинському 🛴'],
                  zamovlena: 'https://www.sanchopizza.com.ua/',
                  workTime: ['пн-нд 11:00 - 21:00', '', ''], 
                  tel:'tel:+380688419311',
                  location: 'https://maps.app.goo.gl/ipUC5Tqfk6hLz27f7',
                  telegram:'tg://resolve?domain=sanchopizza',
                  network:'https://www.sanchopizza.com.ua/',
                  instagram: 'https://www.instagram.com/sancho_pizza_ua?igsh=dTB6dWM0cGVqenhp'

                },   
                {
                  id:629, 
                  name: 'Піцерія "Damiano"',
                  status: true,
                  vip: false,
                  address: ' вул. Пономарьова 24 (біля ПромСИЗа)',
                  foto: [domiano],
                  descrip:['Неаполітанська піца', 'Самовивіз', 'Доставка'],
                  zamovlena: 'https://damiano-pizzabar.ps.me/',
                  workTime: ['10:00 - 20:00', '', ''], 
                  tel:'tel:+380636107424',
                  location: 'https://maps.app.goo.gl/ivEJpaa3HYqSZTY1A',              
                  instagram: 'https://www.instagram.com/damiano.pizzabar'
                  
                },   
                 {
                  id:6211, 
                  name: 'Kotsuba Express',
                  status: true,
                  vip: false,
                  address: 'смт. Коцюбинське та ТРЦ Lavina',
                  foto: [express],
                  descrip:['Доставка в межах Коцюбинського та з ТРЦ Lavina',],
                  zamovlena: 'tel:+380975770774',
                  workTime: ['пн-нд: 09:00-21:00', '', '', ''], 
                  tel:'tel:+380975770774',
                  instagram: 'https://www.instagram.com/kotsuba_express'
                }, 
                {
                id:6210, 
                name: 'Pesto Cafe ',
                status: true,
                vip: false,
                address: 'смт. Коцюбинське',
                foto: [pesto],
                descrip:['Доставка від - 500 грн', 'Доставка - 49 грн. ', 'Безкоштовна доставка від - 998грн'],
                zamovlena: 'https://pesto-family.com/payment/',
                workTime: ['пн-нд: 10:00-21:30', '', 'без перерви'], 
                tel:'tel:+380972253333 ',
                network: 'https://pesto-family.com/',
               
              }, 
                // {
                //   id:621, 
                //   name: 'Територія  смаку',
                //   // moremenu:'Перейти до меню',
                //   get:'https://67083b4e8e86a8d9e42e6ee7.mockapi.io/food/teritoriasmaku',
                //   post:'https://67083b4e8e86a8d9e42e6ee7.mockapi.io/food/order',
                //   pas:'111',
                //   log: '111',
                //   status: true,
                //   vip: false,
                //   address: ' вул. Доківська 10 корпус 9',
                //   address1: ' вул. Пономарьова 26 корпус 1',
                //   foto: [teritiriasmaku],
                //   descrip:['Магазин смачної їжі', 'Домашня кухня'],
                //   zamovlena: 'tel:+380682303208',
                //   workTime: ['пн-нд: 10:00-21:00', '', 'без перерви'], 
                //   tel:'tel:+380682303208',
                //   location: 'https://maps.app.goo.gl/aGLtxj3KvmeFue69A',
                //   instagram: 'https://www.instagram.com/teryrotiya_smaku_kcb/'
                // }, 
                {
                  id:624, 
                  name: 'Піца ВВ',
                  pas:'1111',
                  log: '1111',
                  status: true,
                  // moremenu:'Перейти до меню',
                  vip: false,
                  address: 'смт. Коцюбинське, вул. Пономарева 18б',
                  foto: [pizavv],
                  descrip:['Піца та суші в Коцюбинському', 'Безкоштовна доставка'],
                  zamovlena: 'https://pizzavv.com.ua/',
                  workTime: ['пн-нд: 11:00-22:00', '', 'без перерви'], 
                  tel:'tel:+380687224242',
                  instagram: 'https://www.instagram.com/pizzavv.com.ua/',
                  location: 'https://maps.app.goo.gl/ExenhcGR6MbSr4n19',
                  network: 'https://pizzavv.com.ua/',
                 
                }, 
                {
                  id:625,
                  name: 'Ресторан "Гранат"',
                  status: true,
                  vip: false,
                   zamovlena: 'tel:+380669710484',
                  address: 'вул. Пономарьова 17',
                  foto: [granat],              
                  descrip: [ 'Вишукана кухня сходу', 'Доставка від - 1000 грн'],
                  workTime: ['пн-нд: 11:00-23:00', '', ''],
                  tel:'tel:+380669710484',
                  instagram:"https://www.instagram.com/granat_restaurant/", 
                  location:'https://maps.app.goo.gl/7qHKXvpH4hQsuX9F6',

                  
                },
                {
                  id:626, 
                  name: 'Суші "Insta Sushi Ktsb"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14',
                  foto: [instasushi],
                  zamovlena: 'https://insta-sushi.choiceqr.com/delivery/online-menu',
                  descrip:['Можливо забрати з собою', 'Є доставка '],
                  workTime: ['пн-нд: 12:00-20:45', '', ''], 
                  tel:'tel:+380637886184',
                  instagram: 'https://www.instagram.com/instasushi_ktsb/',
                  location: 'https://maps.app.goo.gl/GqKBcfmHyNTHeSay8',
                  network: 'https://insta-sushi.choiceqr.com/',              
                }, 
                {
                  id:627, 
                  name: 'Суші "Sushi Story"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 10 корп. 8',
                  foto: [sushistory1],
                  zamovlena: 'https://ua.sushistory.com/kotsyubinske',
                  descrip:['При замовленні від 700 грн - доставка безкоштовна. ', 'Мін замовлення  500 грн - доставка 50 грн.'],
                  workTime: ['пн-нд: 10:00-22:00', '', ''], 
                  tel:'tel:+380931702444',
                  instagram: 'https://www.instagram.com/sushistoryukraine/',
                  location: 'https://maps.app.goo.gl/UiXUd4qDiVXj6vka9',
                  network: 'https://ua.sushistory.com/kotsyubinske',              
                },  
                {
                  id:628, 
                  name: 'Tarantino family ',
                  status: true,
                  vip: false,
                  address: 'смт. Коцюбинське',
                  foto: [tarantino],
                  descrip:[ 'Безкоштовна доставка від 600грн'],
                  zamovlena: 'https://www.tarantino-family.com/ua/delivery/',
                  workTime: ['пн-нд: 10:00-21:00', '', 'без перерви'], 
                  tel:'tel:+380800608888',
                  instagram: 'https://www.instagram.com/tarantinofamily/',
                  network: 'https://www.tarantino-family.com/ua/',
                }, 
                 
                      
              ]         
            },
            {
              id: 63,
              name:'Продукти',
              foto: magazin,
              lists: 
              [
                {
                  id:631, 
                  name: 'NOVUS',
                  status: true,
                  vip: false,
                  address: 'смт. Коцюбинське',
                  foto: [novus],
                  descrip:['Відсутня мінімальна сума', 
                  'Інтервал доставки 2 години'],
                  zamovlena: 'https://novus.online/',
                  workTime: ['пн-нд: 11:00-22:00', '', 'без перерви'], 
                  tel:'tel:+380443693939',
                  instagram: 'https://www.instagram.com/novus_ukraine/',
                  facebook:'https://www.facebook.com/NOVUS.com.ua/',
                }, 
                {
                  id:632, 
                  name: 'Фора',
                  status: true,
                  vip: false,
                  address: 'смт. Коцюбинське',
                  foto: [foradost],
                  descrip:['Інтервал доставки 2 години', 'Умови доставки:', '- від 899 грн – вартість 0.10 грн.', '- до 899 грн – вартість 59 грн.'],
                  zamovlena: 'https://shop.fora.ua/#address',
                  workTime: ['пн-нд: 08:00-22:00', '', 'без перерви'], 
                  tel:'tel:+380800301230',
                  instagram: 'https://www.instagram.com/fora.market/',
                  facebook:'https://www.facebook.com/fora.ukraine/',
                }, 
                      
              ]         
            },
            {
              id: 64,
              name:"Все для кав'ярень",
              foto: kofeina,
              lists: 
              [
                {
                  id:641, 
                  name: 'Оптова доставка товару',
                  // more: 'Дізнатися більше',
                  status: true,
                  vip: false,
                  address: 'смт. Коцюбинське',
                  foto: [kofeina],
                  fotowork:[ ],
                  descrip:['Кава, Чай, Стакани та кришки, Сиропи та топінги, Трубочки коктейльні, Мішалки, Маршмеллоу, Шоколад, Батончики, Печиво, Вафлі', 'Безкоштовна доставка від 1000грн'],
                  zamovlena: 'tel:+380937867846',
                  forma:'',
                  workTime: ['пн-нд: 10:00-20:00', ''], 
                  tel:'tel:+380937867846',
                  bgdescrip: 'Короткий опис саме чим Ви займаєтесь, які надаєте послуги, або яку продукцію пропонуєте до 130 символів',
                  telegram:'tg://resolve?domain=maksimser_7',

                  nameclient:'Задоволених клієнтів',
                  nameprojects:'Видів товару',
                  nameexpience:'Років доставляємо',
                  client: 98,
                  projects: 150,
                  experience: 12,
                  nameblock1:'ПРО МЕНЕ',
                  nameblock2:'ФОТО ПРОДУКЦІЇ',
                  nameblock3:'ЦІНИ НА ПРОДУКЦІЮ',
                  colorh1:'fff',
                  fotomaindescrip: 'Компанія "УкрЕнергоОблік"',
                  fotodescrip: ['Я мають достатньо великий досвід  робіт у сфері сантехніки,тому я впевнений що зможу надати вам послуги високої якості', ' Також я надаю допомогу у підборі комплектуючих для ремонту вашої сантехніки та запропоную Вам оптимальне рішення ціни та якості. Як правило в більшості випадків я прибуваю на замовлення вже з комплектуючими для ремонту сантехніки.', 'На всю виконану роботу я надаю гарантію до 1 року.Оплата за виконану роботу здійснюється після виконання замовлення.'],

                  nameWork:"Доставка товарів в кав'ярні",
                  bgpesonal: bgMaksim,
                  btnbgtel:'Замовити товар',

                  priceservise: [
                    { name: "Втановлення бойлера/Заміна бойлера", price: "від 1500/900 грн" },
                    { name: "Підключення пральної або посудомийної машини", price: "від 600 грн" },
                    { name: "Встановлення радіаторів/Заміна радіаторів", price: "від 1500/600 грн" },
                    { name: "Встановлення лічильників/Заміна лічильників", price: "від 1500/600 грн" },
                    { name: "Встановлення душових кабін", price: "від 1600 грн" },
                    { name: "Встановлення ванн/Ремонт ванн", price: "від 1500/600 грн" },
                    { name: "Встановлення джакузі/Ремонт джакузі", price: "від 2500/2000 грн" },
                    { name: "Встановлення Умивальників/Кухонних мийок", price: "від 800 грн" },
                    { name: "Встановлення унітазів/Встановлення біде", price: "від 1500 грн" },
                    { name: "Мінімальний виклик в межах Коцюбинського", price: "від 300 грн" },
                  ]
                  
                 
                 
                }, 
                
                      
              ]         
            },
            
          ]
        },
        { 
          id: 7,
          name: 'Спорт',
          foto: sport,
           types: 
          [
            {
              id: 71,
              name:'Тренажерні зали',
              foto: trenzal,
              lists: 
              [
                {
                  id:711,
                  name: 'Rich Health',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 26/2 ',
                  foto: [richhealt],
                  descrip: 'Групові та індивідуальні заняття з: пілатесу, хореографії, кікбоксингу ',
                  workTime: ['пн-пт: 8:00-22:00', 'сб-нд: 9:00-21:00',], 
                  tel:'tel:+380631582822',
                  instagram: 'https://www.instagram.com/rich_health__kotsubynske/',
                  location:'https://maps.app.goo.gl/kdJc4KJ8NpuDZxbLA',
                  network:'https://rich-health.jimdofree.com/',
                },
                {
                  id:712,
                  name: 'GRAFIT GYM',
                  status: true,
                  vip: false,
                  address: 'вулиця Доківська, 14/1 ТЦ "АТЛАНТ"',
                  foto: [grafit],
                  descrip: 'Тренажерний зал, Фітнес, CROSSFIT',
                  workTime: ['пн-пт: 8:00-22:00', 'сб: 8:00-19:00', 'нд: вихідний'], 
                  tel:'tel:+380734000770',
                  instagram: 'https://www.instagram.com/grafitgym_kotsiubynske/',
                  location:'https://maps.app.goo.gl/v69yK6sXT8pNiz5r7',
                  network:'https://grafitgym.com/',
                },
                      
              ]         
            },
            {
              id: 72,
              name:'Бойові мистецтва / Самооборона',
              foto: boevieiskustva,
              lists: 
              [
                {
                  id:721,
                  name: 'Fusin School',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова  6/4 Школа №18 ',
                  foto: [fusin],
                  descrip: 'Дисципліни: Бокс, Кікбоксинг, Самооборона, ММА, Джиу Джитсу, УШУ-саньда, Лайт-Кросфіт, Стретчинг, Табата',
                  workTime:['', '', ''], 
                  tel:'tel:+380969732827',
                  instagram: 'https://www.instagram.com/fusinzal_kotsiubynske/',
                  location:'https://maps.app.goo.gl/nDSMqU1dxvvCjySj9', 
                  network:'https://fusinjia.wixsite.com/fusin?lang=uk',
                },
                {
                  id:722,
                  name: 'Rich Health',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 26/2 ',
                  foto: [richhealt],
                  descrip: 'Групові та індивідуальні заняття з: фітнесу, пілатесу, хореографії, боксу, кікбоксингу ',
                  workTime: ['пн-пт: 8:00-22:00', 'сб-нд: 8:00-21:00', ''], 
                  tel:'tel:+380631582822',
                  instagram: 'https://www.instagram.com/rich_health__kotsubynske/',
                  location:'https://maps.app.goo.gl/kdJc4KJ8NpuDZxbLA',
                  network:'https://rich-health.jimdofree.com/',
                },
                {
                  id:723,
                  name: 'СК "Stakhov Team"',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова  6/4',
                  foto: [stakhovTeam],
                  descrip: 'Тренування для дітей та підлітків  за напрямками: Кікбоксинг, Бокс , Муай-тай  ',
                  workTime:['', '', ''], 
                  tel:'tel:+380939742854',
                  instagram: 'https://www.instagram.com/stakhov_team/',
                  location:'https://maps.app.goo.gl/nDSMqU1dxvvCjySj9', 
            
                },
                      
              ]         
            },
            {
              id: 73,
              name:'Фітнес',
              foto: fitness,
              lists: 
              [
                {
                  id:731,
                  name: 'Yana Top Fit',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 32 ',
                  foto: [topfit],
                  descrip: 'Stretching, Barre, Pilates, Full body, JUMPING, Індивідуальні тренування, Онлайн-тренування',
                  workTime: ['', '',],
                  instagram: 'https://www.instagram.com/yana_top_fit/',
                  location:"https://maps.app.goo.gl/QCTgAd8Esq4uZ8iQ8",
              
                },
                {
                  id:732,
                  name: 'Danilov School',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 17',
                  foto: [danilov],
                  descrip: 'Школа танцю та фітнесу, Групові заняття для дорослих та дітей, Індивідуальні тренування, Онлайн-тренування',
                  workTime: ['', '',], 
                  tel:'tel:+380965344440',
                  instagram: 'https://www.instagram.com/danilov_school/',
                  location:"https://maps.app.goo.gl/zpkwx5nr7mWjzSM38",
              
                },

                {
                  id:733,
                  name: 'Kangoo  Stretching ',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 26/2 тренар. зал Rich Health ',
                  foto: [kango],
                  descrip: 'Стретчинг, Пілатес, Кенго Джампс, Барре, Індивідуальні зяняття оффлайн та онлайн',
                  workTime: ['', '',], 
                
                  instagram: 'https://www.instagram.com/kangoo_stretching/',
                  location:'https://rich-health.jimdofree.com/',
              
                },           
              ]         
            },
            {
              id: 74,
              name:'Танці',
              foto: tanci,
              lists: 
              [
                {
                  id:741,
                  name: ' Dance studio Style',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14/1 ',
                  foto: [danse_studio_style],
                  descrip: 'Спортивно-бальні танці, Disco/Disco slow, Hip hop, Jazz-funk, Shuffle dance, Kids dance, Zumba, Fitness mix, Stretching/High Heels, Salsa',
                  workTime: ['', '',],
                  instagram: 'https://www.instagram.com/dancestudio_style',
                  location:"https://maps.app.goo.gl/fBpS8YwGHPtur94s6",
              
                },
                {
                  id:742,
                  name: 'Danilov School',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 17',
                  foto: [danilov],
                  descrip: 'Школа танцю та фітнесу, Групові заняття для дорослих та дітей, Індивідуальні тренування, Онлайн-тренування',
                  workTime: ['', '',], 
                  tel:'tel:+380965344440',
                  instagram: 'https://www.instagram.com/danilov_school/',
                  location:"https://maps.app.goo.gl/zpkwx5nr7mWjzSM38",
              
                },
                {
                  id:743,
                  name: 'Yana Top Fit',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 32 ',
                  foto: [topfit],
                  descrip: 'Lady Latina, Rumba, Salsa, Vogue ',
                  workTime: ['', '',],
                  instagram: 'https://www.instagram.com/yana_top_fit/',
                  location:"https://maps.app.goo.gl/QCTgAd8Esq4uZ8iQ8",
              
                },
              ]         
            },
            
          ]
        },
        { 
          id: 8,
          name: 'Фінансові послуги',
          foto: finansi,
           types: 
          [
            {
              id: 81,
              name:'Банківські установи',
              foto: bank,
              lists: 
              [
                {
                  id:811,
                  name: 'Приват Банк ',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 10 ',
                  foto: [privat],
                  descrip: [''],
                  workTime: ['пн-пт: 8:30-17:30', ' перерва: 12:30-13:30', 'сб-нд: вихідний'], 
                  tel:'tel:+380737161131',
                  location:'https://maps.app.goo.gl/YmQfxXUEm8rVCNZ86', 
                  network:'https://privatbank.ua/', 
                },
                // {
                //   id:812,
                //   name: 'Ощад Банк ',
                //   status: true,
                //   vip: false,
                //   address: ' вул. Пономарьова 2-в',
                //   foto: [ochad],
                //   descrip: [''],
                //   workTime: ['пн-пт: 9:30-17:30', '', 'сб-нд: вихідний'], 
                //   tel:'tel:+380459771244',
                //   location:'https://www.google.com/maps/place/%D0%9E%D1%89%D0%B0%D0%B4%D0%B1%D0%B0%D0%BD%D0%BA/@50.4918059,30.3320341,18z/data=!4m14!1m7!3m6!1s0x472b332f2cd9d9fb:0x1b226c6ee1ccd019!2z0JLRltC00LTRltC70LXQvdC90Y8g0J_RgNC40LLQsNGC0JHQsNC90LrRgw!8m2!3d50.4902057!4d30.3372151!16s%2Fg%2F11bbrh4bbf!3m5!1s0x472b33260ffc978b:0xe6538a8b7b3013b3!8m2!3d50.4922873!4d30.3316101!16s%2Fg%2F11c1t62x2q?entry=ttu', 
                //   network:'https://www.oschadbank.ua/', 
                // },
                      
              ]         
            },
            {
              id: 82,
              name:'Обмін валют',
              foto: obmin,
              lists: 
              [
                {
                  id:821,
                  name: 'Обмін Валют',
                  status: true,
                  vip: false,
                  address: ' вул. Доківська, 14/1 ТЦ "АТЛАНТ" 1-й пов.',
                  foto: [watsons],
                  descrip: [''],
                  workTime: ['пн-пт: 9:00-20:00', '', 'сб: 10:00-19:00', 'нд: 10:00-18:00'], 
                  location:'https://maps.app.goo.gl/yAYhSqTj1XJVts2CA',
                },
                {
                  id:822,
                  name: 'Обмін Валют',
                  status: true,
                  vip: false,
                  address: ' вул. Пономарьова "Зупинка на вимогу"',
                  foto: [zupinka],
                  descrip: [''],
                  workTime: ['пн-нд: 9:00-20:00', ' ', ''], 
                  location:'https://maps.app.goo.gl/27fEbUXfSMmAkCva7',
                },
                {
                  id:823,
                  name: 'Приват Банк ',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 10 ',
                  foto: [privat],
                  descrip: [''],
                  workTime: ['пн-пт: 8:30-17:30', ' перерва: 12:30-13:30', 'сб-нд: вихідний'], 
                  tel:'tel:+380737161131',
                  location:'https://maps.app.goo.gl/YmQfxXUEm8rVCNZ86', 
                  network:'https://privatbank.ua/', 
                },
                     
                      
              ]         
            },
          ]
        },
        { 
          id: 9,
          name: 'Ділові послуги',
          foto: delo,
          types: 
          [
            {
              id: 91,
              name:'Нотаріус',
              foto: notarius,
              lists: 
              [
                {
                  id:911,
                  name: 'Земенкова Юлія Анатоліївна  ',
                  status: true,
                  vip: false,
                  address: ' вул. Доківська, буд. 10, корпус 8, прим. 3',
                  foto: [dokivska10],
                  descrip: [''],
                  workTime: ['пн-пт: 10:00-17:00', ' сб: 10:00-14:00', 'нд: вихідний'], 
                  tel:'tel:+380678008485',
                  location:'https://maps.app.goo.gl/J3n9UjCUDaUWYE598', 
              
                },
                {
                  id:912,
                  name: 'Монько Тетяна Володимирівна',
                  status: true,
                  vip: false,
                  address: ' вул. Пономарьова, буд. 2/2',
                  foto: [ponomarevanotar],
                  descrip: [''],
                  workTime: ['пн-пт: 09:00-18:00', '', 'cб-нд: вихідний'], 
                  tel:'tel:+380672365932',
                  location:'https://maps.app.goo.gl/AF3gj4VtnSCXqHYA7', 
              
                },
                      
              ]         
            },
            {
              id: 92,
              name:'Адвокат',
              foto: advocat,
              lists: 
              [
                {
                  id:921,              
                  name: 'Тут може бути Ваша пропозиція',
                  foto: [advocat],                
                  descrip: ['Короткий опис Ваших послуг - до 150 символів',
                  'Одне фото ' ],
                  workTime: ['', '', ''],
                  status: true,
                  vip: false,
                  tel:'tel:+38000000000',
                  telegram:'#',
                  instagram: 'https://www.instagram.com/',
                  location: 'https://maps.app.goo.gl/mjZL3K7ZBHnu3u8C6'
                },
                      
              ]         
            },
            {
              id: 93,
              name:'Бухгалтер',
              foto: bugalteria,
              lists: 
              [
                {
                  id:931,              
                  name: 'Тут може бути Ваша пропозиція',
                  foto: [bugalteria],                
                  descrip: ['Короткий опис Ваших послуг - до 150 символів',
                  'Одне фото '],
                  workTime: ['', '', ''],
                  status: true,
                  vip: false,
                  tel:'tel:+38000000000',
                  telegram:'#',
                  instagram: 'https://www.instagram.com/',
                  location: 'https://maps.app.goo.gl/mjZL3K7ZBHnu3u8C6'
                },
                      
              ]         
            },
             {
              id: 94,
              name:'Ксерокопії документів/ Фото на документи',
              foto: kserox,
              lists: 
              [
                {
                  id:941,
                  name: 'Квіти подарунки',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 5Б',
                  foto: [podarunkikvit],              
                  descrip: [ 'Ксерокопії документів', 'Фото плівка', 'Ламінування'],
                  workTime: ['пн-пт: 08:00-18:00', 'сб:8:00-14:00', 'нд: вихідний'],
                  tel:'tel:+380632270269',
                  location:"https://maps.app.goo.gl/dU3p5DUJMyRWP9KW7", 
           
                },
                {
                  id:942,
                  name: 'Фото на документи (ФОТОДРУК)',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14/1 3-й поверх',
                  foto: [watsons],              
                  descrip: [ 'Фото на документ', 'Оброблення фото', 'Друкування фото з носіїв'],
                  workTime: ['пн-нд: 09:00-20:00', '', ''],
                  tel:'tel:+380500750132',
                  location:"https://maps.app.goo.gl/24t1uHhGyQj2YGTK7", 
                  network:'https://fotonadokivskii.godaddysites.com/', 
           
                },
                {
                  id:943,
                  name: 'КЦБ-ПРИНТ',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 2г (АЗС "Злагода") 2-й пов.',
                  foto: [kcb_print],              
                  descrip: [ 'Фото на документ', 'Ксерокопії документів', 'Друкування фото з носіїв', 'Візитки'],
                  workTime: ['пн-пт: 09:00-18:00', 'сб-нд: вихідний', ''],
                  tel:'tel:+380673581444',
                  location:"https://maps.app.goo.gl/NrChojngMgVK5SbL9", 
                  instagram: 'https://www.instagram.com/kcbprint',
           
                },
                
                      
              ]         
            },
            {
              id: 95,
              name:'Туристичні послуги',
              foto: turist,
              lists: 
              [
                {
                  id:951,              
                  name: 'Франшиза ТА "Поїхали з нами" ',
                  foto: [poihal],                
                  descrip: ['Продам франшизу ТА Поїхали з нами',
                  ' '],
                  workTime: ['', '', ''],
                  status: true,
                  vip: false,
                  tel:'tel:+380965684411',
                  telegram:'tg://resolve?domain=Nataliarm',                  
                },
                      
              ]         
            },
            {
              id: 96,
              name:'Фото/Відео Контент',
              foto: graficdizainer,
              lists: 
              [
                {
                  id:951,              
                  name: 'Animation for Bisiness ',
                  foto: [animation],                
                  descrip: ['Анімований та статичний контент, що приверне увагу та розкриє цінність твого продукту/послуг.',
                  ' '],
                  workTime: ['', '', ''],
                  status: true,
                  vip: false,
                  tel:'tel:+380936166172',
                  instagram: 'https://www.instagram.com/animation_for_business/',
                  telegram:'tg://resolve?domain=anastasiiabezuhlova',                  
                },
                      
              ]         
            },
          ]
        },
        { 
          id: 10,
          name: 'Все для авто',
          foto: avto,
           types: 
          [
            {
              id: 101,
              name:'Автомагазини',
              foto: avtomagazin,
              lists: 
              [
                {
                  id:1011,
                  name: 'Міні Авто Маркет Фаворит',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова, 3 ',
                  foto: [magazin1],
                  descrip: ['Автомагазин різних товарів'],
                  workTime: ['пн-сб: 10:00-19:00', 'Обід: 13:00-14:00 ', 'нд: вихідний'], 
                  // tel:'tel:+30934528352',
                  location:'https://maps.app.goo.gl/GN91eHaSS8axJ93L9', 
                 
                },
                      
              ]         
            },
            {
              id: 102,
              name:'Шиномонтаж',
              foto: shinomontaj,
              lists: 
              [
                {
                  id:1021,
                  name: 'Шиномонтаж-Аргон',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова, 28Б ',
                  foto: [argon],
                  descrip: ['Сезонна заміна гуми, Виправлення дисків, Ремонт проколів, Рихтування дисків, Ремонт бічних порізів, Терміновий шиномонтаж'],
                  workTime: ['пн-сб: 10:00-20:00', ' ', 'нд: вихідний'], 
                  tel:'tel:+30934528352',
                  location:'https://maps.app.goo.gl/vg7ZfAJNkuhzPUV8A', 
                 
                },
                {
                  id:1022,
                  name: 'СТО "Тєлєга" ',
                  status: true,
                  vip: false,
                  address: 'вул. Залізнична 7 ',
                  foto: [telega],
                  descrip: ['Сезонна заміна гуми, Виправлення дисків, Ремонт проколів, Рихтування дисків, Ремонт бічних порізів, Терміновий шиномонтаж' ],
                  workTime: ['вт-сб: 9:00-19:00', ' ', 'нд-пн: вихідний'], 
                  tel:'tel:+380684058474',
                  location:'https://maps.app.goo.gl/k7GKoD6kaqrRBQh77', 
                 
                },

                {
                  id:1023,
                  name: 'Гуртовня шин',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова, 5/6а ',
                  foto: [gurtivnia],
                  descrip: ['Нові та б/у покришки, Сезонна заміна гуми, Виправлення дисків, Ремонт проколів, Рихтування дисків, Ремонт бічних порізів, Терміновий шиномонтаж, Аргонна зварка'],
                  workTime: ['пн-нд: 09:00-19:00', ' ', ''], 
                  tel:'tel:+30986352865',
                  location:'https://maps.app.goo.gl/mFEoVa1XXiCJMrJ17', 
                 
                },
                      
              ]         
            },
            {
              id: 103,
              name:'СТО',
              foto: cto,
              lists: 
              [
                {
                  id:1031,
                  name: 'СТО "Тєлєга"',
                  status: true,
                  vip: false,
                  address: 'вул. Залізнична 7 ',
                  foto: [telega],
                  descrip: ['Заміна оливи в двигуні та АКПП, Заміна та ремонт кермових рейок, Заміна гальмівних дисків та колодок, Заміна ременя ГРМ, запрака кондиц., шиномонтаж' ],
                  workTime: ['вт-сб: 9:00-19:00', ' ', 'нд-пн: вихідний'], 
                  tel:'tel:+380684058474',
                  location:'https://maps.app.goo.gl/k7GKoD6kaqrRBQh77', 
                 
                },
                {
                  id:1032,
                  name: 'Remtorg ',
                  status: true,
                  vip: false,
                  address: 'вулиця Пономарьова 17 ',
                  foto: [remtorg],
                  descrip: ['Забезпечуємо професійний ремонт вашого авто швидко та ефективно'],
                  workTime: ['пн-сб: 9:00-19:00', ' ', 'нд: вихідний'], 
                  tel:'tel:+380672828601',
                  location:'https://maps.app.goo.gl/LyydtZCB6UrX6bXWA', 
                 
                },
                      
              ]         
            },
            {
              id: 104,
              name:'Парковки та паркінги',
              foto: parking,
              lists: 
              [
                {
                  id:1041,
                  name: 'Автостоянка (Оренда)',
                  status: true,
                  vip: false,
                  address: 'вул. Залізнична 2/1 ',
                  foto: [zagalnaparking],
                  descrip: ['Автостоянка для автомобілів' ],
                  workTime: ['пн-нд: 05.00-24.00', ' ', ''], 
                  location:'https://maps.app.goo.gl/9YXPVuHAgTosjQhC9', 
                 
                },
                {
                  id:1042,
                  name: 'Автостоянка "Parking Zone"',
                  status: true,
                  vip: false,
                  address: 'вул. Залізнична 1',
                  foto: [zagalnaparking],
                  descrip: ['Автостоянка для автомобілів та будь-якого транспорту', 'Територія повністю під охороною та відеонаглядом' ],
                  workTime: ['пн-нд: 05.00-24.00', ' ', ''], 
                  tel:'tel:+380984446983',
                  location:'https://maps.app.goo.gl/tvK2tUvG971PgzNp6', 
                 
                },
                      
              ]         
            },
            {
              id: 105,
              name:'Мийки/Детейлінг',
              foto: moika,
              lists: 
              [

                {
                  id:1053,              
                  name: 'Мийка самообс.  "Nano Wash"',
                  address: 'вул. Залізнична 2/2',
                  foto: [nanowash],                
                  descrip: ['До Ваших послуг 6 постів мийки',
                  'Холодна вода, гаряча вода, піна, віск, пилосос', ''],
                  workTime: ['', '', ''],
                  status: true,
                  vip: false,
                  location: 'https://maps.app.goo.gl/yGnqQnPSBBz1oUtM7',
                  instagram: 'https://www.instagram.com/nanowash_ktsb',
                  
                },
                {
                  id:1051,              
                  name: ' Детейлінг Мийка "Gsaf Detailing"',
                  foto: [gsaf],                
                  descrip: ['Полірування фар/кузова',
                  'Хімчистка салону', 'Нанокераміка', 'Віск/Антидощ'],
                  workTime: ['', '', ''],
                  status: true,
                  vip: false,
                  tel:'tel:+380680058500',
                  instagram: 'https://www.instagram.com/gsaf_detailing',
                  location: 'https://maps.app.goo.gl/WGKPUmETgF6tA8Y48'
                },
                {
                  id:1052,              
                  name: ' Детейлінг/Тюнінг  "Soul Car"',
                  foto: [soulcar],                
                  descrip: ['Професфйне тонування Вашого авто',
                  'Оклейка захисними антигравійними плівками', 'Відновлення фар'],
                  workTime: ['', '', ''],
                  status: true,
                  vip: false,
                  tel:'tel:+380638734689',
                  instagram: 'https://www.instagram.com/soul_car_detailing_studio/',
                  location: 'https://maps.app.goo.gl/dH5qFBsj6KxCfWRa9'
                },
                      
              ]         
            },
    
          ]
        },
        { 
          id: 11,
          name: 'Догляд за одягом та взуттям',
          foto: thingth,
           types: 
          [
            {
              id: 111,
              name:'Ремонт одягу / Ательє',
              foto: atele,
              lists: 
              [
                {
                  id:1111,
                  name: 'Ательє "Four seasons"',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 26 корп 1',
                  foto: [fourseson],
                  descrip: ['Пошив одягу ', 'Ремонт одягу будь-якої складності' ],
                  workTime: ['пн-чт 9:00-20:00', 'пт 9:00-19:00', 'сб-нд вихідний'], 
                  tel:'tel:+380976837973',
                  location:'https://maps.app.goo.gl/WCZhyTFBackAJjZa8', 
                 
                },
                {
                  id:1112,
                  name: 'Ательє "Ірис"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14/1, 2-й поверх',
                  foto: [watsons],
                  descrip: ['Пошив одягу', 'Ремонт одягу будь-якої складності' ],
                  workTime: ['пн-сб 10:00-19:00', 'нд вихідний'], 
                  tel:'tel:+380631094063',
                  location:'https://maps.app.goo.gl/25DFWJn9myBmv9hG9', 
                 
                },
                {
                  id:1113,
                  name: 'Швейна майстерня "Olrad"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 10, корп. 10',
                  foto: [olrad],
                  descrip: ['Послуги шиття та перешивання', 'Ремонт одягу будь-якої складності' ],
                  workTime: ['пн-пт 09:00-18:00', 'сб 10:00-18:00', 'нд 13:00-17:00'], 
                  tel:'tel:+380996710260',
                  location:'https://maps.app.goo.gl/m5Xic6ENgjCGYjWh8', 
                  instagram: ' https://www.instagram.com/olrad.workshop',
                 
                 
                },
                {
                  id:1114,
                  name: 'Швейна майстерня "Оленка"',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 15, 2-й поверх',
                  foto: [olenka],
                  descrip: ['Послуги шиття та перешивання', 'Ремонт одягу будь-якої складності' ],
                  workTime: ['вт-пт 09:00-18:00', 'сб 10:00-17:00', 'нд-пн вихідний'], 
                  tel:'tel:+380677296004',
                  location:'https://maps.app.goo.gl/BQH9E9m1uVcFiXer6', 
 
                 
                 
                },
                      
              ]         
            },
            {
              id: 112,
              name:'Ремонт взуття',
              foto: obuv,
              lists: 
              [
                {
                  id:1123,
                  name: 'Ремонт Взуття ',
                  status: true,
                  vip: false,
                  address: "вул. Пономарьова 26 корп. 1 під'їзд 5",
                  foto: [vzutiaponor26],
                  descrip: [ 'Ремонт взуття, шкіргалантерея, ремонт сумок та валіз, заміна фурнітури, розтягування взуття', 'Заточка ножів' ],
                  workTime: ['пн-пт: 08:00–07:00 ', 'сб: 10:00–15:00', 'нд: вихідний'], 
                  tel:'tel:+380976786094',
                  location:'https://maps.app.goo.gl/3CgtQVyficnkDfdU9', 
                 
                },
                {
                  id:1121,
                  name: 'Взуттєва майстерня',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 39',
                  foto: [vzutiadikiv39],
                  descrip: [ 'Ремонт взуття будь-якої складності' ],
                  workTime: ['пн,ср: 09:00–12:00 та 16:00–19:00', 'сб: 10:00–14:00'], 
                  tel:'tel:+380676592670',
                  location:'https://maps.app.goo.gl/xVvYWFLhLE61JEQ4A', 
                 
                },
                {
                  id:1122,
                  name: 'Взуттєва майстерня',
                  status: true,
                  vip: false,
                  address: 'вулиця Залізнична 1',
                  foto: [zaliznichnavzut],
                  descrip: [ 'Ремонт взуття будь-якої складності' ],
                  workTime: ['пн-сб: 10:00–18:00', 'нд: вихідний'], 
                  location:'https://maps.app.goo.gl/mQheGUACWk27cpGb8', 
                 
                },

                
                      
              ]         
            },
           
            {
              id: 113,
              name:'Хімчистка',
              foto: himchiska,
              lists: 
              [
                {
                  id:1131,
                  name: 'Ательє "Ірис"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14/1, 2-й поверх',
                  foto: [watsons],
                  descrip: ['Хімчистка','Пошив одягу', 'Ремонт одягу будь-якої складності' ],
                  workTime: ['пн-сб 10:00-19:00', 'нд вихідний'], 
                  tel:'tel:+380631094063',
                  location:'https://maps.app.goo.gl/25DFWJn9myBmv9hG9', 
                 
                },
                      
              ]         
            },
          ]
        },
        
        { 
          id: 12,
          name: 'Продаж уживаних речей',
          foto: podarunok,
           types: 
          [
            {
              id: 121,
              name:'Меблі',
              foto: bymebli,
              lists: 
              [
                {
                  id:1211,
                  rentsal: '',
                  name: 'Продаж тумби',
                  foto: [ art1211],
                  interv: 3000,
                  descrip: ['Зроблено "для себе" з ЛДСП завтовшки 36мм. Виглядає дорого, практично, стильно. Сіра тумба під ТВ 42-75 діагоналлю. В ідеалі.',
                 ],
                  currency: 'грн',
                  nameflour:'поверх',
                  // price: ,
                  // flor: 1,
                  // area: 44,
                  status: true,
                  vip: false,
                  telegram:'tg://resolve?domain=ART7Luxe',
                  tel:'tel:+380988770087',
                  viber: 'viber://chat?number=+380988770087',
                },
              ]         
            },
            {
              id: 122,
              name:'Одяг та взуття',
              foto: buodejda,
              lists: 
              [
                {
                  id:1221,
                  rentsal: '',
                  name: 'Тут може бути Ваша пропозиція',
                  foto: [buodejda, buodejda, buodejda],
                   interv: 3000,
                   descrip: ['Короткий опис Вашого товару - до 170 символів',
                  'Одне фото'],
                  currency: 'грн',
                  nameflour:'поверх',
                  // price: ,
                  // flor: 1,
                  // area: 44,
                  status: true,
                  vip: false,
                  tel:'tel:+38000000000',
                  telegram:'#',
                  instagram: 'https://www.instagram.com/',
                  location: 'https://maps.app.goo.gl/mjZL3K7ZBHnu3u8C6'
                },
                      
              ]         
            },
            {
              id: 123,
              name:'Техніка',
              foto: butexnika,
              lists: 
              [
                {
                  id:1231,
                  rentsal: '',
                  name: 'Тут може бути Ваша пропозиція',
                  foto: [butexnika, butexnika, butexnika],
                   interv: 3000,
                   descrip: ['Короткий опис Вашої пропозиції - до 170 символів',
                   'Одне фото '],
                   currency: 'грн',
                  nameflour:'поверх',
                  // price: ,
                  // flor: 1,
                  // area: 44,
                  status: true,
                  vip: false,
                  tel:'tel:+38000000000',
                  telegram:'#',
                  instagram: 'https://www.instagram.com/',
                  location: 'https://maps.app.goo.gl/mjZL3K7ZBHnu3u8C6'
                },
                      
              ]         
            },
          ]
        },
        { 
          id: 13,
          name: 'Віддам безкоштовно',
          foto: podarok,
           types: 
          [
            {
              id: 131,
              name:'Меблі',
              foto: bymebli,
              lists: 
              [
                {
                  id:1311,
                  rentsal: '',
                  name: 'Тут може бути Ваша пропозиція',
                  foto: [bymebli, bymebli],
                  interv: 3000,
                  descrip: ['Короткий опис Вашої пропозиції - до 170 символів',
                  'Одне фото '],
                  currency: 'грн',
                  nameflour:'поверх',
                  // price: ,
                  // flor: 1,
                  // area: 44,
                  status: true,
                  vip: false,
                  tel:'tel:+38000000000',
                  telegram:'#',
                  instagram: 'https://www.instagram.com/',
                  location: 'https://maps.app.goo.gl/mjZL3K7ZBHnu3u8C6'
                },
                      
              ]         
            },
            {
              id: 132,
              name:'Одяг та взуття',
              foto: buodejda,
              lists: 
              [
                {
                  id:1321,
                  rentsal: '',
                  name: 'Тут може бути Ваша пропозиція',
                  foto: [buodejda, buodejda, buodejda],
                  interv: 3000,
                  descrip: ['Короткий опис Вашої пропозиції - до 170 символів',
                  'Одне фото '],
                  currency: 'грн',                  
                  nameflour:'поверх',
                  // price: ,
                  // flor: 1,
                  // area: 44,
                  status: true,
                  vip: false,
                  tel:'tel:+38000000000',
                  telegram:'#',
                  instagram: 'https://www.instagram.com/',
                  location: 'https://maps.app.goo.gl/mjZL3K7ZBHnu3u8C6'
                },
                      
              ]         
            },
            {
              id: 133,
              name:'Техніка',
              foto: butexnika,
              lists: 
              [
                {
                  id:1331,
                  rentsal: '',
                  name: 'Тут може бути Ваша пропозиція',
                  foto: [butexnika, butexnika, butexnika],
                  interv: 3000,
                  descrip: ['Короткий опис Вашої пропозиції - до 170 символів',
                  'Одне фото '],
                  currency: 'грн',
                  nameflour:'поверх',
                  // price: ,
                  // flor: 1,
                  // area: 44,
                  status: true,
                  vip: false,
                  tel:'tel:+38000000000',
                  telegram:'#',
                  instagram: 'https://www.instagram.com/',
                  location: 'https://maps.app.goo.gl/mjZL3K7ZBHnu3u8C6'
                },
                      
              ]         
            },
          ]
        },
        { 
          id: 14,
          name: 'Магазини',
          foto: magazinzagal,
           types: 
          [
            {
              id: 141,
              name:'Продуктові магазини',
              foto: magazinprod,
              lists: 
              [
                {
                  id:1411,
                  name: 'Фора (ТЦ АТЛАНТ)',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14/2 ',
                  foto: [watsons],              
                  descrip: ['Супермаркет продуктів та товарів','Наявність доставки:', '- від 899 грн – вартість 0.10 грн.', '- до 899 грн – вартість 59 грн.'],
                  zamovlena: 'https://shop.fora.ua/#address',
                  workTime: ['пн-нд: 6:00-23:30', ' ', ''], 
                  tel:'tel:+380800301230',
                  location:'https://maps.app.goo.gl/BH1kJZZgkAX4CtgB7', 
                  network:'https://fora.ua/', 
                },
                {
                  id:1412,
                  name: 'Фора ( ЖК Атлант 2)',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 26 корп. 3',
                  foto: [forapon26],              
                  descrip: [ 'Супермаркет продуктів та товарів','Наявність доставки:', '- від 899 грн – вартість 0.10 грн.', '- до 899 грн – вартість 59 грн.'],
                  zamovlena: 'https://shop.fora.ua/#address',
                  workTime: ['пн-нд: 6:00-23:30', ' ', ''], 
                  tel:'tel:+380800301230',
                  location:'https://maps.app.goo.gl/w9XGp7dN833fQyUp6', 
                  network:'https://fora.ua/', 
                },
                {
                  id:1413,
                  name: 'Пчілка Маркет',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 28а',
                  foto: [bdjilka],              
                  descrip: [ 'Супермаркет продуктів та товарів','Свіже м’ясо та риба', 'Власна кондитерська', 'Кулінарія', 'Піца та кав’ярня'],
                  workTime: ['пн-нд: 7:00-23:00', ' ', ''], 
                  tel:'tel:+380733418498',
                  location:'https://maps.app.goo.gl/iHXjfqhH9hDPj1hj9', 
                  network:'https://pchjolkamarket.ua/', 
                },

                {
                  id:1414,
                  name: 'Крамничка',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 18б',
                  foto: [kramnichca],              
                  descrip: [''],
                  workTime: ['пн-нд: 8:30-21:30', ' ', ''],
                  location:'https://maps.app.goo.gl/87bRg9ERc8CM8tsC6', 
           
                },
                {
                  id:1415,
                  name: 'Магазин',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 26 корп 1',
                  foto: [magazpon26],              
                  descrip: ['Магазин товарів:', 'хліб, молочні товари, сосиски та ковбасні вироби, газовані напої, слабоалкогольні напої, сигарети, овочі та фрукти '],
                  workTime: ['пн-нд: 8:00-22:30', ' ', ''],
                  location:"https://maps.app.goo.gl/FXCjNfApXVu2sLYE9", 
           
                },
              
                {
                  id:1417,
                  name: 'Мінімаркет ІгЛенд',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 2/2',
                  foto: [igland],              
                  descrip: ['Магазин товарів:', 'хліб, молочні товари, сосиски та ковбасні вироби, газовані напої, слабоалкогольні напої, сигарети, овочі та фрукти '],
                  workTime: ['пн-нд: 9:00-22:00', ' ', ''],
                  tel:'tel:+380995596789',
                  location:"https://maps.app.goo.gl/EKqDXEtbzdL9QxAS9", 
           
                },
                {
                  id:1418,
                  name: 'Топ Маркет',
                  status: true,
                  vip: false,
                  address: 'вул. Меблева 10',
                  foto: [topmarket],              
                  descrip: ["Супермаркет товарів: хлібо-булочні вироби, піца, молочні товари, м'ясні вироби, алкогольні та слабоалкогольні напої, сигарети, овочі та фрукти, смачна кава"],
                  workTime: ['пн-нд: 8:00-22:00', ' ', ''],
                  location:"https://maps.app.goo.gl/JPBGSJRTj2qQCJFq9", 
           
                },

                


                
                      
              ]         
            },
            {
              id: 142,
              name:'Готова їжа/ Заморожені продукти',
              foto: zamorojeni,
              lists: 
              [
                // {
                //   id:1421, 
                //   name: 'Територія  смаку',
                //   status: true,
                //   vip: false,
                //   address: ' вул. Доківська 10 корпус 9',
                //   foto: [teritiriasmaku],
                //   descrip:['Магазин смачної їжі', 'Домашня кухня'],
                //   zamovlena: 'tel:+380682303208',
                //   workTime: ['пн-нд: 9:00-20:00', '', 'без перерви'], 
                //   tel:'tel:+380682303208',
                //   location: 'https://maps.app.goo.gl/7wDHDvwGdYrkoVs79',
                //   instagram: 'https://www.instagram.com/teryrotiya_smaku_kcb/'
                // }, 
                {
                  id:1422, 
                  name: 'Кафе "Бо Смачно"',
                  status: true,
                  vip: false,
                  address: ' вул. Доківська 2г',
                  foto: [bosmachno],
                  descrip:['Магазин готової їжі', 'Комплексні обіди'],
                  workTime: ['пн-нд: 7:00-21:00', '', ''], 
                  tel:'tel:+380991885573',
                  location: 'https://maps.app.goo.gl/h7HuZqkwP4aGaJMp6',
                  instagram: 'https://www.instagram.com/_bo_smachno_/'
                }, 
                
               
                {
                  id:1423, 
                  name: 'Напівфабрикати "Галя Балувана"1',
                  status: true,
                  vip: false,
                  address: ' вул. Пономарьова 17',
                  foto: [galiabaluvana],
                  descrip:['Магазин домашніх виробів', 'Тільки якісні та натуральні продукти'],
                  workTime: ['пн-нд: 9:00-21:00', '', ''], 
                  tel:'tel:+380670002443',
                  location: 'https://maps.app.goo.gl/mMQcQbEX14zJfpWq7',
                  instagram: 'https://www.instagram.com/galiabaluwana/'
                }, 
                {
                  id:1424, 
                  name: 'Напівфабрикати "Галя Балувана"2',
                  status: true,
                  vip: false,
                  address: '  вул. Доківська 12',
                  foto: [galiabaluvana],
                  descrip:['Магазин домашніх виробів', 'Тільки якісні та натуральні продукти'],
                  workTime: ['пн-нд: 9:00-21:00', '', ''], 
                  tel:'tel:+380670002443',
                  location: 'https://maps.app.goo.gl/kwhAKco6zU3enk5dA',
                  instagram: 'https://www.instagram.com/galiabaluwana/'
                }, 
                {
                  id:1424, 
                  name: 'Напівфабрикати "Галя Балувана"3',
                  status: true,
                  vip: false,
                  address: '  вул. Бакала 44',
                  foto: [galiabaluvana],
                  descrip:['Магазин домашніх виробів', 'Тільки якісні та натуральні продукти'],
                  workTime: ['пн-нд: 9:00-21:00', '', ''], 
                  tel:'tel:+380670002443',
                  location: 'https://maps.app.goo.gl/oLs6QqBNAoU9qNVF9',
                  instagram: 'https://www.instagram.com/galiabaluwana/'
                }, 
                      
              ]         
            },
            
             {
              id: 144,
              name:'Алкогольні магазини',
              foto: alcomagazin,
              lists: 
              [
                {
                  id:1441,
                  name: 'Пивний магаз. "beer CENTR"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14',
                  foto: [beercentr],              
                  descrip: ['Пивний магазин:', ''],
                  workTime: ['пн-нд: 10:00-22:00', ' ', ''],
                  tel:'tel:+380982428826',
                  location:"https://maps.app.goo.gl/yge8m85ubTiqD48T9", 
           
                },
                {
                  id:1442,
                  name: 'Пивний магаз. "Bullka beer"',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 26 корп. 1',
                  foto: [bulkabeer],              
                  descrip: ['Пивний магазин:', ''],
                  workTime: ['пн-нд: 11:00-22:00', ' ', ''],
                  location:"https://maps.app.goo.gl/e2W2ZCqDo2UyjU9X7", 
           
                },
                {
                  id:1443,
                  name: 'Магазин напоїв "Біле Сухе"',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 26 корп. 3',
                  foto: [bilesuxe],              
                  descrip: [' Мережа магазинів біля дому🍷', 'Більше 2000 видів алкогольних напоїв', 'Смачні закуски'],
                  workTime: ['пн-пт: 08:00-22:00', 'сб-нд: 09:00-22:00', ''],
                  instagram: 'https://www.instagram.com/bilesuhe.com.ua/',
                  network: 'https://bilesuhe.com.ua/',
                  tel:'tel:+380800214246',
                  location:"https://maps.app.goo.gl/uHKXTyUAfYbVzuEbA", 
           
                },

                
                      
              ]         
            },
            {
              id: 145,
              name:'Будівельні магазини',
              foto: stroitelmagazin,
              lists: 
              [
                {
                  id:1455,
                  name: 'Продаж та встановлення вікон',  
                  address: ' вул. Доківська, 1-Б 2-й поверх магазину "Будматеріали"',        
                  status: true,
                  vip: false,
                  age: 35,
                  foto: [budmaterialvikna],
                  descrip: [ 
                  'Продаж, встановлення, обслуговування вікон, балконів', 
                  'Виносні, внутрішні та зовнішні оздоблювальні роботи.',  
                  'Комплектуючі до вікон.',
                ],
                  workTime: ['', '', ''],
                  tel:'tel:+380669798918',
                  viber: 'viber://chat?number=+380669798918',
                  location: 'https://maps.app.goo.gl/x6pWtxyLC9v18bDu9',
        
                
                },
                {
                  id:1451,
                  name: 'Магазин інструментів "Dnipro-M"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14/1',
                  foto: [dniprom],              
                  descrip: [' Покупка частинами від Монобанк', 'Прийом інструменту на сервісне обслуговування', 'Тестування інструменту'],
                  workTime: ['пн-нд: 08:00-20:00', '', ''],
                  instagram: 'https://www.instagram.com/dniprom/',
                  network: 'https://dnipro-m.ua/',
                  tel:'tel:+380674145131',
                  location:"https://maps.app.goo.gl/YckYmt2y6UHQ8n7z5", 
           
                },

                {
                  id:1452,
                  name: 'Будівельні матеріали',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 1',
                  foto: [budmaterial],              
                  descrip: [ 'Будівельні матеріали, сантехніка електрика, інструмент'],
                  workTime: ['пн-нд: 09:00-18:00', '', ''],
                  location:"https://maps.app.goo.gl/fWBsh9Zsrxit2j6K9", 
           
                },
                {
                  id:1453,
                  name: 'Магазин пиломатеріалів "Строй Мастер"',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 3',
                  foto: [stroimaster],              
                  descrip: [ 'Виробництво пиломатеріалів:', 'дошка підлоги, блок хаус, вагонка, імітація бруса, терасна дошка, суха стругана дошка, брус сухий струганий, дошка обрізна, необрізна дошка.'],
                  workTime: ['пн-нд: 09:00-18:00', '', ''],
                  network: 'https://stroi-master.com.ua/',
                  tel:'tel:+380970075065',
                  location:"https://maps.app.goo.gl/menZkcPDrBA1nQjH6", 
           
                },
                {
                  id:1454,
                  name: 'Вироби з мармуру "Імперія Мармуру"',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 30',
                  foto: [mramor],              
                  descrip: [ 'Виготовлення з мармуру:', 'каміни, столи,   мармурові мийки, декоративні колони, підвіконня, сходи, балясини, скульптури і т.і.'],
                  workTime: ['пн-пт: 08:30-18:30', 'сб-нд: вихідний', ''],
                  network: 'https://marble-ua.com/',
                  tel:'tel:+380674471407',
                  location:"https://maps.app.goo.gl/y5eGr9JtpZTtwj6m9", 
           
                },
        
              ]         
            },
            {
              id: 146,
              name:'Доглядові  магазини',
              foto: mouhiemagaz,
              lists: 
              [
                {
                  id:1461,
                  name: 'Магазин "EVA"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 1',
                  foto: [eva],              
                  descrip: ['Оригінальна продукція', 'Отримання товарів:', 'Самовивіз, Кур\'єр, Нова пошта, Укрпошта'],
                  workTime: ['пн-нд: 08:00-21:00', '', ''],
                  instagram: 'https://www.instagram.com/eva_ua/',
                  network: 'https://eva.ua/',
                  tel:'tel:+380800303700',
                  location:"https://maps.app.goo.gl/rKgUTyAWLHLzo3Kw8", 
           
                },
                {
                  id:1462,
                  name: 'Магазин "Watsons"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14/1',
                  foto: [watsons],              
                  descrip: ['Оригінальна продукція', 'Отримання товарів:', 'Самовивіз, Кур\'єр, Нова пошта, Укрпошта'],
                  workTime: ['пн-нд: 09:00-21:00', '', ''],
                  network: 'https://www.watsons.ua/',
                  tel:'tel:+380800300333',
                  location:"https://maps.app.goo.gl/hPvVHEto8Uy39H7k7", 
           
                },
                
              ]         
            },
            {
              id: 147,
              name:'Квіткові  магазини',
              foto: kvitkovimagaz,
              lists: 
              [
                {
                  id:1471,
                  name: 'Магазин квітів "Подарую тобі..."',
                  status: true,
                  vip: false,
                  address: 'Пономарьова, 26, корпус 3',
                  foto: [podaruutobi],              
                  descrip: [' Покупка в магазині', 'Доставка в день замовлення'],
                  workTime: ['пн-нд: 09:00-20:00', '', ''],
                  instagram: 'https://www.instagram.com/podaruyu__tobi/',
                  tel:'tel:+380964018180',
                  location:"https://maps.app.goo.gl/zcB1XaFqCRo66s8r5", 
           
                },
                {
                  id:1472,
                  name: 'Магазин квітів "Flowers&Coffee"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська, 14/1',
                  foto: [flowerscoffee],              
                  descrip: [ ' Покупка в магазині', 'Доставка в день замовлення'],
                  workTime: ['пн-нд: 09:00-19:00', '', ''],
                  tel:'tel:+380682013911',
                  location:"https://maps.app.goo.gl/55hzDKmwTgyaWhH2A", 
           
                },
                {
                  id:1473,
                  name: 'Магазин кульок та квітів "KyivShar"',
                  status: true,
                  vip: false,
                  address: 'вул. Залізнична 1б',
                  foto: [kyivshar],              
                  descrip: [ 'У магазині Ви можете:', 'Забрати кулі замовлені через сайт', 'Вибрати кульки в магазині', 'Зробити замовлення в магазині та оформити доставкуо',],
                  workTime: ['пн-нд: 09:00-21:00', '', ''],
                  tel:'tel:+380675176849',
                  network: 'https://makemehappy.com.ua/',
                  location:"https://maps.app.goo.gl/Unud2F4RHQA3xak29", 
           
                },
                {
                  id:1474,
                  name: 'Магазин квітів "Sunrise Flowers&Coffee"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 10, корп 8',
                  foto: [sunrisedokiv],              
                  descrip: [ 'Квіткова кавʼярня в Коцюбинському🌷',
                  'Додаємо ложечку любові в твоє життя'],
                  workTime: ['пн-пт: 08:00-21:00', 'сб-нд: 09:00-21:00', ''],
                  tel:'tel:+380682013911',
                  instagram: 'https://www.instagram.com/sunrise_flowers.coffee/',
                  location:"https://maps.app.goo.gl/m5h2uVLUhEXCb9gk9", 
           
                },
                {
                  id:1475,
                  name: 'Студія флористики "Sun Rise"',
                  status: true,
                  vip: false,
                  address: 'вул. Залізнична, 2/1',
                  foto: [sunrisezaliz],              
                  descrip: [ 'Студія квітів та декору'],
                  workTime: ['пн-нд: 08:00-19:00', '', ''],
                  tel:'tel:+380682013911',
                  instagram: 'https://www.instagram.com/flowerkyiv/',
                  location:"https://maps.app.goo.gl/oUZbVb2J8H913AQKA", 
           
                },
                {
                  id:1476,
                  name: 'Квіти подарунки',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 5Б',
                  foto: [podarunkikvit],              
                  descrip: [ 'Магазин  квітів та подарунків'],
                  workTime: ['пн-пт: 08:00-18:00', 'сб:8:00-14:00', 'нд: вихідний'],
                  tel:'tel:+380632270269',
                  location:"https://maps.app.goo.gl/dU3p5DUJMyRWP9KW7", 
           
                },
                
              ]         
            },
          ]
        },
        { 
          id: 15,
          name: "Кафе, Ресторани, Кав'ярні",
          foto: kafe,
           types: 
          [
            {
              id: 151,
              name:"Кав'ярні",
              foto: kofeniakaf,
              lists: 
              [{
                id:1510,
                name: 'Кав\'ярня "My Way"',
                status: true,
                vip: false,
                address: 'вул. Доківська 10 корп. 6',
                foto: [myway],              
                descrip: [ 'Кафе з терасою', 'Працюємо з генератором', ''],
                workTime: ['пн-пт 8:00- 21:00 (літній час 22:00)', 'сб-нд 09:00-21:00(літній час 22:00)', ''],
                tel:'tel:+380970564413',
                instagram:"https://www.instagram.com/my_way_coffee_k10/", 
                location:'https://maps.app.goo.gl/YZbrA8GKpcG2x3FL8',             
              },
                {
                  id:1511,
                  name: 'Пекарня "SMARAGDOVA"',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 26 корп. 5',
                  foto: [smaragdova],              
                  descrip: [ 'Пекарня з кавою', 'Смачна випічка без маргарину'],
                  workTime: ['пн-пт 8:00-19:00', 'сб-нд 10:00-18:00', ''],
                  instagram:"https://www.instagram.com/smaragdova.bakery/", 
                  location:'https://maps.app.goo.gl/Sec5cpu3iAFntS4o8',             
                },
                {
                  id:1512,
                  name: 'Кав‘ярня "Vesna"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 10 корп. 10',
                  foto: [vesna],              
                  descrip: [ 'Кав‘ярня з душею', 'Кава та бельгійські вафлі'],
                  workTime: ['пн-нд 7:30-21:00', '', ''],
                  tel:'tel:+380937517907',
                  instagram:"https://www.instagram.com/vesna.coffee/", 
                  location:'https://maps.app.goo.gl/WZbP6CLG1di4KM1s6',             
                },
                {
                  id:1513,
                  name: 'Кав‘ярня "Чіназес"',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 26 корп. 1',
                  foto: [chinazes],              
                  descrip: [ 'Затишне кафе зі свіжообсмаженою кавою та затишним інтер‘єром'],
                  workTime: ['пн-пт 8:00-21:00', 'сб-нд 9:00-21:00', ''],
                  tel:'tel:+380954899829',
                  instagram:"https://www.instagram.com/chinazes.coffee/", 
                  location:'https://maps.app.goo.gl/dV1AgDX98goKfZ8k9',             
                },
                {
                  id:1514,
                  name: 'Круасанна "КЦБ"',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 26 корп. 1',
                  foto: [kcb],              
                  descrip: [ 'Перша круасанна в Коцюбинському', 'Свіжа випічка', 'Найранковіша кава'],
                  workTime: ['пн-пт 8:00-21:00', 'сб-нд 9:00-21:00', ''],
                  instagram:"https://www.instagram.com/kotsiuba_croissant/", 
                  location:'https://maps.app.goo.gl/yVZBXd1sdWARt7BF6',             
                },
                {
                  id:1515,
                  name: 'Кав‘ярня "Miracle cafe"',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 13А',
                  foto: [mirical],              
                  descrip: [ 'Смачна кава та вишукані десерти; Сендвічі', 'Свіжа випічка', 'Торти на замовлення'],
                  workTime: ['пн-пт 7:00-19:00', 'сб-нд 8:00-20:00', ''],
                  instagram:"https://www.instagram.com/miracle_cafe_ktsb/", 
                  location:'https://maps.app.goo.gl/vMYVRnUSqG1Lr5mx5',             
                },
                {
                  id:1516,
                  name: 'Кав‘ярня "Coffee Beans 1"',
                  status: true,
                  vip: false,
                  address: 'вул. Залізнична 2/1',
                  foto: [beanse],              
                  descrip: [ 'Кава з собою', 'Смачні десерти;', 'Сендвічі'],
                  workTime: ['пн-нд 9:00-21:00', '', ''],
                  tel:'tel:+380634080693',
                  instagram:"https://www.instagram.com/kava_beans/", 
                  location:'https://maps.app.goo.gl/JvMD5r4cDeAPXfSP8',             
                },
                {
                  id:1517,
                  name: 'Кав‘ярня "Coffee Beans 2"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14/2  (ТЦ "Атлант")',
                  foto: [beanse],              
                  descrip: [ 'Кава з собою', 'Смачні десерти;', 'Сендвічі'],
                  workTime: ['пн-нд 9:00-21:00', '', ''],
                  tel:'tel:+380634080693',
                  instagram:"https://www.instagram.com/kava_beans/", 
                  location:'https://maps.app.goo.gl/hPvVHEto8Uy39H7k7',             
                },
               
                {
                  id:1518,
                  name: 'Кав‘ярня "Yellow bee"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 5',
                  foto: [yellow],              
                  descrip: [ 'Кава з собою', 'Смачні десерти'],
                  workTime: ['пн-пт 8:00-21:00', 'сб-нд 9:00-21:00', ''],
                  location:'https://maps.app.goo.gl/iYnYGu9ajQU8toa9A',             
                },
                      
              ]         
            },
            {
              id: 152,
              name:'Кафе, Ресторани, Паби',
              foto: kaffe,
              lists: 
              [
                {
                  id:15210, 
                  name: 'Піцерія "Domiano"',
                  status: true,
                  vip: false,
                  address: ' вул. Пономарьова 24 (біля ПромСИЗа)',
                  foto: [domiano],
                  zamovlena: 'https://damiano-pizzabar.ps.me/',
                  descrip:['Неоролітанська піца', 'Самовивіз'],
                  workTime: ['10:00 - 20:00', '', ''], 
                  tel:'tel:+380636107424',
                  location: 'https://maps.app.goo.gl/ivEJpaa3HYqSZTY1A',
                  instagram: 'https://www.instagram.com/damiano.pizzabar'
                }, 
                
                {
                id:15212, 
                name: 'Піцерія "SANCHO PIZZA"',
                status: true,
                vip: false,
                address: ' вул. Доківська 14',
                foto: [sancho],
                descrip:['Найсмачніша піца в Коцюбинському', 'Від класики до екзотики 🍕🍕🍕', 'Доставляємо по всьому Коцюбинському 🛴'],
                zamovlena: 'https://www.sanchopizza.com.ua/',
                workTime: ['пн-нд 11:00 - 21:00', '', ''], 
                tel:'tel:+380688419311',
                location: 'https://maps.app.goo.gl/ipUC5Tqfk6hLz27f7',
                telegram:'tg://resolve?domain=sanchopizza',
                network:'https://www.sanchopizza.com.ua/',
                instagram: 'https://www.instagram.com/sancho_pizza_ua?igsh=dTB6dWM0cGVqenhp'

              },   
                {
                  id:1521,
                  name: 'Ресторан "Гранат"',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 17',
                  foto: [granat],              
                  descrip: [ 'Вишукана кухня сходу', 'Доставка до Вашого дому'],
                  workTime: ['пн-нд: 11:00-23:00', '', ''],
                  tel:'tel:+380669710484',
                  instagram:"https://www.instagram.com/granat_restaurant/", 
                  location:'https://maps.app.goo.gl/7qHKXvpH4hQsuX9F6',

                  
                },
                {
                  id:1522,
                  name: 'Ресторан "Розмарин"',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 2',
                  foto: [rozmarin],              
                  descrip: [ 'Авторстка кухня', ''],
                  workTime: ['пн-нд: 12:00-23:00', '', ''],
                  tel:'tel:+380978433330',
                  location:'https://maps.app.goo.gl/JGwUHhKZQgSndCRy8',

                  
                },
     
                {
                  id:1523, 
                  name: 'Піца ВВ',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 18б',
                  foto: [pizzavv],
                  descrip:['Піца та суші в Коцюбинському', 'Безкоштовна доставка'],
                  // zamovlena: 'https://pizzavv.com.ua/',
                  workTime: ['пн-нд: 11:00-22:00', '', 'без перерви'], 
                  tel:'tel:+380687224242',
                  instagram: 'https://www.instagram.com/pizzavv.com.ua/',
                  location: 'https://maps.app.goo.gl/2VzRPcfawRGTrWGH8',
                  network: 'https://pizzavv.com.ua/',
                 
                }, 
                 {
                  id:1524, 
                  name: 'Паб "Гніздо"',
                  status: true,
                  vip: false,
                  address: 'вулиця Залізнична 2/10В',
                  foto: [gnizdo],
                  descrip:['Крафтове пиво, сидр', 'Бургери, стейки, дитяче меню', 'Спортивні трансляції'],
                  // zamovlena: 'https://pizzavv.com.ua/',
                  workTime: ['пн-нд: 12:00-23:00', '', 'без перерви'], 
                  tel:'tel:+380638908844',
                  instagram: 'https://www.instagram.com/gnizdo.pub/',
                  location: 'https://maps.app.goo.gl/hyVFs8Ca68FLSPEX7',
                  network: 'https://gnizdopub.choiceqr.com/',
                 
                }, 
                {
                  id:1525, 
                  name: 'Чебуреки "ЛеЧебурек"',
                  status: true,
                  vip: false,
                  address: 'вулиця Залізнична 2/37',
                  foto: [lacheburec],
                  descrip:['Найсмачніші чебуреки та Фрі Меню'],
                  workTime: ['пн-нд: 11:00-21:00', '', 'без перерви'], 
                  tel:'tel:+380969620090',
                  instagram: 'https://www.instagram.com/le_cheburek/',
                  location: 'https://maps.app.goo.gl/JQKQ2vFwdvPGUw3f6',
                  network: 'https://lecheburek.com.ua/',              
                }, 
                {
                  id:1526, 
                  name: 'Суші "Insta Sushi Ktsb"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14',
                  foto: [instasushi],
                  descrip:['Можливо забрати з собою', 'Є доставка '],
                  workTime: ['пн-нд: 12:00-20:45', '', ''], 
                  tel:'tel:+380637886184',
                  instagram: 'https://www.instagram.com/instasushi_ktsb/',
                  location: 'https://maps.app.goo.gl/GqKBcfmHyNTHeSay8',
                  network: 'https://insta-sushi.choiceqr.com/',              
                }, 
                {
                  id:1527, 
                  name: 'Суші "Sushi Story"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 10 корп. 8',
                  foto: [sushistory],
                  descrip:['Можливо забрати з собою', 'Є доставка '],
                  workTime: ['пн-нд: 10:00-22:00', '', ''], 
                  tel:'tel:+380931702444',
                  instagram: 'https://www.instagram.com/sushistoryukraine/',
                  location: 'https://maps.app.goo.gl/UiXUd4qDiVXj6vka9',
                  network: 'https://ua.sushistory.com/kotsyubinske',              
                },  
                {
                  id:1528, 
                  name: 'Кафе "Бо Смачно"',
                  status: true,
                  vip: false,
                  address: ' вул. Доківська 2г',
                  foto: [bosmachno],
                  descrip:['Магазин готової їжі', 'Комплексні обіди'],
                  workTime: ['пн-нд: 7:00-21:00', '', ''], 
                  tel:'tel:+380991885573',
                  location: 'https://maps.app.goo.gl/h7HuZqkwP4aGaJMp6',
                  instagram: 'https://www.instagram.com/_bo_smachno_/'
                }, 
                {
                  id:1529, 
                  name: 'Шаурма & Гриль "Ірпінська на вугіллі"',
                  status: true,
                  vip: false,
                  address: ' вул. Залізнична 1',
                  foto: [irpiskanavugilli],
                  descrip:['Найсмачніша шаурма нашого міста', 'Самовивіз'],
                  workTime: ['', '', ''], 
                  tel:'tel:+380506664013',
                  location: 'https://maps.app.goo.gl/Z7sonwzTnje8NKCBA',
                  instagram: 'https://www.instagram.com/irpinska_na_vugilli/'
                },     
                
                // {
                //   id: 15211, 
                //   name: 'Територія  смаку',
                //   // moremenu:'Перейти до меню',
                //   get:'https://67083b4e8e86a8d9e42e6ee7.mockapi.io/food/teritiriasmakua1',
                //   status: true,
                //   vip: false,
                //   address: 'вул. Пономарьова 26 корпус 1',
                //   foto: [teritiriasmaku],
                //   descrip:['Магазин смачної їжі', 'Домашня кухня'],
                //   zamovlena: 'tel:+380682303208',
                //   workTime: ['пн-нд: 10:00-21:00', '', 'без перерви'], 
                //   tel:'tel:+380682303208',
                //   location: 'https://maps.app.goo.gl/oTe9wzoqDST9Jpav6',
                //   instagram: 'https://www.instagram.com/teryrotiya_smaku_kcb/'
                // },                          
              ]         
            },
            
          ]
        },
        { 
          id: 16,
          name: 'Лікарні, поліклініки, аптеки ',
          foto: medecina,
           types: 
          [
            {
              id: 161,
              name:'Аптеки',
              foto: apteka,
              lists: 
              [
                {
                  id:1611,
                  name: 'Аптека "Сила природы"',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 26 корпус 5',
                  foto: [silaprirodi],              
                  descrip: [ 'Широкий асортимент медичних товарів', 'Оптимальні ціни'],
                  workTime: ['пн-нд: 08:00-20:00', '', ''],
                  tel:'tel:+380960352127',
                  location:"https://maps.app.goo.gl/jtX1Q8oyuTUCYepB8", 
           
                },
                {
                  id:1612,
                  name: 'Аптека "Подорожник"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 1',
                  foto: [podorojnik],              
                  descrip: [ 'Широкий асортимент медичних товарів', 'Оптимальні ціни', 'Кур\'єрська доставка додому', 'Зробити онлайн замовлення.'],
                  workTime: ['пн-нд: 08:00-20:00', '', ''],
                  tel:'tel:+380800303111',
                  location:"https://maps.app.goo.gl/qtYhF4R7oo93JxcS6",
                  network:'https://podorozhnyk.ua/', 
           
                },
                {
                  id:1612,
                  name: 'Аптека "1 Соціальна аптека"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 5В',
                  foto: [socialna1],              
                  descrip: [ 'Найширший асортимент', 'Можливість резервування ліків в режимі онлайн', 'Високу якість сервісного обслуговування'],
                  workTime: ['пн-нд: 07:30-21:30', '', ''],
                  tel:'tel:+380800303111',
                  location:"https://maps.app.goo.gl/cohoL5NR79po12Wm9",
                  network:'https://1sa.com.ua/', 
           
                },
                {
                  id:1613,
                  name: 'Аптека "1 Соціальна аптека"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14',
                  foto: [socialna2],              
                  descrip: [ 'Найширший асортимент', 'Можливість резервування ліків в режимі онлайн', 'Високу якість сервісного обслуговування'],
                  workTime: ['пн-нд: 07:30-21:30', '', ''],
                  tel:'tel:+380800303111',
                  location:"https://maps.app.goo.gl/aGF6BzDmxo4TTFCLA",
                  network:'https://1sa.com.ua/', 
           
                },
                
                {
                  id:1615,
                  name: 'Аптека 36,6',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 6/2',
                  foto: [ambulatoria],              
                  descrip: [ 'Найширший асортимент', 'Високу якість сервісного обслуговування'],
                  workTime: ['пн-пт: 8:00-18:00', 'сб: 9:00-14:00', 'нд: вихідний'],
                  location:"https://maps.google.com/maps?daddr=50.49231,30.32863",
           
                },
                {
                  id:1616,
                  name: 'Бюджетна Аптека',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 5В',
                  foto: [apteka],              
                  descrip: [ 'Найширший асортимент', 'Високу якість сервісного обслуговування'],
                  workTime: ['пн-нд 8:00 - 20:00', '', ''],
                  location:"https://maps.google.com/maps?daddr=50.4887,30.33427",
           
                },
                  
              ]         
            },
            {
              id: 162,
              name:'Лікарні',
              foto: bolnica,
              lists: 
              [
                {
                  id:1623,
                  name: 'Швидка Допомога Коцюбинського',
                  status: true,
                  vip: false,
                  address: 'Коцюбинське',
                  foto: [dopomoga],              
                  descrip: [  'Швидка Медична допомога в Коцюбинському', ],
                  workTime: ['пн-нд: 00:00-24:00', '', ''],
                  tel:'tel:+380456310051', 
                  location:"https://maps.app.goo.gl/MDLvMWWc7A1i1x4X6",
                
                },
                {
                  id:1621,
                  name: 'Медичний центр "Олтім Клінік"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14/1',
                  foto: [oltim],              
                  descrip: [  'Медична допомога вдома', 'Діагностичні послуги, денний стаціонар'],
                  workTime: ['пн-пт: 08:00-20:00', 'сб: 09:00-16:00', 'нд: вихідний'],
                  tel:'tel:+380952408003',
                  instagram: 'https://www.instagram.com/oltimclinic/',
                  location:"https://maps.app.goo.gl/yVWLQAjVUCaCSNyM8",
                  network:'https://www.oltimclinic.com/', 
           
                },
                {
                  id:1622,
                  name: 'Коцюбинська АЗПСМ',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 6/2',
                  foto: [ambulatoria],              
                  descrip: ['Коцюбинська амбулаторія загальної практики - сімейної медицини'],
                  workTime: ['пн-пт: 08:30-18:00', 'сб: 09:00-14:00', 'нд: вихідний'],
                  tel:'tel:+380459772785',
                  tel1:'tel:+380982585158',
                  tel2:'tel:+380932627800',
                  location:"https://maps.app.goo.gl/t1Dbh5s8xH5sjnP37",
                  network:'http://irpinmed.com.ua/ua/1724-kocyubinska-ambulatoriya-zpsm-kz-irpinskiy-miskiy-centr-pervinnoi-mediko-sanitarnoi-dopomogi', 
           
                },
                                     
              ]         
            },
            
            {
              id: 163,
              name:'Лаболаторії',
              foto: labolatoria,
              lists: 
              [
                {
                  id:1631,
                  name: 'Медицинська лабораторія "Сінево"',
                  status: true,
                  vip: false,
                  address: 'Пономарьова 26 корп. 5',
                  foto: [sinevo],              
                  descrip: ['Комфортні умови здачі аналізів', 'Сучасне лабораторне обладнання'],
                  workTime: ['пн-пт: 07:00-13:00', 'сб: 07:00-12:00', 'нд: вихідний'],
                  tel:'tel:+380800605500',
                  instagram:'https://www.instagram.com/synevo_ukraine/',
                  location:"https://maps.app.goo.gl/zjPtNzDLNz4mQCCy7",
                  network:'https://www.synevo.ua/ua', 

                  
           
                },
                {
                  id:1632,
                  name: 'Медицинська лабораторія "Діла"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14',
                  foto: [dila],              
                  descrip: ['Комфортні умови здачі аналізів', 'Сучасне лабораторне обладнання'],
                  workTime: ['пн-пт: 07:00-15:00', 'сб-нд: 08:00-13:00', ''],
                  tel:'tel:+380800217887',
                  instagram:'https://www.instagram.com/dila_ukraine/',
                  location:"https://maps.app.goo.gl/KVA3XtA8M4op5wgF8",
                  network:'https://dila.ua/', 
           
                },
                {
                  id:1621,
                  name: 'Медичний центр "Олтім Клінік"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14/1',
                  foto: [oltim],              
                  descrip: [  'Медична допомога вдома', 'Сучасне лабораторне обладнання'],
                  workTime: ['пн-пт: 08:00-20:00', 'сб: 09:00-16:00', 'нд: вихідний'],
                  tel:'tel:+380952408003',
                  instagram: 'https://www.instagram.com/oltimclinic/',
                  location:"https://maps.app.goo.gl/yVWLQAjVUCaCSNyM8",
                  network:'https://www.oltimclinic.com/', 
           
                },
              ]         
            },
            {
              id: 164,
              name:'Стоматології',
              foto: stomatologia,
              lists: 
              [
                {
                  id:1641,
                  name: 'Стоматологія "Smile plus"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 9',
                  foto: [smileplus],              
                  descrip: ['Стоматологія в Коцюбинському'],
                  workTime: ['пн-пт: 09:00-19:00', 'сб-нд: 09:00-15:00', ''],
                  tel:'tel:+380986491771',
                  location:"https://maps.app.goo.gl/Lo6ELV8YCtRpCWHu6",
      
           
                },
                {
                  id:1642,
                  name: 'Стоматологія "ZubR" ',
                  status: true,
                  vip: false,
                  address: 'вул. Вокзальна 18',
                  foto: [zubr],
                  descrip: ['Стоматологія в Коцюбинському'],
                  workTime: ['пн-пт: 08:00-20:00', 'сб: 10:00-16:00', 'нд: вихідний'],
                  tel:'tel:+380939128788',
                  location: 'https://maps.app.goo.gl/Mu8nRxGPvmenCDCT9' 
                }, 
                {
                  id:1643,
                  name: 'Медичний центр "Олтім Клінік"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14/1',
                  foto: [oltim],              
                  descrip: [  'Медична допомога вдома', 'Сучасне лабораторне обладнання', 'Стоматологія'],
                  workTime: ['пн-пт: 08:00-20:00', 'сб: 09:00-16:00', 'нд: вихідний'],
                  tel:'tel:+380952408003',
                  instagram: 'https://www.instagram.com/oltimclinic/',
                  location:"https://maps.app.goo.gl/yVWLQAjVUCaCSNyM8",
                  network:'https://www.oltimclinic.com/', 
           
                },
                      
              ]         
            },
          ]
        },
        { 
          id: 17,
          name: 'Пошта',
          foto: post,
           types: 
          [
            {
              id: 171,
              name:'Укрпошта',
              // foto: ukrpost,
              lists: 
              [
                {
                  id:1711,
                  name: 'Відділення №08298 ',
                  status: true,
                  vip: false,
                  address: 'вулиця Доківська 9',
                  foto: [ukrposts],
                  descrip: ['Індекс: 08298'],
                  workTime: ['пн-пт: 08:00-20:00','сб-нд: 09:00-19:00' , '','Обід: без обіду'], 
                  tel:'tel:+380800300545',
                  location:'https://maps.app.goo.gl/hSVaMhgu8bSM9oAY6', 
                  network:'https://www.ukrposhta.ua/ua',
                },
                {
                  id:1712,
                  name: 'Відділення №08287 ',
                  status: true,
                  vip: false,
                  address: 'вулиця Бакала 44г',
                  foto: [],
                  descrip: ['Індекс: 08287'],
                  workTime: ['вт-пт: 8:00-17:00','сб: 9:00-18:00' , 'пн-нд: вихідний','Обід: 13:00-14:00'], 
                  tel:'tel:+380800300545',
                  location:'https://maps.app.goo.gl/CR5CDkLWTJ5Jq4uZ7', 
                  network:'https://www.ukrposhta.ua/ua',
                },
                      
              ]         
            },
            {
              id: 172,
              name:'Нова Пошта',
              // foto: novapost,
              lists: 
              [
                {
                  id:1721,
                  name: 'Відділення №1 ',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 13м',
                  foto: [vidilenia1],
                  descrip: ['Вантажне віділення до 1100кг', 'Обмеж. за габар.(см): 170x170x300 ', 'Цифрова адреса: 1559/1',],
                  workTime: ['пн-пт: 8:00-20:00', '', 'сб-нд: 9:00-19:00'], 
                  tel:'tel:+380984500609',
                  location:'https://maps.app.goo.gl/5FqQter1c4xSCdCT9', 
                  network:'https://novaposhta.ua/',
                },
                {
                  id:1722,
                  name: 'Відділення №2 ',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 5Б',
                  foto: [vidilenia2],
                  descrip: ['Обмеження ваги до 30кг', 'Обмеж. за габар.(см): 120x120x120 ', 'Цифрова адреса: 1559/2',],
                  workTime: ['пн-пт: 8:00-20:00', '', 'сб-нд: 9:00-19:00'], 
                  tel:'tel:+380984500609',
                  location:'https://maps.app.goo.gl/uj1aGCu7ykhegnHu9', 
                  network:'https://novaposhta.ua/',
                },
        
                {
                  id:1723,
                  name: 'Відділення №3 ',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14 ',
                  foto: [vidilenia3],
                  descrip: ['Обмеження ваги до 15кг', 'Обмеж. за габар.(см):  70x70x70 ', 'Цифрова адреса: 1559/2',],
                  workTime: ['пн-пт: 8:00-20:00', '', 'сб-нд: 9:00-19:00'], 
                  tel:'tel:+380984500609',
                  location:'https://maps.app.goo.gl/fi3xkMgwYsA4oj9d8', 
                  network:'https://novaposhta.ua/',
                },
                {
                  id:1724,
                  name: 'Поштомат №20825 ',
                  status: true,
                  vip: false,
                  address: "вул. Доківська 14, в клієнт. зоні від. №3 ",
                  foto: [vidilenia3],
                  descrip: ['Обмеження ваги до 20кг', 'Обмеж. за габар.(см):  40x30x60 ', 'Цифрова адреса: 1559/20825',],
                  workTime: ['пн-пт: 8:00-20:00', '', 'сб-нд: 9:00-19:00'], 
                  tel:'tel:+380984500609',
                  location:'https://maps.app.goo.gl/fi3xkMgwYsA4oj9d8', 
                  network:'https://novaposhta.ua/',
                },
                {
                  id:1725,
                  name: 'Поштомат №20899 ',
                  status: true,
                  vip: false,
                  address: "вул. Пономарьова 13м, в клієнт. зоні від. №1 ",
                  foto: [vidilenia1],
                  descrip: ['Обмеження ваги до 20кг', 'Обмеж. за габар.(см):  40x30x60 ', 'Цифрова адреса: 1559/20899',],
                  workTime: ['пн-пт: 8:00-20:00', '', 'сб-нд: 9:00-19:00'], 
                  tel:'tel:+380984500609',
                  location:'https://maps.app.goo.gl/5FqQter1c4xSCdCT9', 
                  network:'https://novaposhta.ua/',
                },
                {
                  id:1726,
                  name: 'Поштомат №23352 ',
                  status: true,
                  vip: false,
                  address: "вул. Доківська 14/2 (ТЦ АТЛАНТ, 2-й поверх, біля маг. Watsons)",
                  foto: [watsons],
                  descrip: ['Обмеження ваги до 20кг', 'Обмеж. за габар.(см):  40x30x60 ', 'Цифрова адреса: 1559/23352',],
                  workTime: ['пн-пт: 08:00-22:00', '', 'сб-нд: 08:00-22:00'], 
                  tel:'tel:+380984500609',
                  location:'https://maps.app.goo.gl/sdwSbQGiuBjDP1VRA',
                  network:'https://novaposhta.ua/',
                },
                {
                  id:1727,
                  name: 'Поштомат №23354 ',
                  status: true,
                  vip: false,
                  address: "вул. Пономарьова 28а (магазин Бджілка) ",
                  foto: [bdjilka],
                  descrip: ['Обмеження ваги до 20кг', 'Обмеж. за габар.(см):  40x30x60 ', 'Цифрова адреса: 1559/23354',],
                  workTime: ['пн-пт: 08:00-22:00', '', 'сб-нд: 08:00-22:00'], 
                  tel:'tel:+380984500609',
                  location:'https://maps.app.goo.gl/r6yenixeHihHcQ7a7', 
                  network:'https://novaposhta.ua/',
                },
                {
                  id:1728,
                  name: 'Поштомат №26826 ',
                  status: true,
                  vip: false,
                  address: "вул. Пономарьова, 26, корпус 1",
                  foto: [pon261],
                  descrip: ['Обмеження ваги до 20кг', 'Обмеж. за габар.(см):  40x30x60 ', 'Цифрова адреса: 1559/26826',],
                  workTime: ['пн-пт: 00:01-23:59', '', 'сб-нд: 00:01-23:59'], 
                  tel:'tel:+380984500609',
                  location:'https://maps.app.goo.gl/tPFTkDtwTvRSqvkL6', 
                  network:'https://novaposhta.ua/',
                },
                {
                  id:1729,
                  name: 'Поштомат №34009 ',
                  status: true,
                  vip: false,
                  address: "вул. Пономарьова, 26 (маг. Фора) ",
                  foto: [forapon26],
                  descrip: ['Обмеження ваги до 20кг', 'Обмеж. за габар.(см):  40x30x60 ', 'Цифрова адреса: 1559/34009',],
                  workTime: ['пн-пт: 08:00-22:00', '', 'сб-нд: 08:00-22:00'], 
                  tel:'tel:+380984500609',
                  location:'https://maps.app.goo.gl/eJUBy5jRz4gcRu5U9', 
                  network:'https://novaposhta.ua/',
                },
                {
                  id:17211,
                  name: 'Поштомат №35114 ',
                  status: true,
                  vip: false,
                  address: "вул. Пономарьова 2/2 (ЖК вуличний) ",
                  foto: [ponomareva2],
                  descrip: ['Обмеження ваги до 20кг', 'Обмеж. за габар.(см):  40x30x60 ', 'Цифрова адреса: 1559/35114',],
                  workTime: ['пн-пт: 00:01-23:59', '', 'сб-нд: 00:01-23:59'],
                  tel:'tel:+380984500609',
                  location:"https://maps.app.goo.gl/Zucc1CAxtuYejeW6A", 
                  network:'https://novaposhta.ua/',
                },
                {
                  id:17212,
                  name: 'Поштомат №35443 ',
                  status: true,
                  vip: false,
                  address: "вул. Доківська, 5б, біля відділення №2",
                  foto: [vidilenia2],
                  descrip: ['Обмеження ваги до 20кг', 'Обмеж. за габар.(см):  40x30x60 ', 'Цифрова адреса: 1559/35443',],
                  workTime: ['пн-пт: 00:01-23:59', '', 'сб-нд: 00:01-23:59'],
                  tel:'tel:+380984500609',
                  location:"https://maps.app.goo.gl/uj1aGCu7ykhegnHu9", 
                  network:'https://novaposhta.ua/',
                },
                {
                  id:17213,
                  name: 'Поштомат №36788',
                  status: true,
                  vip: false,
                  address: "вул. Доківська, 1 (магазин EVA)",
                  foto: [eva],
                  descrip: ['Обмеження ваги до 20кг', 'Обмеж. за габар.(см):  40x30x60 ', 'Цифрова адреса: 1559/35443',],
                  workTime: ['пн-пт: 08:00-21:00', '', 'сб-нд: 08:00-21:00'],
                  tel:'tel:+380984500609',
                  location:"https://maps.app.goo.gl/cCFu5h7CyFCA3Uq29", 
                  network:'https://novaposhta.ua/',
                },
                {
                  id:17214,
                  name: 'Поштомат №40060',
                  status: true,
                  vip: false,
                  address: " вул. Пономарьова, 26к3 (маг. Біле та Сухе) ",
                  foto: [bilesuxe],
                  descrip: ['Обмеження ваги до 20кг', 'Обмеж. за габар.(см):  40x30x60 ', 'Цифрова адреса: 1559/40060',],
                  workTime: ['пн-пт: 08:00-23:00', '', 'сб-нд: 08:00-23:00'],
                  tel:'tel:+380984500609',
                  location:"https://maps.app.goo.gl/aZMTue5W1kBRZRhTA", 
                  network:'https://novaposhta.ua/',
                },
                      
              ]         
            },
            {
              id: 173,
              name:'Rozetka',
              // foto: rozetka,
              lists: 
              [
                {
                  id:1731,
                  name: 'Доківська 1 ',
                  status: true,
                  vip: false,
                  address: 'вулиця Доківська 1',
                  foto: [rozetkavid],
                  descrip: ['Парковка', 'Примірочні', 'Алкогольний відділ'],
                  workTime: ['пн-сб: 9:00-20:00', 'нд: 10:00-19:00',],
                  location:'https://maps.app.goo.gl/Kp33miZNN9NJBZyJ8', 
                  network:'https://rozetka.com.ua/',
                },
                      
              ]         
            },
          ]
        },
        { 
          id: 18,
          name: 'Охорона та безпека',
          foto: oxorona,
           types: 
          [
            {
              id: 181,
              name:'Відділення поліції',
              foto: police,
              lists: 
              [
                {
                  id:1811,
                  name: 'Відділення Поліції ',
                  status: true,
                  vip: false,
                  foto: [policeoffice],
                  descrip: ['Віділення поліції Коцюбинського', 'Дільничий Коцюбинського'],
                  workTime: ['', '',], 
                  tel:'tel:+380459771340',
                  network:'https://kv.npu.gov.ua/',
                
                },
                      
              ]         
            },
            {
              id: 182,
              name:'Охоронні компанії',
              foto: oxrana,
              lists: 
              [
                {
                  id:1821,
                  name: 'Управління Поліція Охорони УПО ',
                  status: true,
                  vip: false,
                 
                  foto: [policeohorona],
                  descrip: ['Управління Поліція Охорони', 'Замовлення охорони приміщень'],
                  workTime: ['', '',], 
                  tel:'tel:+380442870477',
               
                  network:'https://kv.npu.gov.ua/',
                },
                {
                  id:1822,
                  name: 'Охоронна компанія "SHERIFF" ',
                  status: true,
                  vip: false,
                  foto: [sheriff],
                  address: 'вул. Сосницька 1/44',
                  descrip: ['Охорона квартир та приміщень', 'Фізична охорона об\'єктів', 'Мобільна тривожна кнопка SHERIFF SOS'],
                  workTime: ['', '',], 
                  tel:'tel:+380800201446',
                  instagram:'https://www.instagram.com/sheriff_ohorona/',
                  location:'https://maps.app.goo.gl/YE5i67VuUiA7BEkU9', 
                  network:'https://sheriff.com.ua/uk',
                },
                      
              ]         
            },
           
            
            
          ]
        },
        { 
          id: 19,
          name: 'Адмін.будів. ЦНАП',
          foto: cnap,
           types: 
          [
            {
              id: 191,
              name:'Коцюбинський ЦНАП',
              foto: cnap,
              lists: 
              [
                {
                  id:1911,
                  name: 'Коцюбинський "ЦНАП" ',
                  status: true,
                  vip: false,
                  foto: [cnapkotsuba],
                  address: 'вул. Доківcька 2',
                  descrip: ['Надання адміністративних послуг'],
                  workTime: ['пн-чт: 8:30-16:30', 'пт: 8:30-15:45','перерва: 12:00-12:45'], 
                  tel:'tel:+380459795244',
                  tel1:'tel:+380459795244',
                  tel2:'tel:+380459795237',
                  tel3:'tel:+380459795246',
                  location:"https://maps.app.goo.gl/4gYctURCeLudNX53A", 
                  network:'https://vulyk.gov.ua/kocobynske',

                  
                },
                
                
                      
              ]         
            },
          ]
        },
        { 
          id: 20,
          name: 'Все для тварин',
          foto: zoo,
           types: 
          [
            {
              id: 2011,
              name:'Зоомагазини',
              foto: zoomagazin,
              lists: 
              [
                {
                  id:1431,
                  name: 'Зоомагазин "Fluffy Pet"',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 26 корп. 3',
                  foto: [zoomagzatlant2],              
                  descrip: ['Зоомагазин товарів:', 'корм для кішок, собак, птахів', 'наповнювачі всіх видів', 'іграшки для улюбленців'],
                  workTime: ['пн-нд: 10:00-20:00', ' ', ''],
                  instagram: 'https://www.instagram.com/_fluffy_pet_shop_/',
                  location:"https://maps.app.goo.gl/1hfoHoDyAnn7NUwQ6", 
           
                },
                {
                  id:2012,
                  name: 'ЗооМагазин Мурка',
                  status: true,
                  vip: false,
                  address: 'вул. Залізнична 2/29',
                  foto: [zoomurka],              
                  descrip: ['Зоомагазин товарів:', 'корм для кішок, собак, птахів', 'наповнювачі всіх видів', 'іграшки для улюбленців', 'Доставка'],
                  workTime: ['пн-нд: 9:00-19:00', ' ', ''],
                  tel:'tel:+380984722040',
                  location:"https://maps.app.goo.gl/79u4Z7v4vaYJXoQn6", 
           
                },
                {
                  id:2013,
                  name: 'ЗооМагазин Мурка 2',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 14',
                  foto: [zoomurka],              
                  descrip: ['Зоомагазин товарів:', 'корм для кішок, собак, птахів', 'наповнювачі всіх видів', 'іграшки для улюбленців', 'Доставка'],
                  workTime: ['пн-нд: 9:00-19:00', ' ', ''],
                  tel:'tel:+380984722040',
                  location:"https://maps.app.goo.gl/2Po9oc8Nis3t77zC7", 
           
                },
                {
                  id:2014,
                  name: 'Зоомагазин "Zoo-Club"',
                  status: true,
                  vip: false,
                  address: 'вул. Залізнична 2/19',
                  foto: [zooclub],              
                  descrip: ['Зоомагазин товарів:', 'корм для кішок, собак, птахів', 'наповнювачі всіх видів', 'іграшки для улюбленців', 'Доставка'],
                  workTime: ['пн-нд: 8:00-20:00', ' ', ''],
                  tel:'tel:+380638086339',
                  location:"https://maps.app.goo.gl/FU5pFfw9sebGaXCt8", 
           
                },
                      
              ]         
            },
            {
              id: 202,
              name:'Грумінг',
              foto: gruming,
              lists: 
              [
                {
                  id:2021,
                  name: 'Анастасія',
                  status: true,
                  vip: false,
                  address: '',
                  foto: [gruming],              
                  descrip: ['Грумінг для домашніх улюбленців', '', '', '', ''],
                  workTime: ['', '', ''],
                  tel:'tel:+380503567167',
                  viber: 'viber://chat?number=+380503567167',
                
           
                },
                {
                  id:2022,
                  name: 'Вікторія',
                  status: true,
                  vip: false,
                  address: '',
                  foto: [gruming],              
                  descrip: ['Грумінг для домашніх улюбленців', '', '', '', ''],
                  workTime: ['', '', ''],
                  tel:'tel:+380509017247',
                  viber: 'viber://chat?number=+380509017247',
                
           
                },
                
                
                      
              ]         
            },
            {
              id: 203,
              name:'Ветеренарні Клініки',
              foto: vetklinika,
              lists: 
              [
                {
                  id:2031,
                  name: 'Ветеринарна клініка "UDVET"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 7А',
                  foto: [undvet],              
                  descrip: ['Сучасна та надійна ветеринарна клініка', '', '', '', ''],
                  workTime: ['пн-пт: 10:00-20:00', 'сб-вс: 10:00-19:00 ', ''],
                  tel:'tel:+380990327232',
                  location:"https://maps.app.goo.gl/WKFKfkHYNjGoiHYs6", 
           
                },
                
                      
              ]         
            },
          ]
        },
            { 
          id: 21,
          name: 'Громадський транспорт',
           foto: transport,
           types: 
          [
            {
              id: 211,
              name:'Маршрутне таксі ',
              foto: marshrutka,
              lists: 
              [
                {
                  id:2111,
                  name: 'Маршрутне таксі № 745',
                  status: true,
                  vip: false,
                  moreRozklad: 'Детальний розклад',
                  address: 'ст. Біличі - ст. м. Академмістечко',
                  foto: [marshrutka],              
                  descrip: ['Розклад руху ', 'Інтервал - 5-15 хв.', 'Ціна - 20 грн', '', ''],
                  workTime: ['пн-нд: 05:45-22:50', '', ''],
                  nameblock3:'Розклад руху у робочі дні за маршрутом вул. Доківська - ст. м. Академмістечко',
                  tel:'',
                  location:"https://maps.app.goo.gl/xbThrseb9QhBjCis7", 
                  tabl1:'З Коцюб.:',
                  tabl2:'Час',
                  tabl3:'',
                  tabl4:'З Акад.:',
                  tabl5:'Час',
                  priceservise: [
                    { name: "1", nomer: '05:45', arrival: "", departure: '1', period: ''  },
                    { name: "2", nomer: '06:00', arrival: "", departure: '2', period: '06:30'  },
                    { name: "3", nomer: '06:08', arrival: "", departure: '3', period: '06:38'  },
                    { name: "4", nomer: '06:15', arrival: "", departure: '4', period: '06:45'},
                    { name: "5", nomer: '06:20', arrival: "", departure: '5', period: '06:50'  },
                    { name: "6", nomer: '06:25', arrival: "", departure: '6', period: '06:55'  },
                    { name: "7", nomer: '06:30', arrival: "", departure: '7', period: '07:00'  },
                    { name: "8", nomer: '06:35', arrival: "", departure: '8', period: '07:05'  },
                    { name: "9", nomer: '06:40', arrival: "", departure: '9', period: '07:10'  },
                    { name: "10", nomer: '06:45', arrival: "", departure: '10', period: '07:15' },
                    { name: "11", nomer: '06:50', arrival: "", departure: '11', period: '07:20' },
                    { name: "12", nomer: '06:55', arrival: "", departure: '12', period: '07:25'  },
                    { name: "13", nomer: '07:00', arrival: "", departure: '13', period: '07:30'  },

                    { name: "14", nomer: '07:05', arrival: "", departure: '14', period: '07:35'  },
                    { name: "15", nomer: '07:10', arrival: "", departure: '15', period: '07:40'  },
                    { name: "16", nomer: '07:15', arrival: "", departure: '16', period: '07:45'  },
                    { name: "17", nomer: '07:20', arrival: "", departure: '17', period: '07:50'  },
                    { name: "18", nomer: '07:25', arrival: "", departure: '18', period: '07:55'  },
                    { name: "19", nomer: '07:30', arrival: "", departure: '19', period: '08:00'  },
                    { name: "20", nomer: '07:35', arrival: "", departure: '20', period: '08:05'  },
                    { name: "21", nomer: '07:40', arrival: "", departure: '21', period: '08:10'  },
                    { name: "22", nomer: '07:45', arrival: "", departure: '22', period: '08:15'  },
                    { name: "23", nomer: '07:50', arrival: "", departure: '23', period: '08:20'  },
                    { name: "24", nomer: '07:55', arrival: "", departure: '24', period: '08:25'  },
                    { name: "25", nomer: '08:00', arrival: "", departure: '25', period: '08:30'  },

                    { name: "26", nomer: '08:05', arrival: "", departure: '26', period: '08:35'  },
                    { name: "27", nomer: '08:10', arrival: "", departure: '27', period: '08:40'  },
                    { name: "28", nomer: '08:15', arrival: "", departure: '28', period: '08:45'  },
                    { name: "29", nomer: '08:20', arrival: "", departure: '29', period: '08:50'  },
                    { name: "30", nomer: '08:25', arrival: "", departure: '30', period: '08:55'  },
                    { name: "31", nomer: '08:30', arrival: "", departure: '31', period: '09:00'  },
                    { name: "32", nomer: '08:35', arrival: "", departure: '32', period: '09:05'  },
                    { name: "33", nomer: '08:40', arrival: "", departure: '33', period: '09:10'  },
                    { name: "34", nomer: '08:45', arrival: "", departure: '34', period: '09:15'  },
                    { name: "35", nomer: '08:50', arrival: "", departure: '35', period: '09:20'  },
                    { name: "36", nomer: '08:55', arrival: "", departure: '36', period: '09:25'  },
                    { name: "37", nomer: '09:00', arrival: "", departure: '37', period: '09:30'  },


                    { name: "38", nomer: '09:07', arrival: "", departure: '38', period: '09:37'  },
                    { name: "39", nomer: '09:14', arrival: "", departure: '39', period: '09:44'  },
                    { name: "40", nomer: '09:21', arrival: "", departure: '40', period: '09:51'  },
                    { name: "41", nomer: '09:28', arrival: "", departure: '41', period: '09:58'  },
                    { name: "42", nomer: '09:35', arrival: "", departure: '42', period: '10:05'  },
                    { name: "43", nomer: '09:42', arrival: "", departure: '43', period: '10:12'  },
                    { name: "44", nomer: '09:50', arrival: "", departure: '44', period: '10:20'  },
                    { name: "45", nomer: '09:58', arrival: "", departure: '45', period: '10:28'  },

                    { name: "46", nomer: '10:06', arrival: "", departure: '46', period: '10:36'  },
                    { name: "47", nomer: '10:14', arrival: "", departure: '47', period: '10:44'  },
                    { name: "48", nomer: '10:22', arrival: "", departure: '48', period: '10:52'  },
                    { name: "49", nomer: '10:30', arrival: "", departure: '49', period: '11:00'  },
                    { name: "50", nomer: '10:38', arrival: "", departure: '50', period: '11:08'  },
                    { name: "51", nomer: '10:46', arrival: "", departure: '51', period: '11:16'  },
                    { name: "52", nomer: '10:54', arrival: "", departure: '52', period: '11:24'  },

                    
                    { name: "53", nomer: '11:10', arrival: "", departure: '53', period: '11:40'  },
                    { name: "54", nomer: '11:20', arrival: "", departure: '54', period: '11:50'  },
                    { name: "55", nomer: '11:30', arrival: "", departure: '55', period: '12:00'  },
                    { name: "56", nomer: '11:40', arrival: "", departure: '56', period: '12:10'  },
                    { name: "57", nomer: '11:50', arrival: "", departure: '57', period: '12:40'  },
                    { name: "58", nomer: '12:00', arrival: "", departure: '58', period: '13:10'  },

                    { name: "59", nomer: '12:10', arrival: "", departure: '59', period: '12:40'  },
                    { name: "60", nomer: '12:20', arrival: "", departure: '60', period: '12:50'  },
                    { name: "61", nomer: '12:32', arrival: "", departure: '61', period: '13:02'  },
                    { name: "62", nomer: '12:44', arrival: "", departure: '62', period: '13:14'  },
                    { name: "63", nomer: '12:56', arrival: "", departure: '63', period: '13:26'  },

                    { name: "64", nomer: '13:08', arrival: "", departure: '64', period: '13:38'  },
                    { name: "65", nomer: '13:20', arrival: "", departure: '65', period: '13:50'  },
                    { name: "66", nomer: '13:32', arrival: "", departure: '66', period: '14:02'  },
                    { name: "67", nomer: '13:44', arrival: "", departure: '67', period: '14:14'  },
                    { name: "68", nomer: '13:55', arrival: "", departure: '68', period: '14:25'  },

                    { name: "69", nomer: '14:05', arrival: "", departure: '69', period: '14:35'  },
                    { name: "70", nomer: '14:15', arrival: "", departure: '70', period: '14:45'  },
                    { name: "71", nomer: '14:25', arrival: "", departure: '71', period: '14:55'  },
                    { name: "72", nomer: '14:35', arrival: "", departure: '72', period: '15:05'  },
                    { name: "73", nomer: '14:45', arrival: "", departure: '73', period: '15:15'  },
                    { name: "74", nomer: '14:55', arrival: "", departure: '74', period: '15:25'  },

                    { name: "75", nomer: '15:05', arrival: "", departure: '75', period: '15:35'  },
                    { name: "76", nomer: '15:15', arrival: "", departure: '76', period: '15:45'  },
                    { name: "77", nomer: '15:22', arrival: "", departure: '77', period: '15:52'  },
                    { name: "78", nomer: '15:29', arrival: "", departure: '78', period: '15:59'  },
                    { name: "79", nomer: '15:36', arrival: "", departure: '79', period: '16:06'  },
                    { name: "80", nomer: '15:43', arrival: "", departure: '80', period: '16:13'  },
                    { name: "81", nomer: '15:50', arrival: "", departure: '81', period: '16:20'  },
                    { name: "82", nomer: '15:57', arrival: "", departure: '82', period: '16:27'  },

                    { name: "83", nomer: '16:04', arrival: "", departure: '83', period: '16:34'  },
                    { name: "84", nomer: '16:10', arrival: "", departure: '84', period: '16:40'  },
                    { name: "85", nomer: '16:15', arrival: "", departure: '85', period: '16:45'  },
                    { name: "86", nomer: '16:20', arrival: "", departure: '86', period: '16:50'  },
                    { name: "87", nomer: '16:25', arrival: "", departure: '87', period: '16:55'  },
                    { name: "88", nomer: '16:30', arrival: "", departure: '88', period: '17:00'  },
                    { name: "89", nomer: '16:35', arrival: "", departure: '89', period: '17:05'  },
                    { name: "90", nomer: '16:40', arrival: "", departure: '90', period: '17:10'  },
                    { name: "91", nomer: '16:45', arrival: "", departure: '91', period: '17:15'  },
                    { name: "92", nomer: '16:50', arrival: "", departure: '92', period: '17:20'  },
                    { name: "93", nomer: '16:55', arrival: "", departure: '93', period: '17:25'  },

                    { name: "94", nomer: '17:00', arrival: "", departure: '94', period: '17:30'  },
                    { name: "95", nomer: '17:05', arrival: "", departure: '95', period: '17:35'  },
                    { name: "96", nomer: '17:10', arrival: "", departure: '96', period: '17:40'  },
                    { name: "97", nomer: '17:15', arrival: "", departure: '97', period: '17:45'  },
                    { name: "98", nomer: '17:20', arrival: "", departure: '98', period: '17:50'  },
                    { name: "99", nomer: '17:25', arrival: "", departure: '99', period: '17:55'  },
                    { name: "100", nomer: '17:30', arrival: "", departure: '100', period: '18:00'  },
                    { name: "101", nomer: '17:35', arrival: "", departure: '101', period: '18:05'  },
                    { name: "102", nomer: '17:40', arrival: "", departure: '102', period: '18:10'  },
                    { name: "103", nomer: '17:45', arrival: "", departure: '103', period: '18:15'  },
                    { name: "104", nomer: '17:50', arrival: "", departure: '104', period: '18:20'  },
                    { name: "105", nomer: '17:55', arrival: "", departure: '105', period: '18:25'  },

                    { name: "106", nomer: '18:00', arrival: "", departure: '106', period: '18:30'  },
                    { name: "107", nomer: '18:05', arrival: "", departure: '107', period: '18:35'  },
                    { name: "108", nomer: '18:10', arrival: "", departure: '108', period: '18:40'  },
                    { name: "109", nomer: '18:15', arrival: "", departure: '109', period: '18:45'  },
                    { name: "110", nomer: '18:20', arrival: "", departure: '110', period: '18:50'  },
                    { name: "111", nomer: '18:25', arrival: "", departure: '111', period: '18:55'  },
                    { name: "112", nomer: '18:30', arrival: "", departure: '112', period: '19:00'  },
                    { name: "113", nomer: '18:35', arrival: "", departure: '113', period: '19:05'  },
                    { name: "114", nomer: '18:40', arrival: "", departure: '114', period: '19:10'  },
                    { name: "115", nomer: '18:45', arrival: "", departure: '115', period: '19:15'  },
                    { name: "116", nomer: '18:50', arrival: "", departure: '116', period: '19:20'  },
                    { name: "117", nomer: '18:55', arrival: "", departure: '117', period: '19:25'  },

                    { name: "118", nomer: '19:00', arrival: "", departure: '118', period: '19:30'  },
                    { name: "119", nomer: '19:06', arrival: "", departure: '119', period: '19:36'  },
                    { name: "120", nomer: '19:12', arrival: "", departure: '120', period: '19:42'  },
                    { name: "121", nomer: '19:18', arrival: "", departure: '121', period: '19:48'  },
                    { name: "122", nomer: '19:24', arrival: "", departure: '122', period: '19:54'  },
                    { name: "123", nomer: '19:32', arrival: "", departure: '123', period: '20:02'  },
                    { name: "124", nomer: '19:40', arrival: "", departure: '124', period: '20:10'  },
                    { name: "125", nomer: '19:48', arrival: "", departure: '125', period: '20:18'  },
                    { name: "126", nomer: '19:56', arrival: "", departure: '126', period: '20:26'  },

                    { name: "127", nomer: '20:04', arrival: "", departure: '127', period: '20:34'  },
                    { name: "128", nomer: '20:12', arrival: "", departure: '128', period: '20:42'  },
                    { name: "129", nomer: '20:20', arrival: "", departure: '129', period: '20:50'  },
                    { name: "130", nomer: '20:30', arrival: "", departure: '130', period: '21:00'  },
                    { name: "131", nomer: '20:40', arrival: "", departure: '131', period: '21:10'  },
                    { name: "132", nomer: '20:55', arrival: "", departure: '132', period: '21:25'  },

                    { name: "133", nomer: '21:10', arrival: "", departure: '133', period: '21:40'  },
                    { name: "134", nomer: '21:25', arrival: "", departure: '134', period: '21:55'  },
                    { name: "135", nomer: '21:40', arrival: "", departure: '135', period: '22:10'  },
                    { name: "136", nomer: '22:00', arrival: "", departure: '136', period: '22:30'  },
                    { name: "137", nomer: '22:20', arrival: "", departure: '137', period: '22:50'  },
                    
                  ]
           
                },
                {
                  id:2112,
                  name: 'Маршрутне таксі № 393',
                  moreRozklad: 'Детальний розклад',
                  status: true,
                  vip: false,
                  address: 'вул. Меблева - ст. м. Академмістечко',
                  foto: [marshrutka],              
                  descrip: ['Розклад руху ', 'Інтервал - 15-30 хв.', 'Ціна - 20 грн', '', ''],
                  workTime: ['пн-нд: 06:00-22:00', '', ''],
                  nameblock3:'Розклад руху за маршрутом вул. Меблева - ст. м. Академмістечко',
                  location:"https://maps.app.goo.gl/t2HiDXi2UNkifbDD9", 
                  tabl1:'З Коцюб.:',
                  tabl2:'Час',
                  tabl3:'',
                  tabl4:'З Акад.:',
                  tabl5:'Час',
                  priceservise: [
                    { name: "1", nomer: '06:00', arrival: "", departure: '1', period: '06:30'  },
                    { name: "2", nomer: '06:30', arrival: "", departure: '2', period: '07:00'  },
                    { name: "3", nomer: '06:45', arrival: "", departure: '3', period: '07:15'  },
                    { name: "4", nomer: '07:00', arrival: "", departure: '4', period: '07:30'},
                    { name: "5", nomer: '07:15', arrival: "", departure: '5', period: '07:45'  },
                    { name: "6", nomer: '07:30', arrival: "", departure: '6', period: '08:00'  },
                    { name: "7", nomer: '07:45', arrival: "", departure: '7', period: '08:15'  },
                    { name: "8", nomer: '08:00', arrival: "", departure: '8', period: '08:30'  },
                    { name: "9", nomer: '08:15', arrival: "", departure: '9', period: '08:45'  },
                    { name: "10", nomer: '08:30', arrival: "", departure: '10', period: '09:00' },
                    { name: "11", nomer: '08:45', arrival: "", departure: '11', period: '09:15' },
                    { name: "12", nomer: '09:00', arrival: "", departure: '12', period: '09:30'  },
                    { name: "13", nomer: '09:15', arrival: "", departure: '13', period: '09:45'  },
                    { name: "14", nomer: '09:30', arrival: "", departure: '14', period: '10:00'  },
                    { name: "15", nomer: '09:45', arrival: "", departure: '15', period: '10:15'  },
                    { name: "16", nomer: '10:00', arrival: "", departure: '16', period: '10:30'  },
                    { name: "17", nomer: '10:20', arrival: "", departure: '17', period: '10:50'  },
                    { name: "18", nomer: '10:40', arrival: "", departure: '18', period: '11:10'  },
                    { name: "19", nomer: '11:00', arrival: "", departure: '19', period: '11:30'  },
                    { name: "20", nomer: '11:20', arrival: "", departure: '20', period: '11:50'  },
                    { name: "21", nomer: '11:40', arrival: "", departure: '21', period: '12:10'  },
                    { name: "22", nomer: '12:00', arrival: "", departure: '22', period: '12:30'  },
                    { name: "23", nomer: '12:20', arrival: "", departure: '23', period: '12:50'  },
                    { name: "24", nomer: '12:40', arrival: "", departure: '24', period: '13:10'  },
                    { name: "25", nomer: '13:00', arrival: "", departure: '25', period: '13:30'  },
                    { name: "26", nomer: '13:20', arrival: "", departure: '26', period: '13:50'  },
                    { name: "27", nomer: '13:40', arrival: "", departure: '27', period: '14:10'  },
                    { name: "28", nomer: '14:00', arrival: "", departure: '28', period: '14:30'  },
                    { name: "29", nomer: '14:20', arrival: "", departure: '29', period: '14:50'  },
                    { name: "30", nomer: '14:40', arrival: "", departure: '30', period: '15:10'  },
                    { name: "31", nomer: '15:00', arrival: "", departure: '31', period: '15:30'  },
                    { name: "32", nomer: '15:20', arrival: "", departure: '32', period: '15:50'  },
                    { name: "33", nomer: '15:40', arrival: "", departure: '33', period: '16:10'  },
                    { name: "34", nomer: '16:00', arrival: "", departure: '34', period: '16:30'  },
                    { name: "35", nomer: '16:20', arrival: "", departure: '35', period: '16:50'  },
                    { name: "36", nomer: '16:40', arrival: "", departure: '36', period: '17:10'  },
                    { name: "37", nomer: '17:00', arrival: "", departure: '37', period: '17:30'  },
                    { name: "38", nomer: '17:15', arrival: "", departure: '38', period: '17:45'  },
                    { name: "39", nomer: '17:30', arrival: "", departure: '39', period: '18:00'  },
                    { name: "40", nomer: '17:45', arrival: "", departure: '40', period: '18:15'  },
                    { name: "41", nomer: '18:00', arrival: "", departure: '41', period: '18:30'  },
                    { name: "42", nomer: '18:15', arrival: "", departure: '42', period: '18:45'  },
                    { name: "43", nomer: '18:30', arrival: "", departure: '43', period: '19:00'  },
                    { name: "44", nomer: '18:45', arrival: "", departure: '44', period: '19:15'  },
                    { name: "45", nomer: '19:00', arrival: "", departure: '45', period: '19:30'  },
                    { name: "46", nomer: '19:15', arrival: "", departure: '46', period: '19:45'  },
                    { name: "47", nomer: '19:30', arrival: "", departure: '47', period: '20:00'  },
                    { name: "48", nomer: '19:45', arrival: "", departure: '48', period: '20:15'  },
                    { name: "49", nomer: '20:00', arrival: "", departure: '49', period: '20:30'  },
                    { name: "50", nomer: '20:20', arrival: "", departure: '50', period: '20:50'  },
                    { name: "51", nomer: '20:40', arrival: "", departure: '51', period: '21:10'  },
                    { name: "52", nomer: '20:40', arrival: "", departure: '52', period: '21:35'  },
                    { name: "53", nomer: '21:00', arrival: "", departure: '53', period: '22:00'  },
                    { name: "54", nomer: '21:30', arrival: "", departure: '', period: ''  },
                    { name: "", nomer: '', arrival: "", departure: '', period: ''  },
                    
                  ]
           
                },
          
              ]         
            },
            {
              id: 212,
              name:'Розклад електричок (приміських поїздів)',
              foto: potiag,
              lists: 
              [
            
                {
                  id:2121,
                  name: 'Парний напрямок (на Святошино)',
                  
                  moreRozkladSait: 'https://poizdato.net/rozklad-poizdiv/bilychi--sviatoshyn/',
                  status: true,
                  vip: false,
                  address: 'станція Біличі',
                  foto: [rozkladpotiga],               
                  descrip: ['Розклад руху приміський поїздів', '', '', '', ''],
                  workTime: ['пн-нд: 06:23-20:58', '', ''],     
                  location:"https://maps.app.goo.gl/cUjHXnQSa3Jye9GG9", 
                  nameblock3:'Розклад руху приміських поїздів на Святошин',
                  tabl1:'Сполуч.:',
                  tabl2:'№ поїзда:',
                  tabl3:'Приб.',
                  tabl4:'Відпр.',
                  tabl5:'Період.',   
                },   
                {
                  id:2122,
                  name: 'Непарний напрямок (на Тетерів, Коростень)',
                  moreRozkladSait: 'https://poizdato.net/rozklad-poizdiv/bilychi--teteriv/',
                  status: true,
                  vip: false,
                  address: 'станція Біличі',
                  foto: [rozkladpotiga],               
                  descrip: ['Розклад руху приміський поїздів', '', '', '', ''],
                  workTime: ['пн-нд: 06:11-20:55', '', ''],
                  nameblock3:'Розклад руху приміських поїздів на Тетерів, Коростень',
                  location:"https://maps.app.goo.gl/cUjHXnQSa3Jye9GG9", 
                  tabl1:'Сполуч.:',
                  tabl2:'№ поїзда:',
                  tabl3:'Приб.',
                  tabl4:'Відпр.',
                  tabl5:'Період.',
                 
                },                  
              ]         
            },
            {
              id: 213,
              name:'Шатл-баси Lavina-Express',
              foto: lavinabus,
              lists: 
              [
            
                {
                  id:2131,
                  name: 'Графік руху Шатл-Басів',
                  status: true,
                  firstvip: 1,
                  vip: false,
                  address: 'Маршрут «Коцюбинське — Lavina»',
                  foto: [karta],
                  descrip: ['Відправка з Коцюбинського від бібліотеки ім. О. Гайдай:  10:00, 12:00, 14:00, 16:00, 18:00, 20:00','Від ТРЦ Lavina:  11:00, 13:00, 15:00, 17:00, 19:00, 21:00, 22:15',
                  ''],
                  workTime: [''],                
                  tel:'tel:+380986690884',
                 location:"https://maps.app.goo.gl/1fFbV52q87Ay4EySA", 
                 
                },   
                                     
              ]         
            },
          ]
        },

        { 
          id:22,
          name: 'Подарунки',
          foto: podarunki,
           types: 
          [
            {
              id: 221,
              name:'Смачні букети і бокси',
              foto: smachnibuketi,
              lists: 
              [
                {
                  id:2211,
                  name: 'Смачні букети Коцюбинське ',
                  status: true,
                  vip: false,
                  foto: [smachnibuketikotsuba],
                  address: 'Коцюбинське',
                  descrip: ["М'ясні, фруктові, сухофруктові, горіхові і солодкі композиції,як оригінальний подарунок, який здивує і подарує незабутні емоції.", "Вартість букету від 450 грн. в залежності від розміру і наповнення."],
                  workTime: ['', '',''], 
                  tel:'tel:+380508625408',
                  instagram:'https://www.instagram.com/tastybouquet2024',
                  telegram:'tg://resolve?domain=Nataliaovov',

                  
                },
                {
                  id:2212,
                  name: 'Солодкі букети /Подарункові бокси ',
                  status: true,
                  vip: false,
                  foto: [solodkibuketi],
                  address: 'вул. Доківська, 14/1, 2 поверх',
                  descrip: ['Магазин "Дарунок" пропонує Вам оригінальні букети та подарункові бокси до різних свят.', 'Оберіть подарунок який точно всіх здивує. Приємні ціни.'],
                  workTime: ['', '',''], 
                  tel:'tel:+380935664060',
                  instagram:'https://www.instagram.com/darunok_havrutenko',
                  tiktok:'https://www.tiktok.com/@havrutenkoyana?_t=8o7xnWMCoxq&_r=1',
               

                  
                },
                
                      
              ]         
            },

            {
              id: 222,
              name:'Квіткові  магазини',
              foto: kvitkovimagaz,
              lists: 
              [
                {
                  id:2221,
                  name: 'Магазин квітів "Подарую тобі..."',
                  status: true,
                  vip: false,
                  address: 'Пономарьова, 26, корпус 3',
                  foto: [podaruutobi],              
                  descrip: [' Покупка в магазині', 'Доставка в день замовлення'],
                  workTime: ['пн-нд: 09:00-20:00', '', ''],
                  instagram: 'https://www.instagram.com/podaruyu__tobi/',
                  tel:'tel:+380964018180',
                  location:"https://maps.app.goo.gl/zcB1XaFqCRo66s8r5", 
           
                },
                {
                  id:2222,
                  name: 'Магазин квітів "Flowers&Coffee"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська, 14/1',
                  foto: [flowerscoffee],              
                  descrip: [ ' Покупка в магазині', 'Доставка в день замовлення'],
                  workTime: ['пн-нд: 09:00-19:00', '', ''],
                  tel:'tel:+380682013911',
                  location:"https://maps.app.goo.gl/55hzDKmwTgyaWhH2A", 
           
                },
                {
                  id:2223,
                  name: 'Магазин кульок та квітів "KyivShar"',
                  status: true,
                  vip: false,
                  address: 'вул. Залізнична 1б',
                  foto: [kyivshar],              
                  descrip: [ 'У магазині Ви можете:', 'Забрати кулі замовлені через сайт', 'Вибрати кульки в магазині', 'Зробити замовлення в магазині та оформити доставкуо',],
                  workTime: ['пн-нд: 09:00-21:00', '', ''],
                  tel:'tel:+380675176849',
                  network: 'https://makemehappy.com.ua/',
                  location:"https://maps.app.goo.gl/Unud2F4RHQA3xak29", 
           
                },
                {
                  id:2224,
                  name: 'Магазин квітів "Sunrise Flowers&Coffee"',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 10, корп 8',
                  foto: [sunrisedokiv],              
                  descrip: [ 'Квіткова кавʼярня в Коцюбинському🌷',
                  'Додаємо ложечку любові в твоє життя'],
                  workTime: ['пн-пт: 08:00-21:00', 'сб-нд: 09:00-21:00', ''],
                  tel:'tel:+380682013911',
                  instagram: 'https://www.instagram.com/sunrise_flowers.coffee/',
                  location:"https://maps.app.goo.gl/m5h2uVLUhEXCb9gk9", 
           
                },
                {
                  id:2225,
                  name: 'Студія флористики "Sun Rise"',
                  status: true,
                  vip: false,
                  address: 'вул. Залізнична, 2/1',
                  foto: [sunrisezaliz],              
                  descrip: [ 'Студія квітів та декору'],
                  workTime: ['пн-нд: 08:00-19:00', '', ''],
                  tel:'tel:+380682013911',
                  instagram: 'https://www.instagram.com/flowerkyiv/',
                  location:"https://maps.app.goo.gl/oUZbVb2J8H913AQKA", 
           
                },
                {
                  id:2226,
                  name: 'Квіти подарунки',
                  status: true,
                  vip: false,
                  address: 'вул. Доківська 5Б',
                  foto: [podarunkikvit],              
                  descrip: [ 'Магазин  квітів та подарунків'],
                  workTime: ['пн-пт: 08:00-18:00', 'сб:8:00-14:00', 'нд: вихідний'],
                  tel:'tel:+380632270269',
                  location:"https://maps.app.goo.gl/dU3p5DUJMyRWP9KW7", 
           
                },
                
              ]         
            },
            {
              id: 223,
              name:'Речі на подарунки',
              foto: podarunki,
              lists: 
              [
                {
                  id:2231,
                  name: 'Шкіряна майстерня Mari Ko',
                  status: true,
                  vip: false,
                  foto: [rechipodarki],
                  address: 'вул. Пономарьова 13, 1-й пов, каб. №3',
                  descrip: ["Шкіряні сумки, рюкзаки, гаманці.", "В Індивідуальний підхід у виготовленні виробу", "Власне виробництво. Можливість розробки моделі"],
                  workTime: ['', '',''], 
                  tel:'tel:+380935525280',
                  instagram:'https://www.instagram.com/Mari_Ko_2024',
                  location:"https://maps.app.goo.gl/3wayFC822oDLvNKfA",
          

                  
                },
                
                      
              ]         
            },
          ]
        },
        
        { 
          id: 23,
          name: 'Графіки відключень',
           foto: grafik,
           
           types: 
          [
            {
              id: 231,
              name:'Світло',
              description: ' графіки погодинних відключень не будуть застосовуватись протягом доби ',
              foto: grafik,
              lists: 
              [


            
                {
                  id:2311,
                
                  name: 'Група 5.2',                 
                  status: true,
                  vip: false,
                  address: [' '],
                  descrip:['Київські Електромережі Атлант1/2'],
                  tel:'tel:+380502101588',
                  telegram:'tg://resolve?domain=DTEKKyivskielectromerezhibot',
                  olldescrips: ['вул. Пономарьова: 26 корп.1,2,3,4,5', 'вул. Доківська: 10 корп.1,2,7'],
                  workTime: ['', '', '', '' ], 
                 
                  grafiki: [

                    
                    {
                      id: 1,
                      name: 'пн',
                      svitloYes: ['01:30-05:00', '12:00-15:30', '22:30-23:59'],
                      svitloYesNo: [ ],
                      svitloNo: ['00:00-01:30','05:00-12:00', '15:30-22:30'],
                      
                  },
                  {
                      id: 2,
                      name: 'вт',
                      svitloYes: ['00:00-02:00', '09:00-12:30', '19:30-23:00'],
                      svitloYesNo: [ ],
                      svitloNo: ['02:00-09:00','12:30-19:30', '23:00-23:59']
                  },
                  {
                      id: 3,
                      name: 'ср',
                      svitloYes: ['06:00-09:30', '16:30-20:00'],
                      svitloYesNo: [ ],
                      svitloNo: ['00:00-06:00','09:30-16:30', '20:00-23:59']
                  },
                  {
                      id: 4,
                      name: 'чт',
                      svitloYes: ['03:00-06:30', '13:30-17:00'],
                      svitloYesNo: [ ],
                      svitloNo: ['00:00-03:00','06:30-13:30', '17:00-23:59']
                  },
                  {
                      id: 5,
                      name: 'пт',
                      svitloYes: ['00:00-03:30', '10:30-14:00', '21:00-23:59'],
                      svitloYesNo: [ ],
                      svitloNo: ['03:30-10:30','14:00-21:00']
                  },
                  {
                      id: 6,
                      name: 'сб',
                      svitloYes: ['00:00-00:30', '07:30-11:00', '18:00-21:30'],
                      svitloYesNo: [ ],
                      svitloNo: ['00:30-07:30','11:00-18:00', '21:30-23:59']
                  },
                  {
              
                      id: 0,
                      name: 'нд',
                      svitloYes: ['04:30-08:00', '15:00-18:30'],
                      svitloYesNo: [],
                      svitloNo: ['00:00-04:30','08:00-15:00', '18:30-23:59'],
                    
                    },
                  ],
                  grafikiTwo: [
                    {
                      id: 1,
                      name: 'пн',
                      svitloYes: [ '01:30-02:00', '02:00-03:00' ,  '03:00-04:00', '04:00-05:00', '12:00-13:00', '13:00-14:00', '14:00-15:00', '15:00-15:30', '22:30-23:00', '23:00-23:59'],
                      svitloYesNo: [ ],
                      svitloNo: ['00:00-01:00', '01:00-01:30', '05:00-06:00', '06:00-07:00', '07:00-08:00', '08:00-09:00',  '10:00-11:00', '11:00-12:00', '15:30-16:00', '16:00-17:00', '17:00-18:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-22:00', '22:00-22:30']
                  },
                  {
                      id: 2,
                      name: 'вт',
                      svitloYes: ['00:00-01:00','01:00-02:00', '09:00-10:00',  '10:00-11:00', '11:00-12:00', '12:00-12:30', '19:30-20:00', '20:00-21:00', '21:00-22:00', '22:00-23:00'],
                      svitloYesNo: [ ],
                      svitloNo: ['02:00-03:00','03:00-04:00','04:00-05:00', '05:00-06:00', '06:00-07:00','07:00-08:00','08:00-09:00',  '12:30-13:00', '13:00-14:00', '14:00-15:00','15:00-16:00','16:00-17:00', '17:00-18:00', '18:00-19:00','19:00-19:30', '23:00-23:59']
                  },
                  {
                      id: 3,
                      name: 'ср',
                      svitloYes: ['06:00-07:00', '07:00-08:00', '08:00-09:00', '09:00-09:30', '16:30-17:00', '17:00-18:00', '18:00-19:00', '19:00-20:00'],
                      svitloYesNo: [],
                      svitloNo: ['00:00-01:00','01:00-02:00', '02:00-03:00', '03:00-04:00', '04:00-05:00','05:00-06:00','09:30-10:00','10:00-11:00','11:00-12:00', '12:00-13:00', '13:00-14:00', '14:00-15:00', '15:00-16:00', '16:00-16:30', '20:00-21:00', '21:00-22:00', '22:00-23:00', '23:00-23:59']
                  },
                  {
                      id: 4,
                      name: 'чт',
                      svitloYes: [ '03:00-04:00', '04:00-05:00' ,  '05:00-06:00', '06:00-06:30', '13:30-14:00', '14:00-15:00', '15:00-16:00', '16:00-17:00'],
                      svitloYesNo: [ ],
                      svitloNo: ['00:00-01:00', '01:00-02:00', '02:00-03:00', '06:30-07:00', '07:00-08:00', '08:00-09:00',  '09:00-10:00', '10:00-11:00', '11:00-12:00', '12:00-13:00', '13:00-13:30', '17:00-18:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-22:00', '22:00-23:00', '23:00-23:59']
                  },
                  {
                      id: 5,
                      name: 'пт',
                      svitloYes: ['00:00-01:00','01:00-02:00', '02:00-03:00',  '03:00-03:30', '10:30-11:00', '11:00-12:00', '12:00-13:00', '13:00-14:00', '21:00-22:00', '22:00-23:00', '23:00-23:59'],
                      svitloYesNo: [ ],
                      svitloNo: ['03:30-04:00','04:00-05:00','05:00-06:00', '06:00-07:00', '07:00-08:00', '08:00-09:00','09:00-10:00','10:00-10:30',  '14:00-15:00', '15:00-16:00','16:00-17:00','17:00-18:00','18:00-19:00','19:00-20:00','20:00-21:00']
                  },
                  {
                      id: 6,
                      name: 'сб',
                      svitloYes: ['00:00-00:30', '07:30-08:00', '08:00-09:00', '09:00-10:00', '10:00-11:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-21:30'],
                      svitloYesNo: [ ],
                      svitloNo: ['00:30-01:00','01:00-02:00', '02:00-03:00', '03:00-04:00', '04:00-05:00','05:00-06:00','06:00-07:00','07:00-07:30','11:00-12:00','12:00-13:00','13:00-14:00','14:00-15:00','15:00-16:00','16:00-17:00','17:00-18:00','21:30-22:00','22:00-23:00','23:00-23:59']
                  },
                  {
              
                      id: 0,
                      name: 'нд',
                      svitloYes: [ '04:30-05:00','05:00-06:00','06:00-07:00','07:00-08:00', '15:00-16:00','16:00-17:00','17:00-18:00','18:00-18:30'],
                      svitloYesNo: [ ],
                      svitloNo: ['00:00-01:00','01:00-02:00','02:00-03:00','03:00-04:00','04:00-04:30', '08:00-09:00', '09:00-10:00', '10:00-11:00', '11:00-12:00', '12:00-13:00', '13:00-14:00', '14:00-15:00', '18:30-19:00', '19:00-20:00', '20:00-21:00', '21:00-22:00', '22:00-23:00', '23:00-23:59']
                  },
                  ]
                }, 
                {
                  id:2312,
                  name: 'Група 3.1',
                  grafiki: true,
                  status: true,
                  vip: false,
                  address: [, '',  '' ],
                  tel:'tel:+380994957040 ',
                  telegram:'tg://resolve?domain=DTEKKyivRegionElektromerezhiBot',
                  // foto: [],
                  descrip:['Регіональні Електромережі'],
                  olldescrips: ['вул. Пономарьова: 2/1, 2/2, 6, 6/1,2,3, 7, 7б, 8, 9, 13-а,ж, 18-а,б, 28, 28-а, 30, 32, 33, 34','вул. Доківська: 1, 2, 2б, 3, 3в, 4, 5, 5-а,б, 6, 7, 7а, 8, 9, 10 корп 3,4,5,6, 11, 13, 14, 14/1, 15, 17, 18, 19, 21, 23, 25, 26, 27, 29, 31, ', 'вул. Залізнична: з 2 по 14б будинки', 'Практично всі будинки: вул.Паркова вул. Меблева, вул. Бакала, вул. Шевченка, вул. Лісова, вул. Затишна, вул. Київська Русь, вул. Квітнева, вул. Паризька, вул. Соборна, вул. Щаслива, вул. Вокзальна', '', '', '' ],
                  workTime: ['', '', '', '' ], 
                  grafiki: [
                    {
                      id: 1,
                      name: 'пн',
                      svitloYes: ['00:00-01:00', '08:00-11:30', '18:30-22:00'],
                      svitloYesNo: [ ],
                      svitloNo: ['01:00-08:00','11:30-18:30', '22:00-23:59']
                  },
                  {
                      id: 2,
                      name: 'вт',
                      svitloYes: ['05:00-08:30', '15:30-19:00'],
                      svitloYesNo: [ ],
                      svitloNo: ['00:00-05:00','08:30-15:30', '19:00-23:59']
                  },
                  {
                      id: 3,
                      name: 'ср',
                      svitloYes: ['02:00-05:30', '12:30-16:00', '23:00-23:59'],
                      svitloYesNo: [],
                      svitloNo: ['00:00-02:00','05:30-12:30', '16:00-23:00']
                  },
                  {
                      id: 4,
                      name: 'чт',
                      svitloYes: ['00:00-02:30', '09:30-13:00', '20:00-23:30'],
                      svitloYesNo: [ ],
                      svitloNo: ['02:30-09:30','13:00-20:00', '23:30-23:59']
                  },
                  {
                      id: 5,
                      name: 'пт',
                      svitloYes: ['06:30-10:00', '17:00-20:30'],
                      svitloYesNo: [ ],
                      svitloNo: ['00:00-06:30','10:00-17:00', '20:30-23:59']
                  },
                  {
                      id: 6,
                      name: 'сб',
                      svitloYes: ['03:30-07:00', '14:00-17:30'],
                      svitloYesNo: [ ],
                      svitloNo: ['00:00-03:30','07:00-14:00', '17:30-23:59']
                  },
                  {
              
                      id: 0,
                      name: 'нд',
                      svitloYes: ['00:30-04:00', '11:00-14:30', '21:30-23:59'],
                      svitloYesNo: [],
                      svitloNo: ['0:00-00:30','04:00-11:00', '14:30-21:30']
                  },
                  ],
                  grafikiTwo: [

                   
                    {
                      id: 1,
                      name: 'пн',
                      svitloYes: ['00:00-01:00', '08:00-09:00', '09:00-10:00', '10:00-11:00', '11:00-11:30', '18:30-18:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-22:00'],
                      svitloYesNo: [ ],
                      svitloNo: ['01:00-02:00', '02:00-03:00', '03:00-04:00', '04:00-05:00', '05:00-06:00', '06:00-07:00', '07:00-08:00', '11:30-11:00', '11:00-12:00', '12:00-13:00', '13:00-14:00', '14:00-15:00', '15:00-16:00', '16:00-17:00', '17:00-18:00', '18:00-18:30', '22:00-23:00', '23:00-23:59']
                  },
                
                  {
                      id: 2,
                      name: 'вт',
                      svitloYes: [ '05:00-06:00', '06:00-07:00','07:00-08:00','08:00-08:30', '15:30-16:00', '16:00-17:00', '17:00-18:00', '18:00-19:00'],
                      svitloYesNo: [   ],
                      svitloNo: ['00:00-01:00', '01:00-02:00','02:00-03:00','03:00-04:00', '04:00-05:00','08:30-09:00','09:00-10:00', '10:00-11:00','11:00-12:00', '12:00-13:00', '13:00-14:00','14:00-15:00', '15:00-15:30', '19:00-20:00', '20:00-21:00', '21:00-22:00', '22:00-23:00', '23:00-23:59' ]
                  },

                  {
                      id: 3,
                      name: 'ср',
                      svitloYes: [ '02:00-03:00','03:00-04:00', '04:00-05:00', '05:00-05:30', '12:30-13:00', '13:00-14:00', '14:00-15:00', '15:00-16:00',  '23:00-23:59'   ],
                      svitloYesNo: [ ],
                      svitloNo: ['00:00-01:00','01:00-02:00', '05:30-06:00','06:00-07:00',  '07:00-08:00', '08:00-09:00', '09:00-10:00', '10:00-11:00',  '11:00-12:00', '12:00-12:30',  '16:00-17:00','17:00-18:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-22:00', '22:00-23:00',]
                  },

                  {
                      id: 4,
                      name: 'чт',
                      svitloYes: ['00:00-01:00','01:00-02:00', '02:00-02:30', '09:30-10:00', '10:00-11:00', '11:00-12:00', '12:00-13:00',  '20:00-21:00', '21:00-22:00', '22:00-23:00', '23:00-23:30'],
                      svitloYesNo: [   ],
                      svitloNo: ['02:30-03:00','03:00-04:00', '04:00-05:00', '05:00-06:00', '06:00-07:00', '07:00-08:00', '08:00-09:00', '09:00-09:30',  '13:00-14:00', '14:00-15:00', '15:00-16:00',  '16:00-17:00', '17:00-18:00', '18:00-19:00', '19:00-20:00',  '23:30-23:59']
                  },
                  {
                      id: 5,
                      name: 'пт',
                      svitloYes: ['06:30-07:00', '07:00-08:00','08:00-09:00','09:00-10:00', '17:00-18:00', '18:00-19:00', '19:00-20:00', '20:00-20:30'],
                      svitloYesNo: [  ],
                      svitloNo: ['00:00-01:00', '01:00-02:00','02:00-03:00','03:00-04:00', '04:00-05:00','05:00-06:00', '06:00-06:30', '10:00-11:00','11:00-12:00', '12:00-13:00', '13:00-14:00','14:00-15:00', '15:00-16:00', '16:00-17:00',  '20:30-21:00' ,  '21:00-22:00',  '22:00-23:00', '23:00-23:59' ]
                  },
                  {
                      id: 6,
                      name: 'сб',
                      svitloYes: [ '03:30-04:00', '04:00-05:00', '05:00-06:00','06:00-07:00', '14:00-15:00', '15:00-16:00', '16:00-17:00','17:00-17:30',],
                      svitloYesNo: [ ],
                      svitloNo: ['00:00-01:00','01:00-02:00','02:00-03:00','03:00-03:30', '07:00-08:00', '08:00-09:00', '09:00-10:00', '10:00-11:00', '11:00-12:00', '12:00-13:00', '13:00-14:00',  '17:30-18:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-22:00', '22:00-23:00', '23:00-23:59']
                  },
                  {
              
                      id: 0,
                      name: 'нд',
                      svitloYes: ['00:30-01:00','01:00-02:00', '02:00-03:00','03:00-04:00', '11:00-12:00', '12:00-13:00', '13:00-14:00', '14:00-14:30', '21:30-22:00', '22:00-23:00', '23:00-23:59' ],
                      svitloYesNo: [ ],
                      svitloNo: [ '00:00-00:30', '04:00-05:00', '05:00-06:00', '06:00-07:00', '07:00-08:00', '08:00-09:00','09:00-10:00','10:00-11:00', '14:30-15:00', '15:00-16:00', '16:00-17:00', '17:00-18:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-21:30']
                  },
                  ]
                  
                },
                {
                  id:2313,
                  name: 'Група 3.2',
                  grafiki: true,
                  status: true,
                  vip: false,
                  address: [, '',  '' ],
                  tel:'tel:+380994957040 ',
                  telegram:'tg://resolve?domain=DTEKKyivRegionElektromerezhiBot',
                  // foto: [],
                  descrip:['Регіональні Електромережі'],
                  olldescrips: ['Деякі будинки по: пров. Паризький, вул. Щаслива, вул. Соборна, вул. Вокзальна, ' ],
                  workTime: ['', '', '', '' ], 
                  grafiki: [
                    {
                      id: 1,
                      name: 'пн',
                      svitloYes: ['00:00-01:00', '08:00-11:30', '18:30-22:00'],
                      svitloYesNo: [ ],
                      svitloNo: ['01:00-08:00','11:30-18:30', '22:00-23:59']
                  },
                  {
                      id: 2,
                      name: 'вт',
                      svitloYes: ['05:00-08:30', '15:30-19:00'],
                      svitloYesNo: [ ],
                      svitloNo: ['00:00-05:00','08:30-15:30', '19:00-23:59']
                  },
                  {
                      id: 3,
                      name: 'ср',
                      svitloYes: ['02:00-05:30', '12:30-16:00', '23:00-23:59'],
                      svitloYesNo: [],
                      svitloNo: ['00:00-02:00','05:30-12:30', '16:00-23:00']
                  },
                  {
                      id: 4,
                      name: 'чт',
                      svitloYes: ['00:00-02:30', '09:30-13:00', '20:00-23:30'],
                      svitloYesNo: [ ],
                      svitloNo: ['02:30-09:30','13:00-20:00', '23:30-23:59']
                  },
                  {
                      id: 5,
                      name: 'пт',
                      svitloYes: ['06:30-10:00', '17:00-20:30'],
                      svitloYesNo: [ ],
                      svitloNo: ['00:00-06:30','10:00-17:00', '20:30-23:59']
                  },
                  {
                      id: 6,
                      name: 'сб',
                      svitloYes: ['03:30-07:00', '14:00-17:30'],
                      svitloYesNo: [ ],
                      svitloNo: ['00:00-03:30','07:00-14:00', '17:30-23:59']
                  },
                  {
              
                      id: 0,
                      name: 'нд',
                      svitloYes: ['00:30-04:00', '11:00-14:30', '21:30-23:59'],
                      svitloYesNo: [],
                      svitloNo: ['0:00-00:30','04:00-11:00', '14:30-21:30']
                  },
                  ],
                  grafikiTwo: [

                   
                    {
                      id: 1,
                      name: 'пн',
                      svitloYes: ['00:00-01:00', '08:00-09:00', '09:00-10:00', '10:00-11:00', '11:00-11:30', '18:30-18:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-22:00'],
                      svitloYesNo: [ ],
                      svitloNo: ['01:00-02:00', '02:00-03:00', '03:00-04:00', '04:00-05:00', '05:00-06:00', '06:00-07:00', '07:00-08:00', '11:30-11:00', '11:00-12:00', '12:00-13:00', '13:00-14:00', '14:00-15:00', '15:00-16:00', '16:00-17:00', '17:00-18:00', '18:00-18:30', '22:00-23:00', '23:00-23:59']
                  },
                
                  {
                      id: 2,
                      name: 'вт',
                      svitloYes: [ '05:00-06:00', '06:00-07:00','07:00-08:00','08:00-08:30', '15:30-16:00', '16:00-17:00', '17:00-18:00', '18:00-19:00'],
                      svitloYesNo: [   ],
                      svitloNo: ['00:00-01:00', '01:00-02:00','02:00-03:00','03:00-04:00', '04:00-05:00','08:30-09:00','09:00-10:00', '10:00-11:00','11:00-12:00', '12:00-13:00', '13:00-14:00','14:00-15:00', '15:00-15:30', '19:00-20:00', '20:00-21:00', '21:00-22:00', '22:00-23:00', '23:00-23:59' ]
                  },

                  {
                      id: 3,
                      name: 'ср',
                      svitloYes: [ '02:00-03:00','03:00-04:00', '04:00-05:00', '05:00-05:30', '12:30-13:00', '13:00-14:00', '14:00-15:00', '15:00-16:00',  '23:00-23:59'   ],
                      svitloYesNo: [ ],
                      svitloNo: ['00:00-01:00','01:00-02:00', '05:30-06:00','06:00-07:00',  '07:00-08:00', '08:00-09:00', '09:00-10:00', '10:00-11:00',  '11:00-12:00', '12:00-12:30',  '16:00-17:00','17:00-18:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-22:00', '22:00-23:00',]
                  },

                  {
                      id: 4,
                      name: 'чт',
                      svitloYes: ['00:00-01:00','01:00-02:00', '02:00-02:30', '09:30-10:00', '10:00-11:00', '11:00-12:00', '12:00-13:00',  '20:00-21:00', '21:00-22:00', '22:00-23:00', '23:00-23:30'],
                      svitloYesNo: [   ],
                      svitloNo: ['02:30-03:00','03:00-04:00', '04:00-05:00', '05:00-06:00', '06:00-07:00', '07:00-08:00', '08:00-09:00', '09:00-09:30',  '13:00-14:00', '14:00-15:00', '15:00-16:00',  '16:00-17:00', '17:00-18:00', '18:00-19:00', '19:00-20:00',  '23:30-23:59']
                  },
                  {
                      id: 5,
                      name: 'пт',
                      svitloYes: ['06:30-07:00', '07:00-08:00','08:00-09:00','09:00-10:00', '17:00-18:00', '18:00-19:00', '19:00-20:00', '20:00-20:30'],
                      svitloYesNo: [  ],
                      svitloNo: ['00:00-01:00', '01:00-02:00','02:00-03:00','03:00-04:00', '04:00-05:00','05:00-06:00', '06:00-06:30', '10:00-11:00','11:00-12:00', '12:00-13:00', '13:00-14:00','14:00-15:00', '15:00-16:00', '16:00-17:00',  '20:30-21:00' ,  '21:00-22:00',  '22:00-23:00', '23:00-23:59' ]
                  },
                  {
                      id: 6,
                      name: 'сб',
                      svitloYes: [ '03:30-04:00', '04:00-05:00', '05:00-06:00','06:00-07:00', '14:00-15:00', '15:00-16:00', '16:00-17:00','17:00-17:30',],
                      svitloYesNo: [ ],
                      svitloNo: ['00:00-01:00','01:00-02:00','02:00-03:00','03:00-03:30', '07:00-08:00', '08:00-09:00', '09:00-10:00', '10:00-11:00', '11:00-12:00', '12:00-13:00', '13:00-14:00',  '17:30-18:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-22:00', '22:00-23:00', '23:00-23:59']
                  },
                  {
              
                      id: 0,
                      name: 'нд',
                      svitloYes: ['00:30-01:00','01:00-02:00', '02:00-03:00','03:00-04:00', '11:00-12:00', '12:00-13:00', '13:00-14:00', '14:00-14:30', '21:30-22:00', '22:00-23:00', '23:00-23:59' ],
                      svitloYesNo: [ ],
                      svitloNo: [ '00:00-00:30', '04:00-05:00', '05:00-06:00', '06:00-07:00', '07:00-08:00', '08:00-09:00','09:00-10:00','10:00-11:00', '14:30-15:00', '15:00-16:00', '16:00-17:00', '17:00-18:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-21:30']
                  },
                  ]

                } 
              ]
            }, 
            // {
            //   id: 232,
            //   name:'Вода ',
            //   description: ', Графіки водопостачання за відсутності електроенергії',
            //   foto: grafikvoda,
            //   lists: 
            //   [  
            //     {
            //       id:2321,
                
            //       name: '',                 
            //       status: true,
            //       vip: false,
            //       address: [' '],
            //       descrip:[''],
            //       olldescrips: [''],
            //       workTime: ['', '', '', '' ], 
            //       grafiki: [
            //         {
            //           id: 1,
            //           name: 'пн',
            //           svitloYes: ['06:00-09:00', '18:00-22:00',],
            //           svitloYesNo: [ ],
            //           svitloNo: ['00:00-06:00','09:000-18:00','22:00-23:59',]
            //       },
            //       {
            //           id: 2,
            //           name: 'вт',
            //           svitloYes: ['06:00-09:00', '18:00-22:00'],
            //           svitloYesNo: [ ],
            //           svitloNo: ['00:00-06:00','09:000-18:00','22:00-23:59',]
            //       },
            //       {
            //           id: 3,
            //           name: 'ср',
            //           svitloYes: ['06:00-09:00', '18:00-22:00'],
            //           svitloYesNo: [ ],
            //           svitloNo: ['00:00-06:00','09:000-18:00','22:00-23:59',]
            //       },
            //       {
            //           id: 4,
            //           name: 'чт',
            //           svitloYes: ['06:00-09:00', '18:00-22:00'],
            //           svitloYesNo: [ ],
            //           svitloNo: ['00:00-06:00','09:000-18:00','22:00-23:59',]
            //       },
            //       {
            //           id: 5,
            //           name: 'пт',
            //           svitloYes: ['06:00-09:00', '18:00-22:00'],
            //           svitloYesNo: [ ],
            //           svitloNo: ['00:00-06:00','09:000-18:00','22:00-23:59',]
            //       },
            //       {
            //           id: 6,
            //           name: 'сб',
            //           svitloYes: ['07:00-10:00', '18:00-22:00',],
            //           svitloYesNo: [ ],
            //           svitloNo: ['00:00-07:00','10:00-18:00', '22:00-23:59']
            //       },
            //       {
              
            //           id: 0,
            //           name: 'нд',
            //           svitloYes: ['07:00-10:00', '18:00-22:00',],
            //           svitloYesNo: [ ],
            //           svitloNo: ['00:00-07:00','10:00-18:00', '22:00-23:59']
            //       },
            //       ],
            //       grafikiTwo: [
            //         {
            //           id: 1,
            //           name: 'пн',
            //           svitloYes: ['06:00-07:00', '07:00-08:00', '08:00-09:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-22:00', ],
            //           svitloYesNo: [ ],
            //           svitloNo: ['00:00-01:00', '01:00-02:00', '02:00-03:00', '03:00-04:00', '04:00-05:00', '05:00-06:00', '09:00-10:00', '10:00-11:00', '11:00-12:00', '12:00-13:00', '13:00-14:00', '14:00-15:00', '15:00-16:00', '16:00-17:00', '17:00-18:00', '22:00-23:00','23:00-23:59',]
            //       },
            //       {
            //           id: 2,
            //           name: 'вт',
            //           svitloYes: ['06:00-07:00', '07:00-08:00', '08:00-09:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-22:00', ],
            //           svitloYesNo: [ ],
            //           svitloNo: ['00:00-01:00', '01:00-02:00', '02:00-03:00', '03:00-04:00', '04:00-05:00', '05:00-06:00', '09:00-10:00', '10:00-11:00', '11:00-12:00', '12:00-13:00', '13:00-14:00', '14:00-15:00', '15:00-16:00', '16:00-17:00', '17:00-18:00', '22:00-23:00','23:00-23:59',]
            //          },
            //       {
            //           id: 3,
            //           name: 'ср',
            //           svitloYes: ['06:00-07:00', '07:00-08:00', '08:00-09:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-22:00', ],
            //           svitloYesNo: [ ],
            //           svitloNo: ['00:00-01:00', '01:00-02:00', '02:00-03:00', '03:00-04:00', '04:00-05:00', '05:00-06:00', '09:00-10:00', '10:00-11:00', '11:00-12:00', '12:00-13:00', '13:00-14:00', '14:00-15:00', '15:00-16:00', '16:00-17:00', '17:00-18:00', '22:00-23:00','23:00-23:59',]
            //          },
            //       {
            //           id: 4,
            //           name: 'чт',
            //           svitloYes: ['06:00-07:00', '07:00-08:00', '08:00-09:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-22:00', ],
            //           svitloYesNo: [ ],
            //           svitloNo: ['00:00-01:00', '01:00-02:00', '02:00-03:00', '03:00-04:00', '04:00-05:00', '05:00-06:00', '09:00-10:00', '10:00-11:00', '11:00-12:00', '12:00-13:00', '13:00-14:00', '14:00-15:00', '15:00-16:00', '16:00-17:00', '17:00-18:00', '22:00-23:00','23:00-23:59',]
            //         },
            //       {
            //           id: 5,
            //           name: 'пт',
            //           svitloYes: ['06:00-07:00', '07:00-08:00', '08:00-09:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-22:00', ],
            //           svitloYesNo: [ ],
            //           svitloNo: ['00:00-01:00', '01:00-02:00', '02:00-03:00', '03:00-04:00', '04:00-05:00', '05:00-06:00', '09:00-10:00', '10:00-11:00', '11:00-12:00', '12:00-13:00', '13:00-14:00', '14:00-15:00', '15:00-16:00', '16:00-17:00', '17:00-18:00', '22:00-23:00','23:00-23:59',]
            //          },
            //       {
            //           id: 6,
            //           name: 'сб',
            //           svitloYes: ['07:00-08:00', '08:00-09:00', '09:00-10:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-22:00', ],
            //           svitloYesNo: [ ],
            //           svitloNo: ['00:00-01:00', '01:00-02:00', '02:00-03:00', '03:00-04:00', '04:00-05:00', '05:00-06:00', '06:00-07:00', '10:00-11:00', '11:00-12:00', '12:00-13:00', '13:00-14:00', '14:00-15:00', '15:00-16:00', '16:00-17:00', '17:00-18:00', '22:00-23:00','23:00-23:59',]
            //       },
            //       {
              
            //           id: 0,
            //           name: 'нд',
            //           svitloYes: ['07:00-08:00', '08:00-09:00', '09:00-10:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-22:00', ],
            //           svitloYesNo: [ ],
            //           svitloNo: ['00:00-01:00', '01:00-02:00', '02:00-03:00', '03:00-04:00', '04:00-05:00', '05:00-06:00', '06:00-07:00', '10:00-11:00', '11:00-12:00', '12:00-13:00', '13:00-14:00', '14:00-15:00', '15:00-16:00', '16:00-17:00', '17:00-18:00', '22:00-23:00','23:00-23:59',]
            //       },
            //       ]
            //     }, 
            //   ]         
            // },
           
          ]
        },
        { 
          id:24,
          name: 'Робота',
          foto: jobs,
           types: 
          [
            {
              id: 241,
              name:'Пропоную Роботу',
              foto: searchemployee,
              lists: 
              [
              {
                  id:2414,
                  name: 'В студію Hair&Nail  потрібен перукар',
                  status: true,
                  vip: false,
                  foto: [nail_hair],
                  address: 'вул. Доківська, 14/1 ТЦ "АТЛАНТ" 3-й пов.',
                  descrip: ["-виплата зарплати після кожної зміни", "-графік роботи під запис", "-навчання за рахунок компанії "],
                  workTime: ['', '',''], 
                  tel:'tel:+380501702901',  
                  location: 'https://maps.app.goo.gl/SpqNXUsE8YvMrMdP6', 
                  network: 'https://hairnail.kiev.ua/',
                  instagram: 'https://www.instagram.com/hair.nail.studio '
                }, 
                {
                  id:2414,
                  name: 'Шукаємо няню для малюка 2,5 роки',
                  status: true,
                  vip: false,
                  foto: [searchemployee],
                  address: 'Коцюбинське',
                  descrip: ["Шукаємо няню для малюка 2,5 роки на повний робочий день (7:00-19:30), 5 днів на тиждень (понеділок-пʼятниця) ", "Малюк активний та розвинений, потребує постійної уваги і активної взаємодії.", "В обовʼязки няні входить догляд за дитиною та його активний розвиток. Любить читати книжки, ліпити з тіста, гратися іграшками і багато розмовляти. ", "Садочок не відвідує і не планує найближчим часом."],
                  workTime: ['', '',''], 
                  tel:'tel:+380937103601',   
                }, 
                {
                  id:2413,
                  name: '«Це саме та студія» шукає майстрів:',
                  status: true,
                  vip: false,
                  foto: [tastudio],
                  address: 'Коцюбинське',
                  descrip: ["-майстра манікюру;", "-майстра перукаря;", "-майстра бровіста.", "Досвід роботи від 1 року.", "Гнучкий графік, гарні умови праці.", "Пономарьова 26/5 ЖК Атлант2"],
                  workTime: ['', '',''], 
                  tel:'tel:+380933523836',
                  location: 'https://maps.app.goo.gl/FGy6XdjgB1CbW3nB9',
                  instagram: 'https://www.instagram.com/_davydenko_nails/'
                  
                }, 
                {
                  id:2411,
                  name: 'Терміново в Коцюбинське потрібні: ',
                  status: true,
                  vip: false,
                  foto: [searchemployee],
                  address: 'Коцюбинське',
                  descrip: ["Різноробочі", "Сортувальниці", "ЗП від 16 000 грн", "Деталі за телефоном"],
                  workTime: ['', '',''], 
                  tel:'tel:+380992199566',
                  instagram:'',
                  telegram:'',

                  
                },
                 {
                  id:2412,
                  name: 'Шукаємо Піцайоло (22 000 грн) ',
                  status: true,
                  vip: false,
                  foto: [domiano],
                  address: 'Коцюбинське',
                  descrip: ["Формувати та випікати найсмачнішу піцу", "Займатись підготовкую начинок та тіста", "Дотримуватись санітарних норм на робочому місці", "Графік 5/2 з 10:00 до 20:00", "Готові взяти людину без досвіду або з мінімальним досвідом"],
                  workTime: ['', '',''], 
                  tel:'tel:+380636107424',
                  location: 'https://maps.app.goo.gl/ivEJpaa3HYqSZTY1A',
                  instagram: 'https://www.instagram.com/damiano.pizzabar'
                  
                },                
              ]         
            },

            {
              id: 242,
              name:'Шукаю Роботу',
              foto: searchjobs,
              lists: 
              [
                {
                  id:2421,
                  name: 'Тут може бути Ваша пропозиція"',
                  status: true,
                  vip: false,
                  address: '',
                  foto: [searchjobs],              
                  descrip: ['Короткий опис Вашої пропозиції - до 170 символів',
                  'Одне фото '],
                  workTime: ['', '', ''],
                  instagram: 'https://www.instagram.com/',
                  tel:'tel:+380670000000',
                  location:"https://maps.app.goo.gl/2mu1DMTFNv8x8hv66", 
           
                },
                
                
              ]         
            },
          ]
        },
         { 
          id: 25,
          name: 'Відпочинок / Оздоровлення',
           foto: vidpochinok,
           types: 
          [
            {
              id: 251,
              name:'Бані, Сауни, СПА',
              foto: bani,
              lists: 
              [
                {
                  id:2511,
                  name: 'Сауна "ParHub"',
                  status: true,
                  vip: false,
                  address: 'вул. Пономарьова 7',
                  foto: [parhub],              
                  descrip: ['ParHub – сучасна сауна в Коцюбинському',
                  'Ідеальне місце для відпочинку з комфортною парною та затишною атмосферою', 'Бронювання по телефону'],
                  workTime: ['', '', ''],
                  instagram: 'https://www.instagram.com/par.hub.relax',
                  tel:'tel:+380962881241',
                  location:"https://maps.app.goo.gl/hq3DS8wmeGH87G3S9", 
           
                },
            
               
          
              ]         
            },
            
           
          ]
        },
   
        { 
          id: 26,
          name: 'Умови розміщення реклами',
           foto: reklama,
           types: 
          [
            {
              id: 261,
              name:'Платне розміщення ЛЕНДІНГ',
              foto: personreklama,
              lists: 
              [
            
                {
                  id:2611,
                  name: 'Розширена персональна  сторінка',
                  more: 'Перейти на сторінку',
                  status: true,
                  vip: false,
                  priceReklama: 1000,         
                  currency: 'грн/місяць',
                  address: 'Повірка лічильників',
                  foto: [fotomain, reklama1, reklama2, reklama4, reklama5, reklama6,],
                  fotowork:[ reklama1, reklama2, reklama4, reklama5, reklama6, reklama7, reklama8, reklama9],
                  video: videoAboutReklama,
                  header:'Послуги:',
                  descrip: ['Знаходиться в конкретному розділі та на головній сторінці (ТОП)','Короткий опис Вашого бізнесу або Вашої роботи  на картці та необмежений на персональній сторінці','Текст до 170 символів на картці та необмежений на персональній сторінці',
                  'До 10-ти фотографій'],
                  olldescrips: [],
                  workTime: ['',], 
                  forma: 'sdvdv',
                  tel:'tel:+380670000000',
                  telegram:'tg://resolve?domain=KotsiuBilka',
                  email: 'mailto: ipk.kcb@gmail.com',
                  facebook:'https://www.facebook.com/',
                  network:'https://ipk.net.ua/26/261/2611',
                  nameclient:'Задоволених клієнтів',
                  nameprojects:'Виконаних проєктів',
                  nameexpience:'Років досвіду',
                  nameblock1:'ПРО ВАС',
                  nameblock2:'ВИКОНАНІ РОБОТИ АБО ФОТО ВАШОГО ПРОДУКТУ',
                  nameblock3:'ЦІНИ НА РОБОТИ АБО ЦІНИ ВАШОГО ПРОДУКТУ',
                  client: 510,
                  projects: 250,
                  experience: 10,
                  clientTime: 10,
                  projectsTime: 5,
                  fotomaindescrip: 'Назва Вас або Вашої компанії',
                  fotodescrip: ["Ласкаво просимо на наш інформаційний портал, де ми пропонуємо вам найсвіжіші новини, цікаві пропозиції та корисні контакти з найрізноманітніших сфер життя." ,"Наша місія - надавати вам доступ до об'єктивної та достовірної інформації, яка допоможе вам бути в курсі подій і вирішити Ваші проблеми"],
                  nameWork:'Коротка назва Вашого Бізнесу',
                  bgdescrip: 'Короткий опис саме чим Ви займаєтесь, які надаєте послуги, або яку продукцію пропонуєте до 130 символів',
                  bgpesonal: bgreklama,
                  btnbgtel:'Подзвонити Вам',
                  priceservise: [
                    { name: "Звичайне розміщення реклами в розділі", price: "0 грн" },
                    { name: " Платне розміщення знаходиться в конкретному розділі та на головній сторінці (ТОП)", price: " 500 грн/міс" },
                    { name: "Розширена сторінка (Персональна лендінг сторінка) ", price: "1000 грн/міс" },
              
                  ]
          
                },
          
              ]         
            },
            {
              id: 252,
              name:'Платне розміщення ТОП-КУТОК',
              foto: topteklama,
              lists: 
              [
            
                {
                  id:2522,
                  name: 'Ваша назва "Бізнесу"',
                  status: true,
                  vip: false,
                  address: 'Ваша адреса',
                  foto: [reklama4, reklama5, reklama2 ],
                  fotowork:[ reklama4, reklama5, reklama2, reklama8],
                  descrip: ['Знаходиться в конкретному розділі та на головній сторінці (ТОП)','Короткий опис Вашого бізнесу або Вашої роботи','Текст до 170 символів',
                  'До 5-ти фотографій'],
                  olldescrips: [],
                  priceReklama: 500,
                  currency: 'грн/місяць',
                  workTime: ['Час роботи','пн-нд: 00.00-24.00'],                
                  tel:'',
                  telegram:'tg://resolve?domain=KotsiuBilka',
                  email: 'mailto: ipk.kcb@gmail.com',
                  network:'https://ipk.net.ua/26/262',
                  
              
                   
                },                      
              ]         
            },
            {
              id: 253,
              name:'Безкоштовне розміщення',
              foto: besplatno,
              lists: 
              [
            
                {
                  id:2531,
                  name: 'Ваша назва "Бізнесу"',
                  status: true,
                  firstvip: 1,
                  vip: false,
                  address: 'Ваша адреса',
                   priceReklama: '0',
                   currency: 'грн/місяць',
                  foto: [reklama4],
                  fotowork:[ reklama4],
                  descrip: ['Знаходиться в конкретному розділі ','Короткий опис Вашого бізнесу або Вашої роботи - до 170 символів',
                  'Одне фото (Крім розділу Нерухомість)'],
                  olldescrips: [],
                  workTime: ['Час роботи','пн-нд: 00.00-24.00'],                       
                  telegram:'tg://resolve?domain=KotsiuBilka',
                  facebook:'',
                  email: 'mailto: ipk.kcb@gmail.com',
                  network:'https://ipk.net.ua/26/263',
                 
                   
                },   
                                     
              ]         
            },
          ]
        },
   
   

        
    ]
   

    // FoodService

     const [foodServices, setFoodServices] = useState([]);


    useEffect(() => {
        const getFoodServices = async () => {
          try {
            const res = await fetch('https://api.ipk.net.ua/api/V1/food/food-service-group-by-category/');
            if (!res.ok) {
              throw new Error('Failed to fetch restoran');
            }
            const data = await res.json();
            setFoodServices(data);
          } catch (e) {
            console.error(e.message);
          }
        };
    
        getFoodServices();
        
      }, []);
      console.log('rest', foodServices) 
   
    


  return (
    <IpkContext.Provider value={{kategoris, vips, foodServices}}>
      {children}
    </IpkContext.Provider>
  );
};

export default InfoContext;








