
import './App.css';
import loadStyles from '../src/untils/loadStyles';
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hotjar from '@hotjar/browser';
import LauderMain from './components/LauderMain/LauderMain';
import { lazy, Suspense } from "react";


const HeaderLazy = lazy(()=> import('./components/Header/Header'));
const RoutingLazy = lazy(()=> import('./pages/Routing/Routing'));
const FooterLazy = lazy(()=> import('./components/Foter/Foter'));

const siteId = 5015198;
const hotjarVersion = 6;

function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDelayOver, setIsDelayOver] = useState(false);
  

  useEffect(() => {
   
    const delayTimer = setTimeout(() => {
      setIsDelayOver(true);
    }, 3000);

    loadStyles('/')
      .then(() => setIsLoaded(true))
      .catch(() => console.error('Failed to load styles'));

    return () => clearTimeout(delayTimer); // очищення таймеру при демонтованні компонента
  }, []);

  useEffect(() => {
    const refreshInterval = 6 * 60 * 60 * 1000; // 12 hours in milliseconds
    const refreshTimer = setInterval(() => {
      window.location.reload();
    }, refreshInterval);

    return () => clearInterval(refreshTimer); // Cleanup timer on component unmount
  }, []);

  if ( !isDelayOver) {
    return <LauderMain />;
  }








  Hotjar.init(siteId, hotjarVersion)
  return (
    <div className="App">
      <Suspense fallback={<LauderMain />}>
        
      
        <HeaderLazy />
        <RoutingLazy />
        <FooterLazy />
      </Suspense>
    </div>
  );
}

export default App;

